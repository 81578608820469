import loadImage from "blueimp-load-image";

const optionsDefault = {
  maxWidth: 1500,
  maxHeight: 1500,
  imageSmoothingQuality: 'medium'
};

export default async function (file, options) {
  options = options || {};
  options = Object.assign(optionsDefault, options, {canvas: true});

  return new Promise((resolve) => {
    loadImage(file, function(canvas) {
      canvas.toBlob(function (blob) {
        resolve(new File([blob], file.name));
      }, 'image/jpeg');
    }, options);
  });
}
