import axios from 'axios';
import axiosRetry from 'axios-retry';

const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';
const VISIT_TOKEN_STORAGE_KEY = 'visit_token';

class API {
  constructor() {
    const self = this;
    this.retryLimit = 2;
    this.axios = axios.create({
      baseURL: process.env.VUE_APP_API_URL + '/api',
    });

    axiosRetry(this.axios, {retries: this.retryLimit});

    const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    if (accessToken) {
      this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    }

    const visitToken = localStorage.getItem(VISIT_TOKEN_STORAGE_KEY);
    if (visitToken) {
      this.axios.defaults.headers.common['X-Visit-Id'] = visitToken;
    }

    this.axios.defaults.headers.common['Content-Type'] = 'application/json';
    if (Intl && Intl.DateTimeFormat) {
      this.axios.defaults.headers.common['X-Local-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    let triedToUpdateToken = false;
    this.axios.interceptors.response.use(function (response) {
      const contentType = response.headers["content-type"];
      if (!contentType || !contentType.includes("application/json")) {
        throw "Bad API response";
      }

      const visitToken = response.headers["x-visit-id"];
      if (visitToken) {
        self.setVisitToken(visitToken);
      }

      if (response.data && response.data.access_token && response.data.refresh_token) {
        self.setAccessToken(response.data.access_token);
        self.setRefreshToken(response.data.refresh_token);
      }

      return response;
    }, async function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
          if (refreshToken && !triedToUpdateToken) {
            triedToUpdateToken = true;
            try {
              const res = await self.axios.post('auth/token/refresh', {
                refresh_token: refreshToken
              });
              triedToUpdateToken = false;

              error.config.headers['Authorization'] = 'Bearer ' + res.data.access_token;

              return self.axios(error.config);
            } catch (e) {
              // clear auth
            }
          }

          self.clearAuth();
        }

        if (error.response.status === 403 && error.response.data.banned) {
          self.clearAuth();
        }
      }

      return Promise.reject(error);
    });
  }

  setVisitToken(token) {
    this.axios.defaults.headers.common['X-Visit-Id'] = token;
    localStorage.setItem(VISIT_TOKEN_STORAGE_KEY, token);
  }

  setAccessToken(token) {
    this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
  }

  setRefreshToken(token) {
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, token);
  }

  setLastActivityTimestamp(timestamp) {
    this.axios.defaults.headers.common['X-Last-Activity'] = timestamp;
  }

  clearAuth() {
    this.axios.defaults.headers.common['Authorization'] = null;
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    location.href = '/';
  }
}

export default new API();
