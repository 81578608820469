<template>
  <footer>
    <b-container fluid>
      <div class="copyright">&copy; Copyright {{year}} by PIF. All rights reserved</div>
      <nav class="d-flex info-links">
        <router-link
            v-for="link in links"
            :to="link.uri"
            :key="link.uri"
        >
          {{link.label}}
        </router-link>
      </nav>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      year: new Date().getFullYear(),
      links: [
        {label: "Imprint", uri: "/static/imprint"},
        {label: "Conditions", uri: "/static/agb"},
        {label: "Data Policy", uri: "/static/data-policy"},
        {label: "About us", uri: "/static/about-us"},
      ],
      socialButtons: [
        {icon: "icon-facebook", uri: "#"},
        {icon: "icon-youtube", uri: "#"},
        {icon: "icon-twitter", uri: "#"},
        {icon: "icon-instagram", uri: "#"},
      ]
    };
  },
}
</script>


<style lang="scss">
@import "./index.scss";
</style>
