<template>
  <b-overlay
      blur="0"
      :bg-color="transparent ? 'transparent' : null"
      spinner-type="grow"
      spinner-variant="primary"
      v-bind="$attrs"
  >
    <slot/>
  </b-overlay>
</template>

<script>
export default {
  name: "AppOverlay",
  props: {
    transparent: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
