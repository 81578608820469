import icebreakerService from "@/api/services/icebreakerService";

const ADD_ICEBREAKERS = "ADD_ICEBREAKERS";
const CLEAR_ICEBREAKERS = "CLEAR_ICEBREAKERS";

export default {
    namespaced: true,

    state: {
        icebreakers: [],
    },

    mutations: {
        [ADD_ICEBREAKERS](state, icebreakers) {
            state.icebreakers = icebreakers;
        },
        [CLEAR_ICEBREAKERS](state) {
            state.icebreakers = [];
        }
    },

    getters: {
        getIcebreakers(state) {
            return state.icebreakers;
        },
    },

    actions: {
        async getIcebreakers({commit}, conversationId) {
            const icebreakers = await icebreakerService.getIcebreakers(conversationId);
            commit(ADD_ICEBREAKERS, icebreakers);
        },

        clearIcebreakers({commit}) {
            commit(CLEAR_ICEBREAKERS);
        }
    }
}