import kinkService from "@/api/services/kinkService";

const ADD_KINKS = "ADD_KINKS";

export default {
  namespaced: true,

  state: {
    kinks: []
  },

  mutations: {
    [ADD_KINKS](state, kinks) {
      state.kinks = kinks;
    },
  },

  getters: {
    getKinks(state) {
      return state.kinks;
    },

    getMainKinks(state) {
      return state.kinks.filter((kink) => {
        return !kink.category;
      });
    },

    getKinkCategories(state) {
      const categorizedKinks = state.kinks.filter((kink) => {
        return kink.category;
      });

      return categorizedKinks.reduce((result, kink) => {
        const key = kink.category.id;
        if (!result[key]) {
          result[key] = {...kink.category, kinks: [], descriptions: []};
        }

        if (kink.description) {
          result[key].descriptions.push({title: kink.name, text: kink.description});
        }

        result[key].kinks.push(kink);

        return result;
      }, {});
    }
  },

  actions: {
    async getKinks({commit}) {
      const kinks = await kinkService.getKinks();
      commit(ADD_KINKS, kinks);
    },

    async setKinks({dispatch}, kinkIds) {
      const user = await kinkService.setKinks(kinkIds);
      dispatch('user/updateUser', user, {root: true});
    }
  }
}
