import {createRouter, createWebHistory} from 'vue-router'
import routes from "@/router/routes";
import store from "@/store";
import {loginUserByLink, checkRouteAccess} from "@/router/helper";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('modal/closeAllModals');

  if (to.query.login_check_url) {
    await loginUserByLink(to, next);
    return;
  }

  await checkRouteAccess(to, next);
});

router.afterEach(to => {
  window.scrollTo(0, 0);

  store.dispatch("layout/update", {
    showHeader: (to.meta.showHeader instanceof Function ? to.meta.showHeader(to) : to.meta.showHeader) !== false,
    showFooter: (to.meta.showFooter instanceof Function ? to.meta.showFooter(to) : to.meta.showFooter) !== false,
    showNavbar: (to.meta.showNavbar instanceof Function ? to.meta.showNavbar(to) : to.meta.showNavbar) !== false
      && store.getters['auth/isLoggedIn'],
    pageClass: (to.meta.pageClass instanceof Function ? to.meta.pageClass(to) : to.meta.pageClass)
  });
});

router.onError(error => {
  if (/loading chunk [\w-]* failed./i.test(error.message)) {
    window.location.reload();
  }
});

export default router;
