<template>
  <app-notifier :notification-to-component-mapping="mapping"/>
</template>
<script>
import AppNotifier from "@/components/notifier";

export default {
  name: 'AppNoteNotifier',
  components: {
    AppNotifier
  },
  data() {
    return {
      mapping: {
        'user_image_declined_note': 'notes/note-user-image-declined',
        'user_image_approved_note': 'notes/note-user-image-approved',
        'age_verification_accepted_note': 'notes/note-age-verification-accepted',
        'age_verification_rejected_note': 'notes/note-age-verification-rejected',
        'approved_user_fantasy_note': 'notes/note-approved-user-fantasy'
      }
    }
  }
}
</script>
