import {createApp} from 'vue';
import App from './App.vue';
import './utils/storage';
import './utils/gtm';
import router from './router';
import store from '@/store';
import {BootstrapVue3} from 'bootstrap-vue-3';
import VueLazyLoad from 'vue3-lazyload';
import Bugsnag from '@/utils/bugsnag';

const app = createApp(App)
  .use(VueLazyLoad)
  .use(router)
  .use(store)
  .use(BootstrapVue3)
  .directive('click-outside', {
    mounted: (el, binding) => {
      el.clickOutsideEvent = event => {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value();
        }
      };
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
      document.removeEventListener("click", el.clickOutsideEvent);
    }
  });

if (Bugsnag) {
  app.use(Bugsnag.getPlugin('vue'));
}

app.mount('#app');
