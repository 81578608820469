import creditCardService from "@/api/services/creditCardService";

const SET_CREDIT_CARDS = "SET_CREDIT_CARDS";

export default {
  namespaced: true,

  state: {
    creditCards: []
  },

  getters: {
    getCreditCards(state) {
      return state.creditCards;
    }
  },

  mutations: {
    [SET_CREDIT_CARDS](state, creditCards) {
      state.creditCards = creditCards;
    },
  },

  actions: {
    async getCreditCards({commit}) {
      const creditCards = await creditCardService.getCreditCards();
      commit(SET_CREDIT_CARDS, creditCards);
    },

    async removeCard({commit}, cardId) {
      const creditCards = await creditCardService.removeCard(cardId);
      commit(SET_CREDIT_CARDS, creditCards);
    },

    async setPrimaryCard({commit}, cardId) {
      const creditCards = await creditCardService.setPrimaryCard(cardId);
      commit(SET_CREDIT_CARDS, creditCards);
    }
  }
}
