<template>
  <div class="profile-gallery row" v-bind="$attrs">
    <b-col cols="6" sm="4" md="2" v-for="(image, index) in images" :key="image.id">
      <profile-image
          class="photo"
          @click="showLightbox(index)"
          :show-restriction-info="false"
          :image="image"
      />
    </b-col>
  </div>
  <app-lightbox
      :visible="lightboxShown"
      :imgs="getImageUrls(images)"
      :index="imageIndex"
      @hide="hideLightbox"
      @on-index-change="updateImageIndex"
  >
    <template #toolbar v-if="isImageRestricted">
      <adult-restriction @click="showSubscriptionPaywall"/>
    </template>
  </app-lightbox>
</template>

<script>
import AppLightbox from "@/components/lightbox";
import ProfileImage from "@/components/profile-image";
import AdultRestriction from "@/components/adult-restriction";

export default {
  name: 'ProfileGallery',
  components: {
    AppLightbox,
    ProfileImage,
    AdultRestriction
  },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      lightboxShown: false,
      imageIndex: 0
    };
  },
  computed: {
    isImageRestricted() {
      const image = this.images[this.imageIndex];
      return image && image.restricted;
    }
  },
  methods: {
    showLightbox(index) {
      this.lightboxShown = true;
      this.imageIndex = index;
    },

    hideLightbox() {
      this.lightboxShown = false;
      this.imageIndex = 0;
    },

    getImageUrls(images) {
      return images.map(image => image.urls.large);
    },

    updateImageIndex(oldIndex, newIndex) {
      this.imageIndex = newIndex;
    },

    showSubscriptionPaywall() {
      this.$store.dispatch('modal/openModal', {id: 'subscription-paywall-modal'});
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
