import store from "@/store";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: function () {
      if (store.getters['auth/isLoggedIn']) {
        return import('@/pages/home');
      } else {
        return import('@/pages/landings/default');
      }
    },
    meta: {
      isPrivate: () => !!store.getters['user/getUser'].id
    }
  },
  {
    path: '/chat/:conversationId?',
    name: 'Chat',
    component: () => import('@/pages/chat'),
    meta: {
      pageClass: 'chat-page',
      isPrivate: true,
      showNavbar: (route) => !route.params.conversationId
    }
  },
  {
    path: '/my-profile',
    name: 'UserProfile',
    component: () => import('@/pages/user-profile'),
    meta: {
      isPrivate: true,
    }
  },
  {
    path: '/getting-started',
    name: 'GettingStarted',
    component: () => import('@/pages/getting-started'),
    meta: {
      isPrivate: true,
      showHeader: false,
      showNavbar: false,
      showFooter: false,
      pageClass: 'getting-started'
    }
  },
  {
    path: '/fantasies',
    name: 'Fantasies',
    component: () => import('@/pages/fantasies'),
    meta: {
      isPrivate: true,
    }
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import('@/pages/verify-email'),
    meta: {
      isPrivate: true,
      showHeader: false,
      showNavbar: false,
      showFooter: false
    }
  },
  {
    path: '/join-fantasy',
    name: 'JoinFantasy',
    component: () => import('@/pages/join-fantasy'),
    meta: {
      isPrivate: true,
    },
    props: true,
  },
  {
    path: '/karma',
    name: 'Karma',
    component: () => import('@/pages/karma'),
    meta: {
      isPrivate: true,
    }
  },
  {
    path: '/karma-market',
    name: 'KarmaMarket',
    component: () => import('@/pages/karma-market'),
    meta: {
      isPrivate: true,
    }
  },
  {
    path: '/password-recovery',
    name: 'PasswordRecovery',
    component: () => import('@/pages/password-recovery')
  },
  {
    path: '/static/:slug(.*)',
    name: 'StaticPage',
    component: () => import('@/pages/static-page'),
    meta: {
      pageClass: 'static-page',
    }
  },
  {
    path: '/landing/:slug(.*?)/:id([0-9]+)?',
    name: 'LandingPage',
    component: () => import('@/pages/landings'),
    meta: {
      pageClass: (route) => {
        let pageClass = 'landing';
        if (route.params.slug) {
          pageClass += ` ${route.params.slug}-landing`;
        }

        return pageClass;
      },
    }
  },
  {
    path: '/payment/:result(success|fail)',
    name: 'PaymentResult',
    component: () => import('@/pages/payment-result')
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () => import('@/pages/404'),
    meta: {
      showHeader: false
    }
  },
];

export default routes;

export function notFoundRoute(route) {
  return {
    name: 'NotFound',
    params: {
      pathMatch: route.path.substring(1).split('/'),
      query: route.query,
      hash: route.hash
    }
  }
}
