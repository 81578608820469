import api from '@/api/api'

class ChatService {
  sendMessage(conversationId, text, imageId, replyToMessageId) {
    return api.axios.post(`chat/send-message/${conversationId}`, {
      text: text,
      image_id: imageId,
      reply_to_message_id: replyToMessageId
    }).then(response => response.data.message);
  }

  loadMessages(conversationId, minMessageId, maxMessageId) {
    return api.axios.get(`chat/load-messages/${conversationId}`, {
      params: {
        min_message_id: minMessageId,
        max_message_id: maxMessageId
      }
    }).then((response) => {
      return response.data.messages;
    });
  }

  disconnect(conversationId) {
    return api.axios.post(`chat/disconnect/${conversationId}`);
  }

  sendIcebreaker(conversationId, icebreakerId) {
    return api.axios.post(`chat/send-icebreaker/${conversationId}`, {icebreaker_id: icebreakerId})
        .then(response => response.data.message);
  }
}

export default new ChatService();
