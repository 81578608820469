<template>
  <div class="subscription-type">
    <b-form-radio
        :id="`subscription-type-${subscriptionType.id}`"
        name="subscription_type"
        :value="subscriptionType"
        v-model="selectedSubscriptionType"
    >
      <div v-if="subscriptionType.offer" class="prime-label">
        Offer expires in {{ offerExpirationTimeFormatted }}
      </div>
      <div v-else-if="subscriptionType.popular" class="prime-label">Most popular</div>

      <div class="custom-control-label">
        <h4>
          {{ subscriptionType.name }}
          <span class="karma-amount">
            <i class="icon icon-diamond-filled primary medium"/>
            <span>{{ subscriptionType.trial_karma_amount || subscriptionType.karma_amount }} karma</span>
          </span>
        </h4>
        <div class="price">
          <span>{{ subscriptionType.initial_price_label }}</span>/{{ subscriptionType.initial_duration_label }}
        </div>
      </div>
      <div class="type-hint">
        <template v-if="isTrial">
          {{ subscriptionType.name }} for {{ subscriptionType.initial_duration_label }}, cancel any time
        </template>
        <template v-else>
          {{ subscriptionType.name }} subscription, cancel any time
        </template>
      </div>
    </b-form-radio>
  </div>
</template>

<script>
import moment from "moment";
import {mapActions} from "vuex";

export default {
  name: 'SubscriptionType',

  data() {
    return {
      offerExpirationTimeFormatted: null,
      offerExpirationInterval: null
    }
  },

  emits: ['update:modelValue'],

  computed: {
    isTrial() {
      return this.subscriptionType.trial !== null;
    },

    selectedSubscriptionType: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },

  props: {
    subscriptionType: {
      type: Object
    },

    modelValue: {
      type: Object,
      default: () => {
      }
    },
  },

  methods: {
    ...mapActions('subscriptionType', ['getSubscriptionTypes']),

    async updateOfferExpirationTime() {
      const offer = this.subscriptionType.offer;
      if (!offer) {
        return null;
      }

      const currentTimestamp = Date.now() / 1000;
      const remainingSeconds = Math.max(0, offer.end_timestamp - currentTimestamp);
      if (remainingSeconds === 0) {
        clearInterval(this.offerExpirationInterval);
        await this.getSubscriptionTypes();
        return;
      }

      const duration = moment.duration(remainingSeconds, 'seconds');
      const days = Math.floor(duration.asDays());

      let dayLabel = '';
      if (days) {
        dayLabel = days === 1 ? `${days} day ` : `${days} days `;
      }

      this.offerExpirationTimeFormatted = dayLabel + moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
    },
  },

  mounted() {
    if (this.subscriptionType.offer) {
      this.offerExpirationInterval = setInterval(() => this.updateOfferExpirationTime());
    }
  },

  beforeUnmount() {
    clearInterval(this.offerExpirationInterval);
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
