<template>
  <app-modal
      id="forgot-password-modal"
      dialog-class="modal-sm"
      header-class="compact"
      centered
      title="Forgot password?"
      title-tag="h4"
  >
    <forgot-password-form/>
  </app-modal>
</template>

<script>
import AppModal from "@/components/modals/modal"
import ForgotPasswordForm from "@/components/form-forgot-password";

export default {
  name: "ModalForgotPassword",
  components: {
    AppModal,
    ForgotPasswordForm
  }
}
</script>
