<template>
  <app-overlay :transparent="false" z-index="100" opacity="1" :show="globalLoading" fixed no-wrap/>
  <app-header v-if="showHeader"/>
  <div class="content-container" :class="[getPageClass, showNavbar ? 'bottom-indent' : '']">
    <router-view v-if="!globalLoading"/>
  </div>
  <app-footer v-if="showFooter" :class="user.id ? 'authorized' : ''"/>
  <app-navbar v-if="showNavbar"/>

  <modal-notification/>
  <modal-confirmation/>
  <template v-if="!user.id">
    <modal-signup/>
    <modal-login/>
  </template>
  <template v-else>
    <modal-profile/>
    <modal-send-image/>
    <modal-upload-image/>
    <modal-image-moderation-notification/>
    <modal-chat-reward-sent-notification/>
    <modal-user-guide v-if="user.completed_basic_info && !user.meta.completed_guide"/>
    <app-note-notifier/>
    <app-modal-notifier/>
    <modal-user-notification-unsubscribe/>
    <modal-match/>
    <modal-chat-activity-reward/>
    <modal-incoming-fantasy-request/>
    <modal-karma-paywall/>
    <modal-subscription-paywall/>
    <modal-subscription-trial-info/>
    <modal-payment/>
    <modal-payment-success/>
  </template>
</template>

<script>
import {mapGetters} from "vuex";
import api from "@/api/api";
import startHotjar from "./utils/hotjar";
import {getAccessRules} from "@/router/helper";
import CampaignService from "@/api/services/campaignService";
import "@/utils/boringTimer";
import "@/utils/paymentSubscriber";

import pushSubscriptionManager from "@/utils/pushSubscriptionManager";
import AppHeader from "@/components/header";
import AppFooter from "@/components/footer";
import AppOverlay from "@/components/overlay";
import AppNavbar from "@/components/navbar";
import AppNoteNotifier from "@/components/note-notifier";
import AppModalNotifier from "@/components/modal-notifier";
import ModalMatch from "@/components/modals/modal-match";
import ModalUserGuide from "@/components/modals/modal-user-guide";
import ModalSendImage from "@/components/modals/modal-send-image";
import ModalProfile from "@/components/modals/modal-profile";
import ModalNotification from "@/components/modals/modal-notification";
import ModalSignup from "@/components/modals/modal-signup";
import ModalLogin from "@/components/modals/modal-login";
import ModalUploadImage from "@/components/modals/modal-upload-image";
import ModalUserNotificationUnsubscribe from "@/components/modals/modal-user-notification-unsubscribe";
import ModalConfirmation from "@/components/modals/modal-confirmation";
import ModalImageModerationNotification from "@/components/modals/modal-image-moderation-notification";
import ModalChatActivityReward from "@/components/modals/modal-chat-activity-reward";
import ModalChatRewardSentNotification from "@/components/modals/modal-chat-reward-sent-notification";
import ModalIncomingFantasyRequest from "@/components/modals/modal-incoming-fantasy-request";
import ModalSubscriptionPaywall from "@/components/modals/modal-subscription-paywall";
import ModalSubscriptionTrialInfo from "@/components/modals/modal-subscription-trial-info";
import ModalPayment from "@/components/modals/modal-payment";
import ModalKarmaPaywall from "@/components/modals/modal-karma-paywall";
import ModalPaymentSuccess from "@/components/modals/modal-payment-success";

export default {
  data() {
    return {
      messageSyncTimeout: null
    }
  },

  computed: {
    ...mapGetters('layout', ['showHeader', 'showFooter', 'showNavbar', 'getPageClass', 'getGlobalLoading']),
    ...mapGetters('user', {user: 'getUser'}),

    globalLoading() {
      return this.getGlobalLoading;
    }
  },

  components: {
    AppNavbar,
    AppOverlay,
    AppHeader,
    AppFooter,
    AppNoteNotifier,
    AppModalNotifier,
    ModalMatch,
    ModalIncomingFantasyRequest,
    ModalUserGuide,
    ModalSendImage,
    ModalProfile,
    ModalNotification,
    ModalSignup,
    ModalLogin,
    ModalUploadImage,
    ModalUserNotificationUnsubscribe,
    ModalConfirmation,
    ModalImageModerationNotification,
    ModalChatActivityReward,
    ModalChatRewardSentNotification,
    ModalSubscriptionPaywall,
    ModalSubscriptionTrialInfo,
    ModalPayment,
    ModalKarmaPaywall,
    ModalPaymentSuccess
  },

  methods: {
    updateLastActivity() {
      api.setLastActivityTimestamp(Date.now() / 1000)
    },

    onVisibilityChanged() {
      if (this.user.id && document.visibilityState === 'visible') {
        this.syncUnseenMessages();
      }
    },

    async syncUnseenMessages() {
      clearTimeout(this.messageSyncTimeout);

      let hasFullAccess = true;
      const accessRules = getAccessRules(this.user);
      for (let rule of accessRules) {
        if (!rule.condition()) {
          hasFullAccess = false;
          break;
        }
      }

      if (hasFullAccess) {
        try {
          await this.$store.dispatch('message/getUnseenMessages');
        } catch (e) {
          //ignore failed request
        }
      }

      this.messageSyncTimeout = setTimeout(() => {
        this.syncUnseenMessages();
      }, 10000);
    }
  },

  watch: {
    user: {
      handler: function(newValue, oldValue) {
        if (newValue && newValue.id !== oldValue.id) {
          this.syncUnseenMessages();

          if (pushSubscriptionManager.isPermissionGranted() && pushSubscriptionManager.isTokenRefreshNeeded()) {
            pushSubscriptionManager.subscribe();
          }

          this.$store.dispatch('karma/getKarmaSettings');
          startHotjar();
        }
      },
      deep: true
    }
  },

  async mounted() {
    this.updateLastActivity();
    ['click','scroll', 'keydown'].forEach(event => {
      document.addEventListener(event, this.updateLastActivity);
    });

    document.addEventListener('visibilitychange', this.onVisibilityChanged);

    this.$router.isReady().then(async () => {
      const token = this.$route.query.w;
      if (token) {
        try {
          await CampaignService.track(token, this.$route.query);
        } catch (e) {
          // ignore failed request
        }
      }
    });

    this.$store.dispatch('experiment/getExperimentResults');
  },

  beforeUnmount() {
    clearTimeout(this.messageSyncTimeout);
    ['click','scroll', 'keydown'].forEach(event => {
      document.removeEventListener(event, this.updateLastActivity);
    });

    document.removeEventListener('visibilitychange', this.onVisibilityChanged);
  }
};
</script>

<style lang="scss">
@import "./assets/scss/app.scss";
</style>
