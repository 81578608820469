<template>
  <app-modal
      id="send-image-modal"
      :hide-footer="false"
      @show="loadImages"
      @shown="isShown = true"
      @hidden="isShown = false"
  >
    <template #title>
      <h4 class="text-center">Send image</h4>
      <div class="text-bold text-center">Choose from gallery or upload new one</div>
    </template>

    <div class="rounded-bg overflow-hidden" v-if="isShown">
      <app-overlay :show="isLoading">
        <b-container fluid class="vertical-gutter">
          <user-gallery-carousel
              :images="publicImages"
              gallery-title="Profile photos"
              @image-click="imageSelected"
          />
        </b-container>

        <b-container fluid class="vertical-gutter private">
          <user-gallery-carousel
              :images="privateImages"
              gallery-title="Private photos"
              @image-click="imageSelected"
          />
        </b-container>
      </app-overlay>
    </div>

    <template #footer>
      <div class="actions">
        <b-button variant="secondary" class="wide" @click="uploadChatImage">
          <span>Upload new</span>
          <i class="icon icon-upload"/>
        </b-button>
      </div>
    </template>
  </app-modal>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import AppModal from "@/components/modals/modal";
import UserGalleryCarousel from "@/components/user-gallery-carousel";
import AppOverlay from "@/components/overlay";
import {uploadChatImage} from "@/utils/imageUploader";

export default {
  name: 'ModalSendImage',
  data() {
    return {
      isLoading: false,
      isShown: false
    }
  },
  components: {
    UserGalleryCarousel,
    AppModal,
    AppOverlay
  },
  computed: {
    ...mapGetters('userImage', {
      publicImages: 'getPublicImages',
      privateImages: 'getPrivateImages'
    })
  },
  methods: {
    ...mapActions('userImage', ['getAlbumImages', 'setAttachedChatImage']),
    ...mapActions('modal', ['closeModal']),
    ...mapGetters('user', {user: 'getUser'}),

    async loadImages() {
      if (!this.publicImages.length && !this.privateImages.length) {
        this.isLoading = true;
        await this.getAlbumImages();
        this.isLoading = false;
      }
    },

    imageSelected(image) {
      this.setAttachedChatImage(image);
      this.closeModal('send-image-modal');
    },

    async uploadChatImage() {
      uploadChatImage();
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>

