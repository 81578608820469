<template>
  <div class="secure-payment-badges">
    <div class="norton"></div>
    <div class="security"></div>
    <div class="verisign"></div>
  </div>
</template>

<script>
export default {
  name: 'SecurePaymentBadges'
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
