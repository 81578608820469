<template>
  <app-modal
      id="match-modal"
      :hide-footer="false"
      :hide-header-close="isLoading || forcedFantasyStart"
      content-class="full-screen-xs"
      no-close-on-backdrop
      no-close-on-esc
      footer-class="light"
      @hidden="onHidden"
      @shown="onShown"
  >
    <div class="animation" :class="animateOrbit ? 'active' : ''">
      <div class="orbit">
        <div class="match-badge">
          <i class="icon icon-connection"/>
        </div>
        <div class="avatars" :class="animateAvatars ? 'animate' : ''" v-if="match">
          <app-avatar :source="match.conversation.user.avatar.urls.square" class="ratio-1-1 user-avatar"/>
          <app-avatar :source="match.conversation.profile.avatar.urls.square" class="ratio-1-1 profile-avatar"/>
        </div>
        <transition-group name="fade" tag="div">
          <app-tag v-for="kink in animatedKinks" :key="kink.id" :tag="kink"/>
        </transition-group>
      </div>
    </div>

    <h4>You connected!</h4>

    <p class="subtitle text-bold">
      {{ commonKinks.length
        ? `You and ${match.conversation.profile.username} have common desires:`
        : `You can now message each other.`
      }}
    </p>

    <app-tags-list v-if="commonKinks.length" :tags="commonKinks"/>

    <app-overlay :show="isLoading" class="align-self-stretch mt-3">
      <profile-fantasy
          :profile="match.conversation.profile"
          :fantasy="this.matchedFantasy"
          :show-join-fantasy-button="!forcedFantasyStart"
      />
    </app-overlay>

    <template #footer>
      <b-button
          variant="primary"
          class="send-message wide"
          @click="startConversation"
          :disabled="isLoading"
      >
        <i class="icon icon-message-circle"/>
        <span>{{ forcedFantasyStart ? 'Start playing!' : 'Send message' }}</span>
      </b-button>
    </template>
  </app-modal>
</template>

<script>
import AppModal from "@/components/modals/modal";
import AppTagsList from "@/components/tags-list";
import AppAvatar from "@/components/avatar";
import {mapActions, mapGetters} from "vuex";
import AppTag from "@/components/tag";
import AppOverlay from "@/components/overlay";
import fantasyService from "@/api/services/fantasyService";
import ProfileFantasy from "@/components/profile-fantasy"
import {getOnboardingFunnel} from "@/utils/onboardingFunnel";

export default {
  name: "ModalMatch",

  components: {
    ProfileFantasy,
    AppOverlay,
    AppTag,
    AppAvatar,
    AppTagsList,
    AppModal
  },

  data() {
    return {
      matches: [],
      match: null,
      animateOrbit: false,
      animateAvatars: false,
      animatedKinks: [],
      isLoading: false,
      matchedFantasy: null,
      onboardingFunnel: null,
      forcedFantasyStart: false
    }
  },

  computed: {
    ...mapGetters('user', {user: 'getUser'}),
    ...mapGetters('modal', ['getModal']),
    ...mapGetters('message', {unseenMessages: 'getUnseenMessages'}),
    ...mapGetters('payment', {paymentInProgress: 'getPaymentInProgress'}),
    ...mapGetters('conversation', {conversations: 'getConversations'}),

    modal() {
      return this.getModal('match-modal');
    },

    commonKinks() {
      let commonKinks = [];
      if (this.match) {
        const profileKinkIds = this.match.conversation.profile.kinks.map(kink => kink.id);
        this.match.conversation.user.kinks.forEach(kink => {
          if (profileKinkIds.includes(kink.id)) {
            commonKinks.push(kink);
          }
        });
      }

      return commonKinks;
    }
  },

  methods: {
    ...mapActions('message', ['markMessageAsSeen']),
    ...mapActions('modal', ['openModal']),
    ...mapActions('user', ['updateUser']),
    ...mapActions('conversation', ['getConversations']),

    onHidden() {
      this.animateOrbit = false;
      this.animateAvatars = false;
      this.animatedKinks = [];
      this.match = null;
      this.matchedFantasy = null;

      this.pullMatch();
    },

    async onShown() {
      this.animateOrbit = true;
      for (let i = 0; i < 4; i++) {
        setTimeout(() => {
          const kink = this.commonKinks[i];
          if (kink) {
            this.animatedKinks.push(this.commonKinks[i]);
          }
        }, 400 * i + 1500);
      }

      setTimeout(() => this.animateAvatars = true, 1500 + 400 * this.commonKinks.slice(0, 3).length);

      const conversation = this.match.conversation;
      this.isLoading = true;
      try {
        const promises = [fantasyService.getMatchedFantasy(conversation.profile.id)];
        if (!this.conversations.length) {
          promises.push(this.getConversations());
        }

        let result = await Promise.all(promises);
        this.matchedFantasy = result[0];
        this.forcedFantasyStart = this.onboardingFunnel.firstMatchForcedFantasyStart && this.conversations.length <= 1;
      } catch (e) {
        // ignore failed request
      }
      this.isLoading = false;

      this.updateUser(conversation.user);
    },

    pullMatch() {
      if (this.paymentInProgress || !this.matches.length) {
        return;
      }

      if (!this.match) {
        this.match = this.matches.shift();
      }
    },

    async startConversation() {
      if (!this.match) {
        return;
      }

      if (this.forcedFantasyStart && this.matchedFantasy) {
        this.isLoading = true;
        try {
          await fantasyService.startFantasy(this.matchedFantasy.id, this.match.conversation.profile.id);
        } catch (e) {
          // ignore errors, just go to the chat
        }
      }

      this.isLoading = false;
      await this.$router.push(`/chat/${this.match.conversation.id}`);
    }
  },

  watch: {
    unseenMessages: {
      handler: function(newVal) {
        const matches = newVal.filter(message => message.type === 'match');
        matches.forEach(match => {
          if (match.id !== this.match?.id && !this.matches.find(item => item.id === match.id)) {
            this.matches.push(match);
          }
        });

        this.pullMatch();
      },
      deep: true
    },

    paymentInProgress(newVal) {
      if (!newVal) {
        this.pullMatch();
      }
    },

    match(newVal) {
      if (newVal) {
        if (!this.modal) {
          this.openModal({id: 'match-modal'});
        }
        this.markMessageAsSeen(newVal);
      }
    }
  },

  mounted() {
    this.onboardingFunnel = getOnboardingFunnel(this.user);
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
