import experimentService from "@/api/services/experimentService";

const SET_EXPERIMENT_RESULTS = 'SET_EXPERIMENT_RESULTS';

export default {
  namespaced: true,

  state: {
    experimentResults: {}
  },

  getters: {
    getExperimentResults(state) {
      return state.experimentResults;
    },
    getExperimentResult(state) {
      return (experimentToken) => state.experimentResults[experimentToken] || null;
    }
  },

  mutations: {
    [SET_EXPERIMENT_RESULTS](state, results) {
      state.experimentResults = results;
    }
  },

  actions: {
    async getExperimentResults({commit}) {
      const experimentResults = await experimentService.getExperimentResults();
      commit(SET_EXPERIMENT_RESULTS, experimentResults);
    }
  }
}
