import api from '@/api/api';

class KarmaService {
    dailyCheckin() {
        return api.axios.post('karma/daily-checkin', {}).then((response) => {
            return response.data.user;
        });
    }

    getUserKarmaStats() {
        return api.axios.get('karma/user-karma-stats').then((response) => {
            return response.data.stats;
        });
    }

    getKarmaSettings() {
        return api.axios.get('karma/karma-setting-list').then((response) => {
            return response.data;
        });
    }
}

export default new KarmaService();
