import store from "@/store";
import {getOnboardingFunnel} from "@/utils/onboardingFunnel";

export async function loginUserByLink(route, next) {
  await store.dispatch('layout/setLoading', true);
  try {
    await store.dispatch('auth/loginLink', route.query.login_check_url);
    const query = {...route.query};
    delete query.login_check_url;
    next({name: route.name, query});
  } catch (e) {
    next('/');
  }
  await store.dispatch('layout/setLoading', false);
}

export async function checkRouteAccess(route, next) {
  let user = store.getters['user/getUser'];
  if (store.getters['auth/isLoggedIn'] && !user.id) {
    await store.dispatch('layout/setLoading', true);
    try {
      user = await store.dispatch('user/getUser');
    } catch (e) {
      return;
    } finally {
      store.dispatch('layout/setLoading', false);
    }
  }

  let isPrivate = route.meta.isPrivate;
  if (isPrivate instanceof Function) {
    isPrivate = isPrivate();
  }

  if (!isPrivate) {
    next();
    return;
  }

  if (!user.id) {
    next('/');
    return;
  }

  const accessRules = getAccessRules(user);
  for (let rule of accessRules) {
    if (!rule.condition()) {
      if (!rule.allowedPaths.includes(route.path)) {
        next({path: rule.allowedPaths[0], query: route.query});
      } else {
        next();
      }

      if (rule.callback) {
        rule.callback();
      }

      return;
    }
  }

  next();
}

export function isAccessRestricted(user) {
  return user.access_restriction_timestamp && user.access_restriction_timestamp < Date.now() / 1000;
}

export function getAccessRules(user) {
  return [
    {
      condition: () => user.completed_basic_info,
      allowedPaths: ['/getting-started']
    },
    {
      condition: () => {
        const onboardingFunnel = getOnboardingFunnel(user);
        if (!onboardingFunnel.forcedFantasyJoin) {
          return true;
        }

        return user.completed_onboarding || !user.meta.completed_guide;
      },
      allowedPaths: ['/fantasies', '/join-fantasy']
    },
    {
      condition: () => !isAccessRestricted(user),
      allowedPaths: ['/my-profile', '/payment/success', '/payment/fail'],
      callback: () => {
        setTimeout(() => {
          const openedModals = store.getters['modal/getOpenedModals'];
          if (!openedModals.length) {
            store.dispatch('modal/openModal', {id: 'subscription-paywall-modal'});
          }
        }, 500);
      }
    },
  ];
}
