import karmaService from "@/api/services/karmaService";

const SET_KARMA_SETTINGS = "SET_KARMA_SETTINGS";

export default {
  namespaced: true,

  state: {
    karma_settings: null,
  },

  getters: {
    getKarmaSettings(state) {
      return state.karma_settings;
    }
  },

  mutations: {
    [SET_KARMA_SETTINGS](state, settings) {
      state.karma_settings = settings;
    }
  },

  actions: {
    async getKarmaSettings({commit}) {
      const karmaSettings = await karmaService.getKarmaSettings();
      commit(SET_KARMA_SETTINGS, karmaSettings);
      return karmaSettings;
    },
  }
}
