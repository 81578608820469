<template>
  <app-overlay :show="isLoading" z-index="100" opacity="0.5"  :transparent="false" fixed no-wrap/>
  <slot v-bind:startVerification="startVerification"/>
</template>

<script>
import ageVerificationService from "@/api/services/ageVerificationService";
import AppOverlay from "@/components/overlay";
import {mapActions} from "vuex";
import {
  AGE_VERIFICATION_STATUS_ACCEPTED,
  AGE_VERIFICATION_STATUS_REJECTED,
  AGE_VERIFICATION_STATUS_ON_REVIEW
} from "@/utils/constants";
import "@/lib/berbix-verify";

export default {
  name: 'AgeVerification',
  data() {
    return {
      isLoading: false,
      verificationHandler: null
    };
  },
  components: {
    AppOverlay
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('user', ['getUser']),

    async startVerification() {
      this.isLoading = true;
      try {
        const verification = await ageVerificationService.startVerification();
        if (verification.status === AGE_VERIFICATION_STATUS_ON_REVIEW) {
          this.openModal({
            id: "notification-modal",
            message: '' +
                'You already finished the verification process. Your documents will be reviewed by the moderator. ' +
                'We will notify you as soon as they are verified.'
          });
        } else {
          this.verificationHandler.open({
            clientToken: verification.client_token,
            modal: 'true',
            showCloseModalButton: 'true'
          });
        }
      } catch (e) {
        if (e.response && e.response.status >= 400) {
          this.openModal({id: "notification-modal", message: e.response.data.message});
        }
      }
      this.isLoading = false;
    },

    async onVerificationComplete() {
      try {
        const status = await ageVerificationService.finishVerification();
        let message;
        if (status === AGE_VERIFICATION_STATUS_REJECTED) {
          message = 'Your age verification has been rejected.';
        } else if (status === AGE_VERIFICATION_STATUS_ACCEPTED) {
          message = "Congratulations! You've successfully verified your age.";
        } else {
          message = 'Your documents will be reviewed by the moderator. We will notify you as soon as they are verified.';
        }

        this.openModal({id: "notification-modal", message});
      } catch (e) {
        if (e.response && e.response.status >= 400) {
          this.openModal({id: "notification-modal", message: e.response.data.message});
        }
      }

      this.getUser();
    }
  },

  mounted() {
    this.verificationHandler = window.BerbixVerify.configure({
      onComplete: () => {
        this.onVerificationComplete();
      },
    });
  }
}
</script>
