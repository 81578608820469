import api from '@/api/api'

class UserImageService {
  getAlbumImages() {
    return api.axios.get('user-image/get-album-images').then(response => response.data.images);
  }

  getChatImages() {
    return api.axios.get('user-image/get-chat-images').then(response => response.data.images);
  }

  setAvatar(imageId) {
    return api.axios.post('user-image/set-avatar/' + imageId)
      .then(response => response.data.image);
  }

  updateImageVisibility(imageId, visibility) {
    return api.axios.patch('user-image/update-visibility/' + imageId, {
      visibility: visibility
    }).then(response => response.data.image);
  }

  deleteImage(imageId) {
    return api.axios.delete('user-image/delete/' + imageId, {
      headers: {
        "Content-Type": "application/json"
      },
      data: {}
    });
  }

  uploadImage(file, visibility, isAvatar = false) {
    const formData = new FormData();
    formData.append("image", file);
    formData.append('visibility', visibility);
    formData.append('is_avatar', isAvatar ? 1 : 0);

    return api.axios.post('user-image/upload', formData).then(response => response.data.image);
  }
}

export default new UserImageService();
