<template>
  <b-modal
      v-model="isModalOpened"
      v-bind="$attrs"
      :id="id"
      :hideFooter="hideFooter"
      :style="style"
      @show="onShow"
      @shown="onShown"
  >
    <template #title>
      <slot name="title" :modal="modal"/>
    </template>
    <template #header-close>
      <i class="icon icon-close"/>
    </template>
    <slot v-if="isModalOpened" :modal="modal"/>
    <template #footer>
      <slot name="footer"/>
    </template>
  </b-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'AppModal',
  props: {
    id: {
      type: String,
      required: true
    },
    hideFooter: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['show', 'shown', 'update:modelValue'],
  data() {
    return {
      isModalOpened: false,
      style: {}
    }
  },
  computed: {
    ...mapGetters('modal', ['getModal']),
    modal() {
      return this.getModal(this.id)
    }
  },
  methods: {
    ...mapActions('modal', ['openModal', 'closeModal']),
    onShow(e) {
      const idx = document.querySelectorAll('.modal.show').length;
      this.style = {'z-index': 1055 + (10 * idx)};
      this.$emit('show', e);
    },
    onShown(e) {
      this.$nextTick(() => {
        const idx = document.querySelectorAll('.modal.show').length - 1;
        const backdrops = document.querySelectorAll('.modal-backdrop');
        const lastBackdrop = backdrops[backdrops.length - 1];
        if (lastBackdrop) {
          lastBackdrop.style.zIndex = 1054 + (10 * idx);
        }
        this.$emit('shown', e);
      });
    }
  },
  mounted() {
    this.isModalOpened = this.modelValue;
  },
  watch: {
    modelValue: function(newVal) {
      this.isModalOpened = newVal;
    },
    isModalOpened: async function(newVal) {
      if (newVal) {
        if (!this.modal) {
          await this.openModal({id: this.id});
        }
      } else {
        await this.closeModal(this.id);
      }

      this.$emit('update:modelValue', newVal);
    },
    modal: {
      handler: function(newVal) {
        this.isModalOpened = !!newVal;
      },
      deep: true
    }
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
