<template>
  <app-overlay :show="isLoading">
    <div class="card" :class="{'editable': editable, 'checked': selectedCard && selectedCard.id === card.id}">
      <b-form-radio
          :id="`credit-card-${card.id}`"
          name="credit-card"
          :value="card"
          v-model="selectedCard"
      >
        <div class="prime-label" v-if="card.primary">Primary when paying online</div>
        <div class="custom-control-label">
          <div class="icon" :class="`icon-${card.type}`"></div>
          <h4 class="type">{{ card.name }}</h4>
          <div class="expires-display-name">
            <span>{{ card.expiration_label }}</span>
            <span>Ends with ***{{ card.last_digits }}</span>
          </div>
          <i class="icon icon-edit edit" :class="{'primary': editable}" @click.prevent="editable = !editable"></i>
        </div>
      </b-form-radio>

      <div class="set-primary" v-if="editable">
        The primary card is the default card for payments.<br/>
        If you want this card as your primary:<br/>
        <b-link @click.prevent="setPrimary">Set as primary method</b-link>
      </div>
      <div class="remove" v-if="editable">
        <b-link @click.prevent="remove">Remove card</b-link>
      </div>
    </div>
  </app-overlay>
</template>

<script>
import AppOverlay from "@/components/overlay";
import {mapActions} from "vuex";

export default {
  name: 'CreditCard',
  components: {AppOverlay},

  data() {
    return {
      editable: false,
      isLoading: false
    }
  },

  emits: ['update:modelValue'],

  computed: {
    selectedCard: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },

  props: {
    card: {
      type: Object
    },

    modelValue: {
      type: Object,
      default: () => {}
    },
  },

  methods: {
    ...mapActions('creditCard', ['removeCard', 'setPrimaryCard']),

    async remove() {
      this.isLoading = true;
      await this.removeCard(this.card.id);
      this.isLoading = false;
    },

    async setPrimary() {
      this.isLoading = true;
      await this.setPrimaryCard(this.card.id);
      this.isLoading = false;
    },
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
