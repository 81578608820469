import pushSubscriptionService from "@/api/services/pushSubscriptionService";
import {getMessaging, getToken, isSupported} from "firebase/messaging";
import firebaseConfig from "@/config/firebase";
import {initializeApp} from "firebase/app";

export default {
  isPermissionGranted() {
    return window.Notification && window.Notification.permission === 'granted';
  },

  isPermissionDenied() {
    return window.Notification && window.Notification.permission === 'denied';
  },

  async isMessagingSupported() {
    return await isSupported();
  },

  subscribe() {
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    return getToken(messaging, {
      vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY
    }).then(async (currentToken) => {
      await pushSubscriptionService.subscribe(currentToken);
      localStorage.setItem('push_subscription_token_updated_on', Date.now().toString());
    });
  },

  isTokenRefreshNeeded() {
    const tokenUpdatedOn = localStorage.getItem('push_subscription_token_updated_on');
    return tokenUpdatedOn === null || (Date.now() - tokenUpdatedOn) > 86400000;
  }
}
