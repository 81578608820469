<template>
  <app-modal
      id="incoming-fantasy-request-modal"
      centered
      :hide-footer="false"
      :no-fade="true"
      footer-class="fill-buttons compact"
      body-class="text-center"
      no-close-on-backdrop
      hide-header-close
      @hidden="onHidden"
  >
    <template #title>
      <div class="main-info" v-if="fantasyRequest">
        <app-avatar
            :source="fantasyRequest.profile.avatar.urls.square"
            :online="fantasyRequest.profile.online"
            @click="openProfile"
            class="responsive ratio-1-1 extra-large"
        />
        <h4 class="name">{{ fantasyRequest.profile.username }}</h4>
      </div>
    </template>
    <div class="profile" @click="openProfile" v-if="fantasyRequest">
      <app-tags-list v-if="commonKinks.length" :tags="commonKinks" v-model="commonKinks"/>
    </div>

    <h4 class="text-center mt-5">Would you like to Start Fantasy?</h4>

    <div class="rounded-bg grid-mb container-fluid vertical-gutter mt-4">
      <text-quote :text="fantasyRequest.fantasy.text"/>
    </div>

    <template #footer>
      <b-button variant="default" @click="rejectRequest" :disabled="isLoading">
        <i class="icon icon-close"></i>
        <span>No</span>
      </b-button>
      <b-button variant="primary" @click="acceptRequest" :disabled="isLoading">
        <i class="icon icon-check-circle"></i>
        <span>Yes</span>
      </b-button>
    </template>
  </app-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppModal from "@/components/modals/modal";
import AppAvatar from "@/components/avatar";
import AppTagsList from "@/components/tags-list";
import TextQuote from "@/components/text-quote";
import fantasyRequestService from "@/api/services/fantasyRequestService";

export default {
  name: 'ModalIncomingFantasyRequest',

  components: {
    AppAvatar,
    AppModal,
    AppTagsList,
    TextQuote
  },

  data: function () {
    return {
      isLoading: false,
      fantasyRequestMessage: null,
      fantasyRequestMessages: []
    };
  },

  computed: {
    ...mapGetters('modal', ['getModal']),
    ...mapGetters('user', {user: 'getUser'}),
    ...mapGetters('message', {unseenMessages: 'getUnseenMessages'}),
    ...mapGetters('payment', {paymentInProgress: 'getPaymentInProgress'}),

    fantasyRequest() {
      return this.fantasyRequestMessage ? this.fantasyRequestMessage.fantasy_request : null;
    },

    commonKinks() {
      let commonKinks = [];
      if (this.fantasyRequest) {
        const profileKinkIds = this.fantasyRequest.profile.kinks.map(kink => kink.id);
        this.user.kinks.forEach(kink => {
          if (profileKinkIds.includes(kink.id)) {
            commonKinks.push(kink);
          }
        });
      }

      return commonKinks;
    }
  },

  methods: {
    ...mapActions('modal', ['openModal', 'closeModal']),
    ...mapActions('user', ['getUser']),
    ...mapActions('message', ['markMessageAsSeen']),

    openProfile() {
      this.openModal({id: 'profile-modal', profileId: this.fantasyRequest.profile.id});
    },

    onHidden() {
      this.fantasyRequestMessage = null;
      this.pullFantasyRequestMessage();
    },

    pullFantasyRequestMessage() {
      if (this.paymentInProgress || !this.fantasyRequestMessages.length) {
        return;
      }

      if (!this.fantasyRequestMessage) {
        this.fantasyRequestMessage = this.fantasyRequestMessages.shift();
      }
    },

    async rejectRequest() {
      try {
        await fantasyRequestService.rejectFantasyRequest(this.fantasyRequest.id);
      } catch (e) {
        //ignore failed request
      }
      this.closeModal('incoming-fantasy-request-modal');
    },

    async acceptRequest() {
      this.isLoading = true;
      try {
        const conversation = await fantasyRequestService.acceptFantasyRequest(
            this.fantasyRequest.id
        );
        await this.$router.replace({path: '/chat/' + conversation.id});
      } catch (e) {
        if (e.response) {
          if ([400, 404, 403].indexOf(e.response.status) !== -1) {
            this.openModal({id: 'notification-modal', message: e.response.data.message});
          }
        }
      }

      this.closeModal('incoming-fantasy-request-modal');
      this.isLoading = false;
    },
  },

  watch: {
    unseenMessages: {
      handler: function (newVal) {
        const fantasyRequestMessages = newVal.filter(message => message.type === 'fantasy-request');
        fantasyRequestMessages.forEach((message) => {
          if (!this.fantasyRequestMessages.find(item => item.id === message.id)) {
            this.fantasyRequestMessages.push(message);
          }
        });

        this.pullFantasyRequestMessage();
      },
      deep: true
    },

    paymentInProgress(newVal) {
      if (!newVal) {
        this.pullFantasyRequestMessage();
      }
    },

    fantasyRequestMessage(newVal) {
      if (newVal) {
        if (!this.modal) {
          this.openModal({id: 'incoming-fantasy-request-modal'});
          this.getUser();
        }
        this.markMessageAsSeen(newVal);
      }
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
