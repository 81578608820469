<template>
  <app-modal
      id="chat-reward-sent-notification-modal"
      ref="modal"
      hide-header
      no-fade
      modal-class="chat-reward-notification"
  >
    <template v-slot="{modal}">
      <chat-reward-animation
          :karma="modal.karma"
          description-text="You've sent a badge"
          @animation-finished="closeModal()"
      />
    </template>
  </app-modal>
</template>
<script>
import AppModal from "@/components/modals/modal";
import ChatRewardAnimation from "@/components/chat-reward-animation";

export default {
  name: 'ModalChatRewardSentNotification',
  components: {
    ChatRewardAnimation,
    AppModal,
  },

  methods: {
    closeModal() {
      this.$store.dispatch('modal/closeModal', 'chat-reward-sent-notification-modal');
    }
  }
}
</script>
