<template>
  <div class="element-highligter modal-backdrop show" :style="{'z-index': baseZIndex}"/>
</template>

<script>
export default {
  name: 'ElementHighlighter',

  data() {
    return {
      baseZIndex: 2000
    };
  },

  props: {
    elements: {
      type: Array,
      default: () => []
    },
    clickable: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    toggleHighlight(highlight) {
      if (highlight) {
        this.elements.forEach((element) => {
          element.classList.add('highlighted');
          if (!this.clickable) {
            element.classList.add('unclickable');
          }
          element.dataset.prevZIndex = element.style.zIndex;
          element.style.zIndex = this.baseZIndex + 1;
        });
      } else {
        this.elements.forEach((element) => {
          if (element.classList.contains('highlighted')) {
            element.classList.remove('highlighted', 'unclickable');
            element.style.zIndex = element.dataset.prevZIndex;
            element.dataset.prevZIndex = null;
          }
        });
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.toggleHighlight(true);
    });
  },

  unmounted() {
    this.toggleHighlight(false);
  }
}
</script>
