import influencerService from "@/api/services/influencerService";

const ADD_INFLUENCER = "ADD_INFLUENCER";

export default {
  namespaced: true,

  state: {
    influencers: []
  },

  getters: {
    getInfluencerById(state) {
      return (id) => {
        return state.influencers.find(item => item.id === id);
      };
    }
  },

  mutations: {
    [ADD_INFLUENCER](state, influencer) {
      state.influencers = [...state.influencers.filter(item => item.id !== influencer.id), influencer];
    },
  },

  actions: {
    async getInfluencer({commit}, influencerId) {
      const influencer = await influencerService.getInfluencer(influencerId);
      commit(ADD_INFLUENCER, influencer);
    },
  }
}
