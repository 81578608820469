import store from "@/store";
import userService from "@/api/services/userService";

let boringTimer;
store.watch(
  function (state) {
    return state.user.user;
  },
  function (user) {
    if (!user.id) {
      return;
    }

    clearTimeout(boringTimer);
    if (user.bored_status_remaining_time !== null) {
      boringTimer = setTimeout(async () => {
        const paymentInProgress = store.getters['payment/getPaymentInProgress'];
        if (!paymentInProgress) {
          const user = await userService.setBored();
          store.dispatch('user/updateUser', user);
        }
      }, user.bored_status_remaining_time * 1000);
    }
  },
  {
    deep: true
  }
);
