export const IMAGE_VISIBILITY_PRIVATE = 0;
export const IMAGE_VISIBILITY_PUBLIC = 1;
export const IMAGE_VISIBILITY_HIDDEN = 2;

export const IMAGE_STATUS_REJECTED = 0;
export const IMAGE_STATUS_ON_MODERATION = 1;
export const IMAGE_STATUS_APPROVED = 2;

export const RELATION_STATUS_NEW = 0;
export const RELATION_STATUS_MATCH = 1;
export const RELATION_STATUS_DELETED = 2;
export const RELATION_INITIATOR_USER = 0;
export const RELATION_INITIATOR_PROFILE = 1;
export const RELATION_DECISION_LIKE = 1;
export const RELATION_DECISION_DISLIKE = 0;

export const FANTASY_CATEGORY_ACTIVE = 'active';
export const FANTASY_CATEGORY_POPULAR = 'popular';
export const FANTASY_CATEGORY_NEW = 'new';
export const FANTASY_CATEGORY_LIKE = 'like';

export const AGE_VERIFICATION_STATUS_ON_REVIEW = 1;
export const AGE_VERIFICATION_STATUS_REJECTED = 2;
export const AGE_VERIFICATION_STATUS_ACCEPTED = 3;

export const MESSAGE_SENT_BY_USER = 1;
export const MESSAGE_SENT_BY_PROFILE = 2;

export const MESSAGE_MAX_CHAR_COUNT = 500;

export const SIGNUP_FUNNEL_DEFAULT = 0;
export const SIGNUP_FUNNEL_INFLUENCER = 1;

export const CONVERSATION_REPORT_LABELS = {
    0: 'Inappropriate messages',
    1: 'Inappropriate photos',
    2: 'Feels like spam',
    3: 'Other'
};

export const NOTIFICATION_TYPES = [
    {
        value: 'NewMessageNotification',
        label: 'Notifications about new messages',
        unsubscribe_label: 'I do not want to be informed when I get a new message.'
    },
    {
        value: 'ApprovedFantasyNotification',
        label: 'Notifications about approved fantasies',
        unsubscribe_label: 'I do not want to be informed when my fantasy is approved.'
    },
    {
        value: 'SomeoneLookedProfileNotification',
        label: "Notifications about someone's been checking me out",
        unsubscribe_label: "I do not want to be informed someone's been checking me out."
    },
    {
        value: 'CheckinDailyKarmaNotification',
        label: 'Notifications about daily karma',
        unsubscribe_label: 'I do not want to be informed when the daily karma is available.'
    },
    {
        value: 'ProfileWithCommonInterestNotification',
        label: 'Notifications about registered users with similar interests',
        unsubscribe_label: 'I do not want to be informed when users with similar interests signed up.'
    },
    {
        value: 'ProfileLikedSameFantasyNotification',
        label: 'Notifications about profiles who liked similar fantasies',
        unsubscribe_label: 'I do not want to be informed when someone liked my juiciest fantasies.'
    }
];

export const GENDER_EXTRA_OPTIONS = {
    'Nonbinary': 'NB',
    'Genderqueer': 'GQ',
    'Trans male/masc.': 'TM',
    'Trans female/fem': 'TF',
    'Bi/pangender': 'BI',
    'Other': 'O'
};
