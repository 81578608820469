import api from "@/api/api";

class CreditCardService {
  getCreditCards() {
    return api.axios.get('credit-card/get-list').then((response) => {
      return response.data.cards;
    });
  }

  setPrimaryCard(cardId) {
    return api.axios.post(`credit-card/set-main/${cardId}`).then((response) => {
      return response.data.cards;
    });
  }

  removeCard(cardId) {
    return api.axios.delete(`credit-card/remove/${cardId}`).then((response) => {
      return response.data.cards;
    });
  }

  getWidgetUrl() {
    return api.axios.get(`credit-card/get-widget-url`).then((response) => {
      return response.data.widget_url;
    });
  }
}

export default new CreditCardService();
