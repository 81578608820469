import chatActivityRewardsService from "@/api/services/chatActivityRewardsService";

const SET_PROFILES = "SET_PROFILES";

export default {
    namespaced: true,

    state: {
        profiles: []
    },

    getters: {
        getProfiles(state) {
            return state.profiles;
        }
    },

    mutations: {
        [SET_PROFILES](state, profiles) {
            state.profiles = profiles;
        },
    },
    actions: {
        async getProfiles({commit}) {
            const profiles = await chatActivityRewardsService.getProfilesForReward();
            commit(SET_PROFILES, profiles);
        },

        async sendRewardToProfile({dispatch}, profileId) {
            const reward = await chatActivityRewardsService.sendRewardToProfile(profileId);
            dispatch('user/updateUser', reward.user, {root: true});
            return reward;
        }
    }
}
