<template>
  <div class="tag" :data-tag-id="tag.id">
    {{ tag.name }}
    <slot />
  </div>
</template>

<script>

export default {
  name: 'AppTag',
  props: {
    tag: {
      type: Object,
      default: () => {},
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
