import conversationService from "@/api/services/conversationService";

const SET_CONVERSATIONS = "SET_CONVERSATIONS";
const ADD_CONVERSATION = "ADD_CONVERSATION";
const REMOVE_CONVERSATION = "REMOVE_CONVERSATION";

export default {
  namespaced: true,

  state: {
    conversations: [],
    syncIntervalId: null,
    syncListenerCount: 0
  },

  getters: {
    getConversations(state) {
      return [...state.conversations].sort(function (a, b) {
        const aTimestamp = a.last_item ? a.last_item.timestamp : a.created_timestamp;
        const bTimestamp = b.last_item ? b.last_item.timestamp : b.created_timestamp;

        return bTimestamp - aTimestamp;
      });
    },
    getConversationById(state) {
      return (id) => {
        return state.conversations.find(conversation => conversation.id === id);
      };
    }
  },

  mutations: {
    [SET_CONVERSATIONS](state, conversations) {
      state.conversations = conversations;
    },

    [ADD_CONVERSATION](state, conversation) {
      for (let i = 0; i < state.conversations.length; i++) {
        if (state.conversations[i].id === conversation.id) {
          state.conversations[i] = conversation;
          state.conversations = [...state.conversations];
          return;
        }
      }

      state.conversations = [...state.conversations, conversation];
    },

    [REMOVE_CONVERSATION](state, conversation) {
      state.conversations = state.conversations.filter((element) => element.id !== conversation.id);
    },
  },

  actions: {
    async getConversations({commit}) {
      const conversations = await conversationService.getConversations();
      commit(SET_CONVERSATIONS, conversations);
    },

    async getConversation({commit}, id) {
      const conversation = await conversationService.getConversation(id);
      if (conversation) {
        commit(ADD_CONVERSATION, conversation);
      }
    },

    addConversation({commit}, conversation) {
      commit(ADD_CONVERSATION, conversation);
    },

    removeConversation({commit}, conversation) {
      commit(REMOVE_CONVERSATION, conversation);
    },
  }
}
