<template>
  <div class="connection-avatars" v-if="conversations.length">
    <app-avatar
        v-for="conversation in conversations"
        :key="conversation.id"
        :source="conversation.profile.avatar.urls.square"
        size="extra-small"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AppAvatar from "@/components/avatar";

export default {
  name: "ConnectionAvatars",

  components: {
    AppAvatar,
  },

  computed: {
    ...mapGetters('conversation', {conversations: 'getConversations'}),
  },

  async mounted() {
    if (!this.conversations.length) {
      this.$store.dispatch('conversation/getConversations');
    }
  },
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
