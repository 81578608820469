<template>
  <app-modal
      id="subscription-trial-info-modal"
      :hide-footer="false"
      :hide-header-close="true"
      header-class="compact"
      content-class="full-screen-xs"
      dialog-class="modal-sm paywall"
      footer-class="fill-buttons light"
      no-close-on-backdrop
      no-close-on-esc
  >
    <template #title>
      <b-button variant="back" @click="openModal('subscription-paywall-modal')">
        <i class="icon icon-arrow-left"></i>
      </b-button>
    </template>

    <div class="trial-info-block" v-if="order.subscriptionType && order.subscriptionType.trial">
      <div class="paywall-header container-fluid vertical-gutter">
        <h3>Welcome to the <span class="interactive-text">family!</span></h3>
        <div>Your erotic adventure starts here…</div>
      </div>

      <div class="trial-list-info mt-4">
        <ul>
          <li>
            <h4>Today</h4>
            <div>Start your full access to PIF</div>
          </li>
          <li>
            <h4 class="trial-reminded-day">Day {{ order.subscriptionType.trial.trial_duration_days - 2 }}</h4>
            <div>Get a reminder about when your trial ends</div>
          </li>
          <li>
            <h4 class="end-trial-duration">Day {{ order.subscriptionType.trial.trial_duration_days }}</h4>
            <div>You’ll be charged on <span class="purchase-date text-bold">{{ order.subscriptionType.trial.rebill_date }}</span>, cancel anytime before</div>
          </li>
        </ul>
        <div class="charged-info">
          You will be charged the premium subscription fee <span class="text-bold">{{ order.subscriptionType.trial.rebill_price }}</span>
          after <span class="trial-duration-label">{{ order.subscriptionType.trial.trial_duration_label }}</span> unless you cancel your trial.
        </div>
      </div>
    </div>

    <template #footer>
      <b-button variant="primary" @click="openModal('payment-modal')" v-if="order.subscriptionType">
        <span>Start my {{ order.subscriptionType.initial_price_label }} trial now</span>
        <i class="icon icon-arrow-right"></i>
      </b-button>
    </template>

  </app-modal>
</template>

<script>
import AppModal from "@/components/modals/modal";
import {mapGetters} from "vuex";

export default {
  name: 'ModalSubscriptionTrialInfo',

  components: {
    AppModal
  },

  computed: {
    ...mapGetters('payment', {order: 'getOrder'}),
  },

  methods: {
    openModal(modalId) {
      this.$store.dispatch('modal/closeModal', 'subscription-trial-info-modal');
      this.$store.dispatch('modal/openModal', {id: modalId});
    }
  }
}
</script>
<style lang="scss">
@import "./index.scss";
</style>
