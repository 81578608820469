<template>
  <div ref="navbar" class="navigation-bar-bottom d-md-none align-self-stretch">
    <b-container fluid>
      <b-row>
        <b-col v-for="link in links" :key="link.id" :cols="link.cols">
          <b-link :to="link.uri" :class="{
            active: $route.path === link.uri,
            pulsating: link.showAnimation && link.showAnimation(),
            'notification-indicator': link.showNotificationIndicator && link.showNotificationIndicator(),
          }" :id="link.id" @click="link.click ? link.click() : null">
            <i class="icon" :class="link.icon"/>
          </b-link>
        </b-col>
      </b-row>

      <popover-explore-fantasies
          container=".navigation-bar-bottom"
          target="nav-fantasies-link"
          :show="showExploreFantasyHint"
          placement="top"
      />
    </b-container>
  </div>

  <element-highlighter v-if="showExploreFantasyHint" :elements="[$refs.navbar]" :clickable="false"/>
</template>

<script>
import {mapGetters} from "vuex";
import ElementHighlighter from "@/components/element-highlighter";
import PopoverExploreFantasies from "@/components/popovers/popover-explore-fantasies";

export default {
  name: 'AppNavbar',

  components: {
    PopoverExploreFantasies,
    ElementHighlighter,
  },
  data() {
    return {
      links: [
        {uri: "/", icon: "icon-home", id: 'nav-home-link', cols: 2},
        {
          uri: "/fantasies",
          icon: "icon-edit",
          id: 'nav-fantasies-link',
          cols: 2,
          showAnimation: () => this.showExploreFantasyHint,
          click: () => this.showExploreFantasyHint = false
        },
        {
          uri: "/chat",
          icon: "icon-message-circle",
          id: 'nav-chat-list-link',
          cols: 4,
          showNotificationIndicator: () => this.getUnseenMessages.filter(item => item.conversation).length
        },
        {
          uri: "/karma",
          icon: "icon-diamond",
          id: 'nav-karma-link',
          cols: 2,
          showNotificationIndicator: () => this.user.chat_activity_reward_progress === 100
        },
        {uri: "/my-profile", icon: "icon-user", id: 'nav-karma-link', cols: 2}
      ],
      showExploreFantasyHint: false
    }
  },

  computed: {
    ...mapGetters('user', {user: 'getUser'}),
    ...mapGetters('layout', ['showFantasyHints']),
    ...mapGetters('message', ['getUnseenMessages']),
  },

  watch: {
    showFantasyHints(newVal) {
      this.$nextTick(() => {
        this.showExploreFantasyHint = newVal
            && this.$route.name !== 'Fantasies'
            && this.$refs.navbar.getClientRects().length !== 0;
      });
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
