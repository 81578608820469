<template>
  <app-modal
      id="user-guide-modal"
      size="sm"
      centered
      no-fade
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      content-class="full-screen-xs"
      header-class="compact"
      :hide-footer="false"
      @shown="onShown"
  >
    <div class="step novice-congratulation-step" v-if="currentStep === 1">
      <h3 class="text-center">Welcome to PIF!<br/> <span>Here’s some Karma for you</span></h3>

      <div>
        You can use Karma to chat, play out exciting fantasies, add fun prompts to conversations, and more.
        Check out our marketplace for other ideas.
      </div>

      <div class="karma-block text-center">
        <div class="karma-scores user-karma" :class="{animate: animateKarmaBalance}">
          <i class="icon icon-diamond-filled"/>
          <span class="balance">+{{ user.karma }}</span>
        </div>
      </div>

      <p class="text-center" v-if="totalSteps > 1">
        Want to know what you can do with it? Come on our guided tour and let us show you around.
      </p>
    </div>

    <div class="step find-partners-step" v-else-if="currentStep === 2">
      <img class="mb-3" v-lazy="require('@/assets/images/user-guide/browse-fantasies.png')" alt="Browse fantasies"/>
      <h3 class="text-center mb-4">Browse fantasies & meet exciting chat partners!</h3>
      <p class="text-center">PIF is a safe and private place</p>
    </div>

    <div class="step meaningful-conversation-step" v-else-if="currentStep === 3">
      <img class="mb-3" v-lazy="require('@/assets/images/user-guide/sexy-chat.png')" alt="Sexy chat">
      <h3 class="text-center mb-4">Be adventurous & <span>have an exciting chat!</span></h3>
      <p class="text-center">Become an amazing storyteller.</p>
    </div>

    <div class="step badge-send-partner-step" v-else-if="currentStep === 4">
      <img class="mb-3" v-lazy="require('@/assets/images/user-guide/appreciation.png')" alt="Appreciation">
      <h3 class="text-center mb-4">Send & receive <span>badges of appreciation</span></h3>
      <p class="text-center">
        Did you just have the best chat ever?
        Award your chat partner with a badge, or earn badges when others award you.
      </p>
    </div>

    <div class="step full-member-step" v-else-if="currentStep === 5">
      <img class="mb-3" v-lazy="require('@/assets/images/user-guide/spend-karma.png')" alt="Spend karma">
      <h3 class="text-center mb-4">Buy or spend your Karma <span>in our marketplace</span></h3>
      <p class="text-center">
        Trade Karma or donate what you’ve earned to a good cause and make the world a better place!
      </p>
    </div>

    <template #footer>
      <b-button variant="primary" v-if="currentStep === 1" @click="nextStep">
        <template v-if="currentStep === totalSteps">
          <span>Ok</span>
          <i class="icon icon-check-circle"/>
        </template>
        <template v-else>
          <span>Let's go</span>
          <i class="icon icon-arrow-right"/>
        </template>
      </b-button>

      <template v-else-if="currentStep > 1 && currentStep < 5">
        <div class="justify-content-between d-flex w-100">
          <step-indicator :steps-total="totalSteps - 1" :step-number="currentStep - 1"/>
          <b-button variant="primary" @click="nextStep">
            <i class="icon icon-arrow-right"/>
          </b-button>
        </div>
      </template>

      <template v-else-if="currentStep === 5">
        <div class="justify-content-between d-flex w-100">
          <step-indicator :steps-total="totalSteps - 1" :step-number="currentStep - 1"/>
          <b-button variant="primary" @click.stop="nextStep">
            <i class="icon icon-check-circle"/>
          </b-button>
        </div>
      </template>
    </template>
  </app-modal>
</template>

<script>
import AppModal from "@/components/modals/modal";
import StepIndicator from "@/components/step-indicator";
import mixpanelUtils from "@/utils/mixpanel";
import {mapActions, mapGetters} from "vuex";
import {getOnboardingFunnel} from "@/utils/onboardingFunnel";

export default {
  name: "ModalUserGuide",
  components: {
    StepIndicator,
    AppModal
  },
  data() {
    return {
      currentStep: 1,
      totalSteps: 5,
      animateKarmaBalance: false,
      onboardingFunnel: null
    }
  },

  computed: {
    ...mapGetters('user', {user: 'getUser'})
  },

  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('message', ['getUnseenMessages']),

    async nextStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep++;
      } else {
        this.closeModal('user-guide-modal');

        mixpanelUtils.trackFinishedUserGuide();
        await this.$store.dispatch('user/addUserMeta', {key: 'completed_guide', value: true});
        this.getUnseenMessages();

        if (this.onboardingFunnel.exploreFantasyHintEnabled) {
          this.$store.dispatch('layout/update', {showFantasyHints: true});
        }
      }
    },

    onShown() {
      setTimeout(() => this.animateKarmaBalance = true, 500);
      setTimeout(() => this.animateKarmaBalance = false, 1500);
    }
  },

  mounted() {
    this.onboardingFunnel = getOnboardingFunnel(this.user);
    this.totalSteps = this.onboardingFunnel.guideStepCount;

    this.$nextTick(() => {
      this.$store.dispatch('modal/openModal', {id: 'user-guide-modal'});
    });
  },
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
