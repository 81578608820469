import api from '@/api/api'

class RelationService {
  establishRelation(profileId, decision) {
    return api.axios.post('relation/establish-relation', {
      profile_id: profileId,
      decision: decision
    }).then(response => response.data.relation);
  }

  getRelation(profileId) {
    return api.axios.get('relation/get-relation/' + profileId).then(response => response.data.relation);
  }
}

export default new RelationService();
