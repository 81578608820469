<template>
  <app-modal
      id="chat-activity-reward-modal"
      content-class="full-screen-xs"
      dialog-class="modal-sm"
      header-class="compact"
      centered
      title-tag="div"
      :hide-footer="false"
      footer-class="fill-buttons light"
      @shown="getProfilesReadyForReward"
  >
    <template #title>
      <h4 class="text-center">Send a Badge</h4>
      <span>Choose someone to send them a token of your appreciation</span>
    </template>

    <app-overlay :show="isLoading">
      <div class="badge-static"/>
      <template v-if="profiles.length">
        <template v-for="profile in profiles" :key="profile.id">
          <input
              :id="'result_profile_' + profile.id"
              type="radio"
              name="profile"
              v-model="profileId"
              :value="profile.id"
          />
          <label :for="'result_profile_' + profile.id" class="">
            <app-avatar :source="profile.avatar.urls.square" class="responsive ratio-1-1" :online="profile.online"/>
            <div class="info">
              <h4>{{ profile.username }}</h4>
            </div>
            <div class="badge"/>
          </label>
        </template>
      </template>
    </app-overlay>

    <template #footer>
      <b-button variant="primary" :disabled="!profileId" @click="sendReward">
        <span>Done</span>
        <karma-cost-badge :cost="karmaSettings ? karmaSettings.income.amount_chat_reward_sent : 0"/>
      </b-button>
    </template>
  </app-modal>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import AppModal from "@/components/modals/modal";
import AppOverlay from "@/components/overlay";
import AppAvatar from "@/components/avatar";
import KarmaCostBadge from "@/components/karma-cost-badge";

export default {
  name: 'ModalChatActivityReward',
  components: {
    KarmaCostBadge,
    AppModal,
    AppOverlay,
    AppAvatar
  },

  data() {
    return {
      isLoading: false,
      profileId: null,
      errorMessage: null,
    }
  },
  computed: {
    ...mapGetters('chatActivityReward', {profiles: 'getProfiles'}),
    ...mapGetters('karma', {karmaSettings: 'getKarmaSettings'}),
    ...mapGetters('modal', ['getModal']),
    modal() {
      return this.getModal('chat-activity-reward-modal');
    }
  },
  methods: {
    ...mapActions('modal', ['openModal', 'closeModal']),

    async loading(callback) {
      this.isLoading = true;
      try {
        await callback();
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.errorMessage = e.response.data.message ?? e.response.data.detail
        }
      }
      this.isLoading = false;
    },

    async getProfilesReadyForReward() {
      await this.loading(async () => {
        await this.$store.dispatch('chatActivityReward/getProfiles');
      });
    },

    async sendReward() {
      if (this.profileId) {
        await this.loading(async () => {
          const reward = await this.$store.dispatch('chatActivityReward/sendRewardToProfile', this.profileId);
          this.$store.dispatch('modal/closeModal', 'chat-activity-reward-modal');
          this.$store.dispatch('modal/openModal', {
            id: "chat-reward-sent-notification-modal",
            karma: reward.karma_operation.amount
          });
        });
      }
    }
  },

  watch: {
    errorMessage: function(newValue) {
      if (newValue) {
        this.$store.dispatch('modal/openModal', {
          id: "notification-modal",
          message: this.errorMessage
        });

        this.errorMessage = null;
      }
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
