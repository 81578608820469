import {SIGNUP_FUNNEL_INFLUENCER} from "@/utils/constants";

class OnboardingFunnel
{
  constructor(forcedFantasyJoin, guideStepCount, exploreFantasyHintEnabled, firstMatchForcedFantasyStart) {
    this.forcedFantasyJoin = forcedFantasyJoin;
    this.guideStepCount = guideStepCount;
    this.exploreFantasyHintEnabled = exploreFantasyHintEnabled;
    this.firstMatchForcedFantasyStart = firstMatchForcedFantasyStart;
  }
}

export function getOnboardingFunnel(user) {
  if (!user) {
    return null;
  }

  switch (user.signup_funnel) {
    case SIGNUP_FUNNEL_INFLUENCER:
      return new OnboardingFunnel(false, 1, false, true);
    default:
      return new OnboardingFunnel(true, 5, true, false);
  }
}
