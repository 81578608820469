const firebaseConfig = {
  apiKey: "AIzaSyD9SEs91KqWOZwH2ok6nRUy1F2MPsJ9lTA",
  authDomain: "pif-app-e6dae.firebaseapp.com",
  projectId: "pif-app-e6dae",
  storageBucket: "pif-app-e6dae.appspot.com",
  messagingSenderId: "210739445223",
  appId: "1:210739445223:web:84cfef39fdb7f13ce11142"
}

export default firebaseConfig;
