import userService from "@/api/services/userService";

const SET_USER = "SET_USER";

let userRequestPromise;
function request(callback) {
  if (userRequestPromise) {
    return userRequestPromise.finally(() => {
      return request(callback)
    });
  }

  userRequestPromise = callback().finally(() => userRequestPromise = null);
  return userRequestPromise;
}

export default {
  namespaced: true,

  state: {
    user: {}
  },

  getters: {
    getUser(state) {
      return state.user;
    },

    getUserRequestPromise(state) {
      return state.userRequestPromise;
    }
  },

  mutations: {
    [SET_USER](state, user) {
      state.user = user;
    },
  },

  actions: {
    async getUser({commit}) {
      return request(async () => {
        const user = await userService.getCurrentUser();
        commit(SET_USER, user);
        return user;
      });
    },

    updateUser({commit}, user) {
      commit(SET_USER, user);
    },

    async updateProfile({commit}, {username, birthday, description}) {
      const user = await userService.updateUserProfile(username, birthday, description);
      commit(SET_USER, user);
    },

    async updatePreferences({commit}, preferences) {
      const user = await userService.updateUserPreferences(preferences);
      commit(SET_USER, user);
    },

    async addUserMeta({commit}, {key, value}) {
      const user = await userService.addUserMeta(key, value);
      commit(SET_USER, user);
    },

    async changeEmail({commit}, email) {
      const user = await userService.changeEmail(email);
      commit(SET_USER, user);
    }
  }
}
