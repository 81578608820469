import api from "@/api/api";

class SubscriptionService {
  getSubscriptionTypes() {
    return api.axios.get('subscription/get-subscription-types').then((response) => {
      return response.data.subscription_types
    });
  }

  buySubscription(subscriptionTypeId, creditCardId) {
    return api.axios.post('subscription/buy-subscription', {
      'subscription_type_id': subscriptionTypeId,
      'credit_card_id': creditCardId
    }).then((response) => {
      return response.data;
    });
  }
}

export default new SubscriptionService();
