import karmaChargeService from "@/api/services/karmaChargeService";

const SET_KARMA_PACKAGES = "SET_KARMA_PACKAGES";

export default {
  namespaced: true,

  state: {
    karmaPackages: []
  },

  getters: {
    getKarmaPackages(state) {
      return state.karmaPackages;
    }
  },

  mutations: {
    [SET_KARMA_PACKAGES](state, karmaPackages) {
      state.karmaPackages = karmaPackages;
    },
  },

  actions: {
    async getKarmaPackages({commit}) {
      const karmaPackages = await karmaChargeService.getKarmaPackages();
      commit(SET_KARMA_PACKAGES, karmaPackages);
    }
  }
}
