<template>
  <div class="step-indicator text-bold">
    <div
        class="step-number"
        v-for="index in stepsTotal"
        :class="index === stepNumber ? 'active' : ''"
        :key="index"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'StepIndicator',
  props: {
    stepNumber: {
      type: Number,
      required: true
    },
    stepsTotal: {
      type: Number,
      required: true
    },
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
