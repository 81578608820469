<template>
  <div ref="tagsList" class="tags-list" :class="{'editable': editable, 'mini-tags': mini}">
    <slot/>

    <app-tag
        v-for="tag in visibleTags"
        :class="{selected: isTagSelected(tag)}"
        @click.stop="onClickTag(tag)"
        :tag="tag"
        :key="tag.id"
    />

    <template v-if="expandable">
      <b-link
          @click.stop.prevent="showAllTagsPopover"
          ref="showAllTags"
          id="show-all-tags"
          class="show-all-tags"
          :class="allTagsBtnClass"
          v-show="!!hiddenTags.length"
      >
        {{ allTagsBtnLabel || `...+${hiddenTags.length}` }}
      </b-link>
      <app-popover
          v-click-outside="hideAllTagsPopover"
          :container="$refs.tagsList"
          :target="$refs.showAllTags"
          triggers="manual"
          :show="allTagsPopoverShown"
          placement="bottom"
      >
        <div class="tags-list" v-if="hiddenTags.length">
          <app-tag
              class="extra-tag"
              v-for="tag in hiddenTags"
              :class="{selected: isTagSelected(tag)}"
              :key="tag.id"
              :tag="tag"
              @click.stop="onClickTag(tag)"
          />
        </div>
      </app-popover>
    </template>
  </div>
</template>

<script>
import AppTag from "@/components/tag";
import AppPopover from "@/components/popovers/popover";

export default {
  name: 'AppTagsList',
  components: {
    AppPopover,
    AppTag
  },
  data() {
    return {
      visibleTags: [],
      allTagsPopoverShown: false
    };
  },
  emits: ['update:modelValue', 'tag-click'],

  props: {
    tags: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    expandable: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    },
    allTagsBtnClass: {
      type: String
    },
    allTagsBtnLabel: {
      type: String
    },
    selectedTagsFirst: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array,
      default: function() {
        return [];
      }
    },
  },

  computed: {
    hiddenTags() {
      if (!this.expandable) {
        return [];
      }

      const visibleTagIds = this.visibleTags.map(tag => tag.id);
      return this.orderedTags.filter(tag => !visibleTagIds.includes(tag.id));
    },
    selectedTags: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    orderedTags() {
      if (this.selectedTagsFirst) {
        const priorityTags = [];
        const otherTags = [];
        this.tags.forEach((tag) => {
          if (this.isTagSelected(tag)) {
            priorityTags.push(tag);
          } else {
            otherTags.push(tag);
          }
        });

        return [...priorityTags, ...otherTags];
      }

      return this.tags;
    }
  },

  methods: {
    updateVisibleTags(reset = false) {
      if (reset === true) {
          this.visibleTags = [...this.orderedTags];
      }

      if (this.expandable) {
        this.$nextTick(() => {
          const container = this.$refs.tagsList;
          if (container.scrollHeight > container.offsetHeight) {
            const tags = this.visibleTags;
            tags.splice(tags.length - 1, 1);
          }
        });
      }
    },

    onResize() {
      this.updateVisibleTags(true);
    },

    isTagSelected(tag) {
      return !!this.selectedTags.find(item => item.id === tag.id);
    },

    onClickTag(tag) {
      this.$emit('tag-click', tag);

      if (this.editable) {
        const index = this.selectedTags.findIndex((item) => item.id === tag.id);
        if (index !== -1) {
          this.selectedTags.splice(index, 1);
        } else {
          this.selectedTags.push(tag);
        }
        this.$forceUpdate();
      }
    },

    showAllTagsPopover() {
      this.allTagsPopoverShown = true;
    },

    hideAllTagsPopover() {
      this.allTagsPopoverShown = false;
    }
  },

  mounted() {
    this.updateVisibleTags(true);
    window.addEventListener('resize', this.onResize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  watch: {
    tags: {
      handler: function() {
        this.updateVisibleTags(true);
      },
      deep: true
    },
    hiddenTags: {
      handler: function(value) {
        if (!value.length) {
          this.hideAllTagsPopover();
        }
        this.updateVisibleTags();
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
