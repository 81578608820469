<template>
  <div v-if="fantasy" class="container-fluid vertical-gutter rounded-bg profile-fantasy">
    <h4 class="mb-2 d-flex title">
      {{ fantasyMatched ? 'Matched Fantasy' : 'I fantasize about…' }}
      <slot name="fantasy-button">
        <b-button v-if="showJoinFantasyButton"
            variant="primary"
            :disabled="!this.profile.online"
            @click.prevent="joinFantasy()"
        >
          <i class="icon icon-arrow-right"/>
        </b-button>
      </slot>
    </h4>
    <text-quote :text="fantasy.text" />
  </div>
</template>

<script>
import TextQuote from "@/components/text-quote";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProfileFantasy",

  components: {
    TextQuote
  },

  computed: {
    ...mapGetters('fantasy', ['getLikedFantasies']),

    fantasyMatched() {
      const likedFantasyIds = (this.getLikedFantasies || []).map(fantasy => fantasy.id);
      return likedFantasyIds.includes(this.fantasy.id);
    }
  },

  props: {
    fantasy: {
      type: Object,
      default: () => {}
    },

    profile: {
      type: Object,
      default: () => {}
    },

    showJoinFantasyButton: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    ...mapActions('profile', ['addProfile']),
    ...mapActions('fantasy', ['addPreselectedFantasy']),

    joinFantasy() {
      if (!this.fantasy) {
        return;
      }

      this.addProfile(this.profile);
      this.addPreselectedFantasy(this.fantasy);

      this.$router.push({
        name: 'JoinFantasy',
        params: {
          forceFantasyId: this.fantasy.id,
          forceProfileId: this.profile.id
        }
      });
    },
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
