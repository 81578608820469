import api from '@/api/api'

class ChatEventService {
  getChatEvents() {
    return api.axios.get(`chat-event/get-events`).then(response => {
      return response.data.events;
    });
  }
}

export default new ChatEventService();
