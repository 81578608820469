<template>
  <div class="headline" :class="{blackout, absolute}">
    <div class="background" :class="{blurred}" :style="`background-image: url(${backgroundImageUrl})`"></div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'AppHeadline',
  props: {
    backgroundImageUrl: {
      type: String
    },
    blurred: {
      type: Boolean,
      default: false
    },
    blackout: {
      type: Boolean,
      default: false
    },
    absolute: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
