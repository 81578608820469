<template>
  <b-form @submit="onSubmit" class="forgot-password-form wide">
    <app-overlay :show="isLoading">
      <b-form-group class="email">
        <input-icon-group position="left" class="animated-form-field" icon="mail">
          <b-form-input
              type="text"
              v-model="email"
              :state="getFieldState('email') "
              placeholder="Input your email address"
          />
          <b-form-invalid-feedback>{{ error || validationErrors['email'] }}</b-form-invalid-feedback>
          <label>Email</label>
        </input-icon-group>
      </b-form-group>

      <p class="text-left mt-3 text-small">We'll send you the instructions to reset your password.</p>

      <div class="text-center">
        <b-button type="submit" variant="primary" class="wide login">
          <i class="icon icon-send"></i>
          <span>Send email</span>
        </b-button>
      </div>
    </app-overlay>
  </b-form>
</template>

<script>
import AppOverlay from "@/components/overlay";
import InputIconGroup from "@/components/input-icon-group";
import PasswordRecoveryService from "@/api/services/passwordRecoveryService";

export default {
  name: "ForgotPasswordForm",

  components: {
    AppOverlay,
    InputIconGroup
  },

  data: function() {
    return {
      isSubmitted: false,
      isLoading: false,
      email: '',
      validationErrors: {},
      error: false
    }
  },

  methods: {
    getFieldState(field) {
      return !this.isSubmitted ? null : (!this.error && !this.validationErrors[field]);
    },

    async onSubmit() {
      this.error = false;
      this.isSubmitted = true;
      this.validationErrors = {};

      if (!this.email) {
        this.validationErrors.email = 'Field is required';
      }

      if (Object.keys(this.validationErrors).length) {
        return;
      }

      try {
        this.isLoading = true;
        const response = await PasswordRecoveryService.forgotPassword(this.email);
        this.$store.dispatch('modal/closeModal', 'forgot-password-modal');
        this.$store.dispatch('modal/openModal', {
          id: "notification-modal",
          message: response.message
        });
      } catch (e) {
        if (e.response.status === 400) {
          for (let key in e.response.data.errors) {
            this.validationErrors[key] = e.response.data.errors[key];
          }

          if (e.response.data.message) {
            this.error = e.response.data.message;
          }
        }
      }
      this.isLoading = false;
    }
  }
}
</script>
