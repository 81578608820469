import store from "../store/index";

export default function () {
  const hjId = process.env.VUE_APP_HOTJAR_ID;
  if (hjId) {
    const user = store.getters['user/getUser'];
    if (!user.id) {
      return;
    }

    const script = document.createElement('script');
    script.innerHTML = `(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${hjId},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      window.hj('identify', ${user.id}, {});
    `;
    document.head.appendChild(script);
  }
}
