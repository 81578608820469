import profileService from "@/api/services/profileService";
import relationService from "@/api/services/relationService";
import {RELATION_DECISION_DISLIKE, RELATION_DECISION_LIKE} from "@/utils/constants";

const ADD_UNTOUCHED_PROFILES = "ADD_UNTOUCHED_PROFILES";
const CLEAR_UNTOUCHED_PROFILES = "RESET_UNTOUCHED_PROFILES";
const REMOVE_UNTOUCHED_PROFILE = "REMOVE_UNTOUCHED_PROFILE";
const SET_UNTOUCHED_PROFILE_INDEX = "SET_UNTOUCHED_PROFILE_INDEX";
const ADD_PROFILE = "ADD_PROFILE";

export default {
  namespaced: true,

  state: {
    profiles: [],
    untouchedProfiles: [],
    untouchedProfileIndex: 0
  },

  getters: {
    getProfiles(state) {
      return state.profiles;
    },

    getUntouchedProfiles(state) {
      return state.untouchedProfiles;
    },

    getUntouchedProfileIndex(state) {
      return state.untouchedProfileIndex;
    },

    getProfileById(state) {
      return (id) => {
        return state.profiles.find(item => item.id === id);
      };
    }
  },

  mutations: {
    [ADD_UNTOUCHED_PROFILES](state, profiles) {
      profiles.forEach(profile => {
        const index = state.untouchedProfiles.findIndex(item => profile.id === item.id);
        if (index === -1) {
          state.untouchedProfiles.push(profile);
        } else {
          state.untouchedProfiles[index] = profile;
        }
      });

      state.untouchedProfiles = [...state.untouchedProfiles];
    },

    [REMOVE_UNTOUCHED_PROFILE](state, profile) {
      state.untouchedProfiles = state.untouchedProfiles.filter(item => item.id !== profile.id);
    },

    [SET_UNTOUCHED_PROFILE_INDEX](state, index) {
      state.untouchedProfileIndex = index || 0;
    },

    [ADD_PROFILE](state, profile) {
      state.profiles = [...state.profiles.filter(item => item.id !== profile.id), profile];
    },

    [CLEAR_UNTOUCHED_PROFILES](state) {
      state.untouchedProfiles = [];
    }
  },

  actions: {
    async getUntouchedProfiles({commit, getters}) {
      const excludeIds = getters.getUntouchedProfiles.map(profile => profile.id);
      const profiles = await profileService.getUntouchedProfiles(excludeIds);
      commit(ADD_UNTOUCHED_PROFILES, profiles);
    },

    async updateUntouchedProfiles({commit, getters}) {
      let profileIds = [];
      const profilesLimit = 30;

      for (const profile of getters.getUntouchedProfiles) {
        profileIds.push(profile.id);

        if ((profileIds.length % profilesLimit) === 0) {
          const profiles = await profileService.getUntouchedProfiles([], profileIds);
          commit(ADD_UNTOUCHED_PROFILES, profiles);
          profileIds = [];
        }
      }

      if (profileIds.length) {
        const profiles = await profileService.getUntouchedProfiles([], profileIds);
        commit(ADD_UNTOUCHED_PROFILES, profiles);
      }
    },

    clearUntouchedProfiles({commit, dispatch}) {
      commit(CLEAR_UNTOUCHED_PROFILES);
      dispatch('setUntouchedProfileIndex');
    },

    setUntouchedProfileIndex({commit}, index) {
      commit(SET_UNTOUCHED_PROFILE_INDEX, index);
    },

    async getProfile({commit}, profileId) {
      const profile = await profileService.getProfile(profileId);
      commit(ADD_PROFILE, profile);
    },

    async addProfile({commit}, profile) {
      commit(ADD_PROFILE, profile);
    },

    likeProfile({commit}, profile) {
      commit(REMOVE_UNTOUCHED_PROFILE, profile);
      return relationService.establishRelation(profile.id, RELATION_DECISION_LIKE);
    },

    dislikeProfile({commit}, profile) {
      commit(REMOVE_UNTOUCHED_PROFILE, profile);
      return relationService.establishRelation(profile.id, RELATION_DECISION_DISLIKE);
    },

    async updateProfiles({commit, getters}) {
      let profileIds = [];
      const profilesLimit = 100;

      for (const profile of getters.getProfiles) {
        profileIds.push(profile.id);

        if ((profileIds.length % profilesLimit) === 0) {
          const profiles = profileService.getProfiles(profileIds);
          profiles.forEach((updatedProfile) => {
            commit(ADD_PROFILE, updatedProfile);
          })

          profileIds = [];
        }
      }

      if (profileIds.length) {
        const profiles = await profileService.getProfiles(profileIds);
        profiles.forEach((updatedProfile) => {
          commit(ADD_PROFILE, updatedProfile);
        })
      }
    }
  }
}
