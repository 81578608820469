import mixpanel from "mixpanel-browser";
import store from '@/store';
import trackingService from "@/api/services/trackingService";

mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN);

function track(event, parameters) {
  const user = store.getters['user/getUser'];
  const trackParameters = user.id ? {...user.tracking_parameters, ...parameters} : parameters;

  if (!mixpanel.__loaded
    || navigator.doNotTrack
    || window.doNotTrack
    || navigator.msDoNotTrack
  ) {
    trackingService.sendTrack(event, trackParameters);
  } else {
    if (user.id) {
      mixpanel.identify(user.id);
    }
    mixpanel.track(event, trackParameters);
  }
}

export default {
  trackFinishedUserGuide() {
    track('User finished guide');
  },

  trackProfileView(profile) {
    track('User viewed profile', {'profile name': profile.username});
  },

  trackProfileLike(profile) {
    track('User like profile', {'profile name': profile.username});
  },

  trackProfileDislike(profile) {
    track('User disliked profile', {'profile name': profile.username});
  },

  trackFantasyLike(fantasy) {
    track('User like fantasy', {'fantasy': fantasy.text});
  },

  trackSawJoinFantasy(profilePredefined) {
    track("User saw 'Join fantasy'", {'profile predefined': profilePredefined})
  },

  trackClickedStartFantasy(profilePredefined) {
    track("User clicked 'Start fantasy'", {
      'profile predefined': profilePredefined,
      'page': window.location.pathname.split('/')[1] || ''
    });
  },

  trackSawSubscriptionPaywall() {
    track("User saw 'Subscription' paywall");
  },

  trackChoseSubscriptionType(subscriptionType) {
    track("User chose subscription type", {'subscription type': subscriptionType.name});
  },

  trackSawKarmaPaywall() {
    track("User saw 'Karma' paywall");
  },

  trackChoseKarmaPackage(karmaPackage) {
    track("User chose karma package", {'karma package': karmaPackage.name});
  },

  trackPaymentError(error) {
    track("User failed payment", {'error': error});
  },

  trackFantasyMatchedProfileNotFound() {
    track("Fantasy matched profile not found");
  },

  trackUntouchedProfilesNotFound() {
    track("User saw 'Nothing found' on the Home page");
  },

  trackSawAddNewCardScreen() {
    track("User saw 'Add new card' screen");
  },

  trackClickedAddNewCard() {
    track("User clicked 'Add new card'");
  },

  trackClickedIcebreaker() {
    track("User clicked 'Icebreaker'");
  },

  trackChoseIcebreaker() {
    track("User chose Icebreaker");
  },

  trackClickedAddIcebreaker() {
    track("User clicked 'Add Icebreaker'");
  },

  trackClickedAnswerQuestion() {
    track("User clicked 'Answer question'");
  },

  trackClickedFantasy() {
    track("User clicked 'Fantasy'");
  },

  trackChoseFantasy() {
    track("User chose Fantasy");
  },

  trackClickedSearchAgain() {
    track("User clicked 'Search again' button");
  },
};
