import chatEventService from "@/api/services/chatEventService";

const SET_CHAT_EVENTS = "SET_CHAT_EVENTS";
const CLEAR_CHAT_EVENTS = "CLEAR_CHAT_EVENTS";

export default {
  namespaced: true,

  state: {
    chatEvents: [],
    syncIntervalId: null
  },

  getters: {
    getChatEventByConversationId(state) {
      return (id) => {
        return state.chatEvents.find(event => event.conversation_id === id);
      };
    }
  },

  mutations: {
    [SET_CHAT_EVENTS](state, chatEvents) {
      state.chatEvents = chatEvents;
    },
    [CLEAR_CHAT_EVENTS](state) {
      state.chatEvents = [];
    }
  },

  actions: {
    async getChatEvents({commit}) {
      const chatEvents = await chatEventService.getChatEvents();
      commit(SET_CHAT_EVENTS, chatEvents);
    }
  }
}
