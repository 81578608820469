import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

export default process.env.VUE_APP_BUGSNAG_API_KEY ? Bugsnag.start({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  appVersion: process.env.npm_package_version,
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: process.env.NODE_ENV,
  onError: function (event) {
    const errorMessage = event.originalError?.message
    const ignoredMessages = [
      'Network Error',
      'Failed to fetch',
      'Load failed',
      'timeout exceeded'
    ];

    if (ignoredMessages.includes(errorMessage)) {
      return false;
    }
  }
}) : null;
