<template>
  <app-modal
      id="confirmation-modal"
      :hide-footer="false"
      footer-class="fill-buttons"
      dialog-class="modal-sm"
  >
    <template #title="{modal}">
      <h4 v-if="modal && modal.title">{{ modal.title }}</h4>
    </template>

    <template v-slot="{modal}" >
      <div class="text-center">
        <p class="message">{{ modal.message }}</p>
      </div>
    </template>

    <template #footer>
      <button class="btn btn-danger" @click="onClick('reject')">
        <i class="icon icon-close"></i>
        <span>No</span>
      </button>
      <button class="btn btn-success" @click="onClick('resolve')">
        <i class="icon icon-check-circle"></i>
        <span>Yes</span>
      </button>
    </template>
  </app-modal>
</template>

<script>
import AppModal from "@/components/modals/modal";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ModalConfirmation",
  components: {
    AppModal
  },
  computed: {
    ...mapGetters('modal', ['getModal']),

    modal() {
      return this.getModal('confirmation-modal');
    }
  },

  methods: {
    ...mapActions('modal', ['closeModal']),

    onClick(type) {
      if (this.modal[type]) {
        this.modal[type]();
      }
      this.closeModal('confirmation-modal');
    }
  }
}
</script>
