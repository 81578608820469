<template>
  <app-modal
      id="signup-modal"
      size="sm"
      title-class="text-center"
      body-class="compact"
      centered
      dialog-class="modal-sm"
      content-class="full-screen-xs"
      title="Create account"
      title-tag="h4"
  >
    <signup-form/>
    <data-protection-hint button-name="Create Account"/>
  </app-modal>
</template>

<script>
import AppModal from "@/components/modals/modal";
import SignupForm from "@/components/form-signup";
import DataProtectionHint from "@/components/data-protection-hint";

export default {
  name: "ModalSignup",
  components: {
    DataProtectionHint,
    SignupForm,
    AppModal
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
