<template>
  <app-modal
      id="payment-modal"
      @shown="onShown"
      :hide-footer="false"
      hide-header-close
      content-class="full-screen-xs"
      dialog-class="modal-sm"
      footer-class="fill-buttons light"
      no-close-on-backdrop
      no-close-on-esc
  >
    <template #title>
      <b-button variant="back" class="bg-clear" @click="back">
        <i class="icon icon-arrow-left"></i>
      </b-button>
    </template>
    <img class="d-block mx-auto" alt="Logo" src="@/assets/images/logo.svg"/>

    <div class="header" v-if="order.subscriptionType">
      <h3 class="mt-2 text-center">
        <template v-if="order.subscriptionType.trial || !order.subscriptionType.recurring">
          {{ order.subscriptionType.initial_price_label }} for {{ order.subscriptionType.initial_duration_label }}
        </template>
        <template v-else>
          {{ order.subscriptionType.initial_price_label }} per {{ order.subscriptionType.rebill_period_label }}
        </template>
      </h3>

      <div class="subtitle regular-payment text-center">
        <template v-if="order.subscriptionType.trial">
          Then {{ order.subscriptionType.full_price_label }}/{{ order.subscriptionType.rebill_period_label }}, cancel any time
        </template>
        <template v-else>
          {{ order.subscriptionType.name }} subscription, cancel any time
        </template>
      </div>
    </div>

    <app-overlay :show="isLoading">
      <div class="payment-methods">
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
        <div class="payment-widget" ref="paymentWidget">
          <div class="payment-creditcards" v-if="creditCards.length && !showCreditCardForm">
            <div class="cards">
              <credit-card
                  v-for="card in creditCards"
                  :key="card.id"
                  :card="card"
                  v-model="selectedCard"
              />
              <b-button variant="secondary" class="wide m-auto d-block" @click="addNewCard">
                <i class="icon icon-credit-card"></i>
                <span>Add new card</span>
              </b-button>
            </div>
          </div>

          <div class="card-form" v-else-if="showCreditCardForm">
            <div class="commdoo-payment-widget"></div>
            <b-button variant="primary" class="wide m-auto d-block mt-3" @click="registerCard">
              <i class="icon icon-credit-card"></i>
              <span>Add new card</span>
            </b-button>
          </div>
        </div>
      </div>
    </app-overlay>

    <template #footer>
      <b-button variant="primary" :disabled="!selectedCard" @click.prevent="payOrder">
        <span>Buy</span>
        <i class="icon icon-arrow-right"></i>
      </b-button>
    </template>
  </app-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import mixpanelUtils from "@/utils/mixpanel";
import Cleave from "cleave.js";
import creditCardService from "@/api/services/creditCardService";
import AppModal from "@/components/modals/modal";
import AppOverlay from "@/components/overlay";
import CreditCard from "@/components/credit-card";
import subscriptionService from "@/api/services/subscriptionService";
import karmaChargeService from "@/api/services/karmaChargeService";

export default {
  name: 'ModalPayment',

  components: {
    AppModal,
    AppOverlay,
    CreditCard
  },

  data() {
    return {
      isLoading: false,
      selectedCard: null,
      errorMessage: '',
      showCreditCardForm: false,
      cardIcon: '',
    }
  },

  computed: {
    ...mapGetters('payment', {order: 'getOrder'}),
    ...mapGetters('creditCard', {creditCards: 'getCreditCards'}),
    ...mapGetters('modal', ['getModal']),

    modal() {
      return this.getModal('payment-modal');
    }
  },

  methods: {
    ...mapActions('creditCard', ['getCreditCards']),
    ...mapActions('payment', ['setRouteBeforePayment']),
    ...mapActions('subscriptionType', ['getSubscriptionTypes']),
    ...mapActions('karmaPackage', ['getKarmaPackages']),
    ...mapActions('user', ['getUser']),
    ...mapActions('modal', ['openModal', 'closeModal']),

    back() {
      if (this.creditCards.length && this.showCreditCardForm) {
        this.showCreditCardForm = false;
      } else {
        const modalId = this.order.subscriptionType ? 'subscription-paywall-modal' : 'karma-paywall-modal';
        this.closeModal('payment-modal');
        this.openModal({id: modalId});
      }
    },

    async onShown() {
      this.errorMessage = this.modal && this.modal.errorMessage ? this.modal.errorMessage : null;

      if (!this.creditCards.length) {
        this.isLoading = true;
        await this.getCreditCards();
        this.isLoading = false;
      }

      this.selectedCard = this.creditCards.find((card) => card.primary);
    },

    async addNewCard() {
      mixpanelUtils.trackSawAddNewCardScreen();

      this.isLoading = true;
      const oldScript = document.getElementById('#commdoo-widget-script');
      if (oldScript) {
        oldScript.remove();
      }

      try {
        window.$ = require("jquery");
        var widgetUrl = await creditCardService.getWidgetUrl();
      } catch (e) {
        this.isLoading = false;
        return;
      }

      const widgetScript = document.createElement('script');
      widgetScript.addEventListener("load", () => {
        this.showCreditCardForm = true;
        this.isLoading = false;
      });

      widgetScript.addEventListener("error", () => {
        this.isLoading = false;
      });

      widgetScript.setAttribute('src', widgetUrl);
      if (this.$refs.paymentWidget) {
        this.$refs.paymentWidget.parentNode.insertBefore(widgetScript, this.$refs.paymentWidget);
      }
    },

    registerCard() {
      mixpanelUtils.trackClickedAddNewCard();

      this.isLoading = true;
      this.setRouteBeforePayment(this.$route.name);

      window.pay__commdoo();
    },

    async payOrder() {
      this.isLoading = true;
      let result = {};
      try {
        if (this.order.subscriptionType) {
          result = await subscriptionService.buySubscription(this.order.subscriptionType.id, this.selectedCard.id);
          this.getSubscriptionTypes();
        } else if (this.order.karmaPackage) {
          result = await karmaChargeService.buyKarma(this.order.karmaPackage.id, this.selectedCard.id);
          this.getKarmaPackages();
        }

        this.getUser();
        this.closeModal('payment-modal');
        this.openModal({id: 'payment-success-modal', karmaAmount: result.karma_amount});
      } catch (e) {
        if (e.response) {
          if (e.response.status === 400) {
            this.errorMessage = e.response.data.errors
                ? Object.values(e.response.data.errors).join("\n")
                : e.response.data.message;
          } else {
            this.errorMessage = 'Something went wrong, try again later...';
          }
        }
      }
      this.isLoading = false;
    }
  },

  watch: {
    creditCards: {
      handler(newVal) {
        if (!newVal.length) {
          this.addNewCard();
        }
      },
      deep: true
    },

    errorMessage(newVal) {
      if (newVal) {
        mixpanelUtils.trackPaymentError(newVal);
      }
    }
  },

  mounted() {
    window.payment_error = (error) => {
      this.errorMessage = error;
      this.isLoading = false;
    }

    window.payment_validation_error = (input) => {
      if (!this.$refs.paymentWidget) {
        return;
      }
      this.$refs.paymentWidget.querySelectorAll('.is-invalid').forEach(el => el.classList.remove('is-invalid'));
      this.$refs.paymentWidget.querySelectorAll('.' + input).forEach(el => el.classList.add('is-invalid'));
      this.isLoading = false;
    }

    window.payment_loaded = () => {
      if (!this.$refs.paymentWidget) {
        return;
      }
      this.$refs.paymentWidget.querySelector('#lll-input-commdoo-number').setAttribute('autocomplete', 'cc-number');
      this.$refs.paymentWidget.querySelector('#lll-input-commdoo-expirationdate').setAttribute('autocomplete', 'cc-exp');
      this.$refs.paymentWidget.querySelector('#lll-input-commdoo-owner').setAttribute('autocomplete', 'cc-name');

      new Cleave('.payment-widget .number', {
        creditCard: true,
        onCreditCardTypeChanged: (type) => {
          this.$refs.paymentWidget.querySelectorAll('.card-icons div').forEach(el => el.classList.remove('d-none'));
          if (type !== 'unknown') {
            this.$refs.paymentWidget
                .querySelectorAll('.card-icons div:not(.icon-' + type + ')')
                .forEach(el => el.classList.add('d-none'));
          }
        }
      });

      new Cleave('.payment-widget .expirationmonth', {
        date: true,
        datePattern: ['m', 'y']
      });

      this.$refs.paymentWidget.querySelector('input.number').focus();
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
