<template>
  <div class="chat-reward-animation">
    <div v-if="step === 1" class="step step-badge">
      <div>
        <explode-bubbles v-if="showExplode" :bubbleCount="50"/>
        <div class="badge"/>
        <h3 class="description" v-html="descriptionText"></h3>
      </div>
    </div>

    <div v-if="step === 2" class="step step-karma">
      <div>
        <karma-cost-badge :cost="karma" :class="{scatter: animateKarmaScores}"/>

        <h3 class="description">Karma received</h3>
      </div>
    </div>
  </div>
</template>
<script>
import ExplodeBubbles from "@/components/explode-bubbles";
import KarmaCostBadge from "@/components/karma-cost-badge";

export default {
  name: 'ChatRewardAnimation',
  components: {
    ExplodeBubbles,
    KarmaCostBadge
  },
  data() {
    return {
      step: 1,
      animateKarmaScores: false,
      showExplode: false
    }
  },
  emits: ['animation-finished'],
  props: {
    karma: {
      type: Number,
      default: 0
    },
    descriptionText: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.showExplode = true;
    setTimeout(() => {
      this.showExplode = false;
      this.step = 2;
    }, 2000);

    setTimeout(() => {
      this.animateKarmaScores = true;
    }, 3000);

    setTimeout(() => {
      this.$emit('animation-finished');
    }, 5000);
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
