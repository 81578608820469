<template>
  <div class="carousel">
    <div
        class="prev"
        :class="[prevArrowClass, {disabled: prevArrowDisabled}]"
        @click="scrollList(0 - step)"
    />
    <div ref="list" class="list" @scroll="this.scrollLeft = Math.round($event.target.scrollLeft)">
      <slot/>
    </div>
    <div
        class="next"
        :class="[nextArrowClass, {disabled: nextArrowDisabled}]"
        @click="scrollList(step)"
    />
  </div>
</template>

<script>
export default {
  name: 'AppCarousel',
  props: {
    prevArrowClass: {
      type: String,
      default: 'icon icon-chevron-left'
    },
    nextArrowClass: {
      type: String,
      default: 'icon icon-chevron-right'
    },
    step: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      scrollLeft: 0,
      scrollLeftMax: 0
    }
  },

  computed: {
    prevArrowDisabled() {
      return this.scrollLeft === 0;
    },
    nextArrowDisabled() {
      return this.scrollLeft === this.scrollLeftMax;
    },
  },

  methods: {
    scrollList(steps) {
      const list = this.$refs.list;
      const elementWidth = list.firstElementChild.offsetWidth;
      list.scrollTo({
        left: Math.min(this.scrollLeft + steps * elementWidth, this.scrollLeftMax),
        behavior: "smooth"
      });
    },

    updateScrollLeftMax() {
      const list = this.$refs.list;
      this.scrollLeftMax = list.scrollWidth - list.clientWidth;
    },
  },

  mounted() {
    this.updateScrollLeftMax();
    window.addEventListener('resize', this.updateScrollLeftMax);
  },

  updated() {
    this.updateScrollLeftMax();
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.updateScrollLeftMax);
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>

