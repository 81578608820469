import api from '@/api/api';

class AuthService {
  signup(email, password, influencerId, fantasyId) {
    return api.axios.post('auth/signup', {
      email,
      password,
      influencer_id: influencerId,
      fantasy_id: fantasyId
    });
  }

  login(username, password) {
    return api.axios.post('auth/login', {username, password});
  }

  loginGoogle(token, influencerId, fantasyId) {
    return api.axios.post('auth/login-google', {token, influencer_id: influencerId, fantasy_id: fantasyId});
  }

  loginFacebook(token, influencerId, fantasyId) {
    return api.axios.post('auth/login-facebook', {token, influencer_id: influencerId, fantasy_id: fantasyId});
  }

  loginLink(url) {
    if (url.substr(0, 4) === '/api') {
      url = url.substr(4);
    }
    return api.axios.post(url);
  }

  logout() {
    api.clearAuth();
  }
}

export default new AuthService();
