<template>
  <div class="uploaded-image-preview">
    <b-container fluid class="vertical-gutter rounded-bg">
      <div class="image">
        <img :src="imageUrl" alt="Uploaded image"/>
      </div>
      <div class="image-settings">
        <slot/>
      </div>
    </b-container>

    <div class="actions">
      <slot name="actions"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadedImagePreview',
  props: {
    imageFile: {
      type: File
    }
  },
  computed: {
    imageUrl() {
      return URL.createObjectURL(this.imageFile);
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>

