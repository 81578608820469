<template>
  <app-modal
      id="login-modal"
      size="sm"
      centered
      body-class="compact"
      content-class="full-screen-xs"
      title="Login to the account"
      title-tag="h4"
  >
    <login-form>
      <div class="text-center or-delimiter">or</div>
      <div class="text-center">
        <b-button v-b-modal="'signup-modal'" variant="secondary" class="wide">
          <i class="icon icon-user"></i>
          <span>Create new account</span>
        </b-button>
      </div>
    </login-form>

    <data-protection-hint button-name="Create Account" class="mt-4"/>
  </app-modal>
</template>

<script>
import AppModal from "@/components/modals/modal";
import LoginForm from "@/components/form-login";
import DataProtectionHint from "@/components/data-protection-hint";

export default {
  name: "ModalLogin",
  components: {
    AppModal,
    DataProtectionHint,
    LoginForm
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>

