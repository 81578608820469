if (!('localStorage' in window) || window['localStorage'] === null) {
  window.Storage = function () {
    this.data = {};
  };
  window.localStorage = new Storage();
  window.sessionStorage = new Storage();
}

Storage.prototype.data = {};
Storage.prototype._setItem = Storage.prototype.setItem;
Storage.prototype.setItem = function (key, value) {
  try {
    this._setItem(key, value);
  } catch (e) {
    this.data[key] = value;
  }
};

Storage.prototype._getItem = Storage.prototype.getItem;
Storage.prototype.getItem = function (key) {
  try {
    return this._getItem(key);
  } catch (e) {
    return this.data[key] || null;
  }
};

Storage.prototype._removeItem = Storage.prototype.removeItem;
Storage.prototype.removeItem = function (key) {
  try {
    this._removeItem(key);
  } catch (e) {
    delete this.data[key];
  }
};

Storage.prototype._clear = Storage.prototype.clear;
Storage.prototype.clear = function () {
  try {
    this._clear();
  } catch (e) {
    this.data = {};
  }
};
