<template>
  <age-verification>
    <template v-slot:default="{startVerification}">
      <div class="age-verification-warning">
        <i class="icon icon-alert-triangle"/>
        <div class="description">
          You can post only non-Nude photos, which will go through the moderation process.
          Please, <b-link @click.prevent="startVerification">Verify your Age</b-link> to get access to the instant Nude photos posting.
        </div>
      </div>
    </template>
  </age-verification>
</template>

<script>
import AgeVerification from "@/components/age-verification";

export default {
  name: 'AgeVerificationWarning',
  components: {
    AgeVerification,
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
