<template>
  <b-form-group class="notification-settings justify-content-center text-left">
    <b-form-checkbox
        v-for="(label, index) in notificationTypes"
        :key="index"
        v-model="selectedNotificationTypes"
        :value="index"
    >
      {{ label }}
    </b-form-checkbox>

    <b-form-invalid-feedback :force-show="!!error">
      {{ error }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  name: 'NotificationSettingFormGroup',

  emits: ['update:modelValue'],

  props: {
    error: {
      type: String,
      default: ''
    },
    notificationTypes: {
      type: Object,
      default: function() {
        return {};
      }
    },
    modelValue: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  computed: {
    selectedNotificationTypes: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  }
}
</script>