BerbixVerify = {
  configure: function (config) {
    var defaults = {
      onError: function () {},
      onExit: function () {},
      onDisplay: function () {},
      onStateChange: function () {},
      onComplete: null,
      version: "v0",
      baseUrl: null,
      overrideUrl: null,
      clientId: null,
      templateKey: null,
      role: null,
      root: null,
      email: null, // deprecated
      phone: null, // deprecated
      staging: false,
      environment: null,
      continuation: null,
      clientToken: null,
      modal: false,
      showCloseModalButton: false,
    };

    var SDK_VERSION = "1.0.7";

    return {
      open: function (openConfig) {
        if (openConfig == null) {
          openConfig = {};
        }

        var cfg = {};
        for (var key in defaults) {
          cfg[key] = openConfig[key] || config[key] || defaults[key];
        }

        function constructUrl() {
          if (cfg.overrideUrl != null) {
            return cfg.overrideUrl;
          }

          var template = cfg.templateKey || cfg.role;
          if (cfg.onComplete == null) {
            throw "'onComplete' handler must be defined when invoking Berbix Verify";
          }
          if (cfg.root == null && !cfg.modal) {
            throw "'root' id must be defined when invoking Berbix Verify";
          }

          if (cfg.baseUrl == null) {
            if (cfg.environment != null) {
              if (cfg.environment === "sandbox") {
                cfg.baseUrl = "https://verify.sandbox.berbix.com";
              } else if (cfg.environment === "staging") {
                cfg.baseUrl = "https://verify.staging.berbix.com";
              } else {
                cfg.baseUrl = "https://verify.berbix.com";
              }
            } else {
              cfg.baseUrl = cfg.staging
                ? "https://verify.staging.berbix.com"
                : "https://verify.berbix.com";
            }
          }

          if (cfg.continuation != null) {
            cfg.clientToken = cfg.continuation;
          }

          var options = [];
          if (cfg.clientId) {
            options.push("client_id=" + cfg.clientId);
          }
          if (template) {
            options.push("template=" + template);
          }
          if (cfg.email) {
            console.debug("the email parameter is deprecated");
          }
          if (cfg.phone) {
            console.debug("the phone parameter is deprecated");
          }
          if (cfg.clientToken) {
            options.push("client_token=" + cfg.clientToken);
          }
          if (cfg.modal) {
            if (cfg.showCloseModalButton) {
              options.push("modal=2");
            } else {
              options.push("modal=1");
            }
          }
          var height = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight || 0
          );
          options.push("max_height=" + height);
          options.push("sdk=BerbixJS-" + SDK_VERSION);
          return (
            cfg.baseUrl + "/" + cfg.version + "/verify?" + options.join("&")
          );
        }

        var frameUrl = constructUrl();

        var frame = document.createElement("iframe");
        frame.className = "--berbix-iframe";
        frame.src = frameUrl;
        frame.style.backgroundColor = "transparent";
        frame.style.border = "0 none transparent";
        frame.style.padding = "0";
        frame.style.margin = "0";
        frame.style.overflow = "visible";
        frame.style.display = "block";
        frame.style.width = "100%";
        frame.style.height = "0";
        frame.style.overflow = "hidden";
        frame.allow = "camera";
        frame.scrolling = "no";
        frame.referrerPolicy = "no-referrer-when-downgrade";

        var cleanup = null;

        var changeMarginTop = function (px) {};

        if (!cfg.modal) {
          var elem = document.getElementById(cfg.root);

          var children = elem.children;
          for (var i = 0, child; (child = children[i]); i++) {
            if (child.className == "--berbix-iframe") {
              elem.removeChild(child);
            }
          }

          elem.appendChild(frame);

          cleanup = function () {
            elem.removeChild(frame);
          };
        } else {
          var body = document.body;

          var children = body.children;
          for (var i = 0, child; (child = children[i]); i++) {
            if (child.className == "--berbix-overlay") {
              elem.removeChild(child);
            }
          }

          var overlay = document.createElement("div");
          overlay.className = "--berbix-overlay";
          overlay.style.width = "100%";
          overlay.style.height = "100%";
          overlay.style.position = "fixed";
          overlay.style.top = "0";
          overlay.style.left = "0";
          overlay.style.right = "0";
          overlay.style.bottom = "0";
          overlay.style.backgroundColor = "rgba(0, 0, 0, 0.6)";
          overlay.style.zIndex = 1000;

          var container = document.createElement("div");
          container.style.margin = "0 auto";
          container.style.width = "100%";
          container.style.maxWidth = "500px";
          container.style.maxHeight = "100%";
          container.style.overflow = "auto";

          changeMarginTop = function (px) {
            container.style.marginTop = px + "px";
          };

          overlay.appendChild(container);
          container.appendChild(frame);
          body.appendChild(overlay);

          cleanup = function () {
            body.removeChild(overlay);
          };
        }

        function handler(e) {
          if (e.origin !== cfg.baseUrl) {
            return;
          }

          var data = JSON.parse(e.data);
          if (data.type === "VERIFICATION_COMPLETE") {
            try {
              if (data.payload.success) {
                cfg.onComplete({ value: data.payload.code });
              } else {
                cfg.onError(data);
              }
            } catch (e) {
              // Continue clean-up even if callback throws
            }
            cleanup && cleanup();
            window.removeEventListener("message", handler);
          } else if (data.type === "DISPLAY_IFRAME") {
            cfg.onDisplay();
            frame.style.display = "block";
            frame.style.height = data.payload.height + "px";
            if (data.payload.margin != null && data.payload.margin != 0) {
              changeMarginTop(data.payload.margin);
            }
          } else if (data.type === "RESIZE_IFRAME") {
            frame.style.height = data.payload.height + "px";
          } else if (data.type === "RELOAD_IFRAME") {
            frame.src = frameUrl;
          } else if (data.type === "STATE_CHANGE") {
            cfg.onStateChange(data.payload);
          } else if (data.type === "EXIT_MODAL") {
            cfg.onExit();
            cleanup();
            window.removeEventListener("message", handler);
          } else if (data.type === "ERROR_RENDERED") {
            cfg.onError(data.payload);
            frame.style.height = "200px";
          }
        }

        window.addEventListener("message", handler);
      },
    };
  },
};
