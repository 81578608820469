<template>
  <app-notifier :notification-to-component-mapping="mapping"/>
</template>
<script>
import AppNotifier from "@/components/notifier";

export default {
  name: 'AppModalNotifier',
  components: {
    AppNotifier,
  },

  data() {
    return {
      mapping: {
        'chat_reward_received_notification': 'modals/modal-chat-reward-received-notification',
      }
    }
  }
}
</script>
