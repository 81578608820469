const UPDATE = 'UPDATE';
const SET_LOADING = 'SET_LOADING';

export default {
  namespaced: true,

  state: {
    showHeader: false,
    showFooter: false,
    showNavbar: false,
    showFantasyHints: false,
    globalLoading: false,
    pageClass: '',
    showBackButton: false,
  },

  getters: {
    showHeader(state) {
      return state.showHeader;
    },
    showFooter(state) {
      return state.showFooter;
    },
    showNavbar(state) {
      return state.showNavbar;
    },
    showFantasyHints(state) {
      return state.showFantasyHints;
    },
    getPageClass(state) {
      return state.pageClass;
    },
    getGlobalLoading(state) {
      return state.globalLoading;
    },
    showBackButton(state) {
      return state.showBackButton;
    },
  },

  mutations: {
    [UPDATE](state, data) {
      Object.keys(data).forEach(key => {
        state[key] = data[key];
      });
    },

    [SET_LOADING](state, value) {
      state.globalLoading = value;
    }
  },

  actions: {
    update({commit}, payload) {
      commit(UPDATE, payload);
    },

    setLoading({commit}, value) {
      commit(SET_LOADING, value);
    }
  }
}
