const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';

export default {
  namespaced: true,

  state: {
    openedModals: []
  },

  getters: {
    getOpenedModals(state) {
      return state.openedModals;
    },
    getModal(state) {
      return (id) => state.openedModals.find(modal => modal.id === id);
    }
  },

  mutations: {
    [OPEN_MODAL](state, modal) {
      const index = state.openedModals.findIndex(item => item.id === modal.id);
      if (index === -1) {
        state.openedModals.push(modal);
      }
    },

    [CLOSE_MODAL](state, id) {
      state.openedModals = state.openedModals.filter(modal => modal.id !== id);
    },

    [CLOSE_ALL_MODALS](state) {
      state.openedModals = [];
    }
  },

  actions: {
    openModal({commit}, modal) {
      commit(OPEN_MODAL, modal);
      if (modal.onOpened) {
        modal.onOpened();
      }
    },

    closeModal({commit, getters}, id) {
      const modal = getters.getModal(id);
      if (modal) {
        commit(CLOSE_MODAL, id);
        if (modal.onClosed) {
          modal.onClosed();
        }
      }
    },

    closeAllModals({commit}) {
      commit(CLOSE_ALL_MODALS);
    }
  }
}
