<template>
  <div class="karma-scores karma-cost" :class="[
      cost > 0 ? 'karma-cost-positive' : 'karma-cost-negative'
    ]">
    <i class="icon icon-diamond-filled"/>
    <span class="balance">{{ (cost > 0 ? '+' : '') + cost }}</span>
  </div>
</template>

<script>
export default {
  name: 'KarmaCostBadge',
  props: {
    cost: {
      type: [Number, String],
      required: true
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>

