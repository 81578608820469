<template>
  <div class="avatar" :class="avatarClass">
    <img v-if="source" :src="source" alt="Avatar">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "AppAvatar",
  props: {
    source: {type: String},
    size: {
      type: String,
      default: "",
      validator: function(value) {
        return ['', 'extra-small', 'small', 'medium', 'large', 'extra-large'].includes(value);
      }
    },
    square: {type: Boolean, default: false},
    online: {type: Boolean, default: false}
  },
  computed: {
    avatarClass() {
      return [this.size, {square: this.square, online: this.online}];
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
