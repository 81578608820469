<template>
  <app-modal
      id="user-notification-unsubscribe-modal"
      title-class="text-center"
      centered
      content-class="full-screen-xs"
      header-class="compact"
      dialog-class="modal-sm"
      :hide-footer="false"
      footer-class="light"
      @show="onShow"
      @hide="onHide"
  >
    <app-overlay :show="isLoading">
      <div class="scatter color-secondary circle-icon badge secondary m-auto mb-3">
        <i class="icon icon-mail"></i>
      </div>
      <h4 class="text-center">E-mail logout</h4>
      <div class="text-bold mt-2 mb-3 text-center">{{ user.email }}</div>

      <b-form>
        <notification-setting-form-group
            v-model="selectedNotificationTypes"
            :error="this.validationErrors['notification_types']"
            :notificationTypes="notificationTypes"
        />

        <b-form-group class="notification-settings justify-content-center text-left">
          <b-form-checkbox v-model="unsubscribedFromMails" >
            I do not want to receive emails from PIF anymore.
          </b-form-checkbox>

          <b-form-invalid-feedback :force-show="!!validationErrors['unsubscribed_from_mails']">
            {{ validationErrors['unsubscribed_from_mails'] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </app-overlay>

    <template #footer>
      <b-button variant="secondary" class="wide" @click="onSubmit" :disabled="isLoading">
        <span>Next</span>
        <i class="icon icon-arrow-right"/>
      </b-button>
    </template>
  </app-modal>
</template>

<script>
import AppModal from "@/components/modals/modal";
import {mapActions, mapGetters} from "vuex";
import {NOTIFICATION_TYPES} from "@/utils/constants";
import NotificationSettingFormGroup from "@/components/form-group-notification-setting";
import AppOverlay from "@/components/overlay";

export default {
  name: "ModalUserNotificationUnsubscribe",

  components: {
    AppModal,
    NotificationSettingFormGroup,
    AppOverlay
  },

  data() {
    const user = this.$store.getters['user/getUser'];

    let selectedNotificationTypes = [];
    let notificationTypes = {}
    NOTIFICATION_TYPES.forEach(function(item){
      notificationTypes[item.value] = item.unsubscribe_label;

      if (!user.active_notification_types.includes(item.value)) {
        selectedNotificationTypes.push(item.value);
      }
    });

    return {
      isLoading: false,
      unsubscribedFromMails: user.unsubscribed_from_emails,
      validationErrors: {},
      notificationTypes: notificationTypes,
      selectedNotificationTypes: selectedNotificationTypes,
    }
  },
  methods: {
    ...mapActions('modal', ['openModal', 'closeModal']),

    onShow() {
      if (this.modal) {
        this.selectedNotificationTypes.push(this.modal.notification_type);
      }
    },

    onHide() {
      this.$router.replace(this.$route.path);
    },

    onSubmit() {
      if (this.unsubscribedFromMails) {
        this.openModal({
          id: 'confirmation-modal',
          title: 'Are you sure you do not want to receive emails from PIF anymore?',
          message: 'You will also no longer be informed when someone writes you a message.',
          resolve: () => {
            this.send()
          }
        });
      } else {
        this.send()
      }
    },

    async send() {
      this.validationErrors = {};
      this.isLoading = true;
      try {
        const unsubscribeNotificationTypes = Object.keys(this.notificationTypes).filter((type) => {
          return !this.selectedNotificationTypes.includes(type);
        });

        await this.$store.dispatch('user/updatePreferences', {
          notification_types: unsubscribeNotificationTypes,
          unsubscribed_from_mails: this.unsubscribedFromMails,
        });
        this.closeModal('user-notification-unsubscribe-modal');
      } catch (e) {
        if (e.response.status === 400) {
          for (let key in e.response.data.errors) {
            this.validationErrors[key] = e.response.data.errors[key];
          }
        }
      }
      this.isLoading = false;
    }
  },

  computed: {
    ...mapGetters('user', {user: 'getUser'}),
    ...mapGetters('modal', ['getModal']),

    modal() {
      return this.getModal('user-notification-unsubscribe-modal');
    }
  },

  async mounted() {
    await this.$router.isReady();
    if (this.$route.query['unsubscribe_from_type']) {
      const notification = this.$route.query['unsubscribe_from_type'];
      const notificationType = NOTIFICATION_TYPES.filter((item) => notification === item.value);
      if (notificationType.length) {
        this.openModal({
          id: 'user-notification-unsubscribe-modal',
          notification_type: notification
        });
      }
    }
  }
}
</script>
