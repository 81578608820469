<template>
  <div
      :id="id"
      ref="element"
      class="popover b-popover"
      :class="classes"
      role="tooltip"
      tabindex="-1"
  >
    <div ref="titleRef">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <div ref="contentRef">
      <slot>
        {{ content }}
      </slot>
    </div>
  </div>
</template>

<script>
import Popover from 'bootstrap/js/dist/popover'
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onBeforeUnmount,
  ref,
  watch,
} from 'vue'

export default defineComponent({
  name: 'AppPopover',
  props: {
    container: {
      type: [String, Object],
      default: 'body',
    },
    content: {type: String},
    id: {type: String},
    noninteractive: {type: Boolean, default: false},
    placement: {type: String, default: 'right'},
    target: {
      type: [String, Object],
      default: undefined,
    },
    title: {type: String},
    delay: {type: [Number, Object], default: 0},
    triggers: {type: String, default: 'click'},
    show: {type: Boolean, default: false},
    variant: {type: String, default: undefined},
    html: {type: Boolean, default: true},
    sanitize: {type: Boolean, default: false},
    fallbackPlacements: {type: Array, default: () => ['top', 'right', 'bottom', 'left']}
  },
  emits: ['show', 'shown', 'hide', 'hidden', 'inserted'],
  setup(props, {emit, slots}) {
    const element = ref()
    const target = ref()
    const instance = ref()
    const titleRef = ref()
    const contentRef = ref()
    const classes = computed(() => ({
      [`b-popover-${props.variant}`]: props.variant,
    }))

    const cleanElementProp = (target) => {
      if (typeof target === 'string') {
        return target
      } else if (target instanceof HTMLElement) return target
      else if (typeof target !== 'undefined')
        return (target).$el
      return undefined
    }

    const getElement = (element) => {
      if (!element) return undefined
      if (typeof element === 'string') {
        const idElement = document.getElementById(element)
        return idElement ? idElement : undefined
      }
      return element
    }

    const eventCallbacks = {
      show: () => emit('show'),
      shown: () => emit('shown'),
      hide: () => emit('hide'),
      hidden: () => emit('hidden'),
      inserted: () => emit('inserted'),
    };

    onMounted(() => {
      nextTick(() => {
        target.value = getElement(cleanElementProp(props.target))

        if (target.value)
          instance.value = new Popover(target.value, {
            container: cleanElementProp(props.container),
            trigger: props.triggers,
            placement: props.placement,
            title: props.title || slots.title ? titleRef.value : '',
            content: contentRef.value,
            html: props.html,
            delay: props.delay,
            sanitize: props.sanitize,
            fallbackPlacements: props.fallbackPlacements
          })
        else console.warn('[B-Popover] Target is a mandatory props.')

        for (let event in eventCallbacks) {
          target?.value?.addEventListener(`${event}.bs.popover`, eventCallbacks[event])
        }

        element.value?.parentNode?.removeChild(element.value)

        if (props.show) {
          instance.value?.show()
        }
      })
    })

    onBeforeUnmount(() => {
      for (let event in eventCallbacks) {
        target?.value?.removeEventListener(event, eventCallbacks[event])
      }
    })

    watch(
        () => props.show,
        (show, oldVal) => {
          if (show !== oldVal) {
            if (show) {
              instance.value?.show()
            } else {
              instance.value?.hide()
            }
          }
        }
    )

    return {
      element,
      titleRef,
      contentRef,
      classes,
    }
  },
})
</script>
