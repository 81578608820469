<template>
  <component
      :is="notificationComponent"
      v-model="isNotificationShown"
      :notification="notification"
  />
</template>
<script>
import {mapGetters} from "vuex";
import {defineAsyncComponent} from "vue";

export default {
  name: 'AppNotifier',

  props: {
    notificationToComponentMapping: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('message', {unseenMessages: 'getUnseenMessages'}),
    ...mapGetters('payment', {paymentInProgress: 'getPaymentInProgress'}),

    notificationComponent() {
      const component = this.notification.id
          ? this.notificationToComponentMapping[this.notification.variety]
          : null;

      if (component) {
        return defineAsyncComponent(() => import(`@/components/${component}`));
      }
      return null;
    },
  },

  data() {
    return {
      isNotificationShown: false,
      notification: {},
      notificationQueue: [],
    }
  },

  watch: {
    unseenMessages(newVal) {
      const notifications = newVal.filter(message => message.type === 'notification');
      this.notificationQueue = notifications.filter(notification => {
        return this.notificationToComponentMapping[notification.variety]
            && notification.id !== this.notification.id
      });

      this.pullNotification();
    },

    notification(newVal) {
      if (newVal) {
        this.isNotificationShown = true;
        this.$store.dispatch('message/markMessageAsSeen', this.notification);
        this.$store.dispatch('user/getUser');
      }
    },

    isNotificationShown(newVal) {
      if (!newVal) {
        this.notification = {};
        this.pullNotification();
      }
    },

    paymentInProgress(newVal) {
      if (!newVal) {
        this.pullNotification();
      }
    }
  },

  methods: {
    pullNotification() {
      if (document.visibilityState !== 'visible' || this.paymentInProgress) {
        return;
      }

      if (!this.notification.id && this.notificationQueue.length) {
        this.notification = this.notificationQueue.shift();
      }
    }
  },

  mounted() {
    document.addEventListener('visibilitychange', this.pullNotification);
  },

  beforeUnmount() {
    document.removeEventListener('visibilitychange', this.pullNotification);
  }
}
</script>
