import messageService from "@/api/services/messageService";

const SET_UNSEEN_MESSAGES = "SET_UNSEEN_MESSAGES";
const SET_SEEN_MESSAGES = "SET_SEEN_MESSAGES";
const SET_SEEN_MESSAGE_TIMEOUT = "SET_SEEN_MESSAGE_TIMEOUT";

export default {
  namespaced: true,

  state: {
    unseenMessages: [],
    seenMessages: [],
    seenMessageTimeout: null
  },

  getters: {
    getUnseenMessages(state) {
      return state.unseenMessages;
    },

    getSeenMessages(state) {
      return state.seenMessages;
    },

    getSeenMessageTimeout(state) {
      return state.seenMessageTimeout;
    }
  },

  mutations: {
    [SET_UNSEEN_MESSAGES](state, messages) {
      state.unseenMessages = messages;
    },

    [SET_SEEN_MESSAGES](state, messages) {
      state.seenMessages = messages;
    },

    [SET_SEEN_MESSAGE_TIMEOUT](state, timeout) {
      state.seenMessageTimeout = timeout;
    }
  },

  actions: {
    async getUnseenMessages({commit}) {
      const messages = await messageService.getUnseenMessages();
      commit(SET_UNSEEN_MESSAGES, messages);
    },

    async markMessageAsSeen({commit, getters}, message) {
      commit(SET_SEEN_MESSAGES, [...getters.getSeenMessages.filter(item => item.id !== message.id), message]);

      clearTimeout(getters.getSeenMessageTimeout);
      commit(SET_SEEN_MESSAGE_TIMEOUT, setTimeout(async function() {
        const messageIds = getters.getSeenMessages.map(item => item.id);
        if (messageIds) {
          await messageService.markMessagesAsSeen(messageIds);
          commit(SET_SEEN_MESSAGES, []);
          commit(SET_UNSEEN_MESSAGES, getters.getUnseenMessages.filter(message => !messageIds.includes(message.id)));
        }
      }, 10));
    }
  }
}
