<template>
  <app-modal
      id="notification-modal"
      :hide-footer="false"
      footer-class="fill-buttons"
      dialog-class="modal-sm"
  >
    <template v-slot="{modal}">
      <div class="text-center">
        <p class="message" v-html="modal.message"></p>
      </div>
    </template>
    <template #footer>
      <b-button variant="primary" data-bs-dismiss="modal">
        <i class="icon icon-check-circle"/>
        <span>Ok</span>
      </b-button>
    </template>
  </app-modal>
</template>

<script>
import AppModal from "@/components/modals/modal";
export default {
  name: "ModalNotification",
  components: {
    AppModal
  }
}
</script>
