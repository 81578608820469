import subscriptionService from "@/api/services/subscriptionService";

const SET_SUBSCRIPTION_TYPES = "SET_SUBSCRIPTION_TYPES";

export default {
  namespaced: true,

  state: {
    subscriptionTypes: []
  },

  getters: {
    getSubscriptionTypes(state) {
      return state.subscriptionTypes;
    }
  },

  mutations: {
    [SET_SUBSCRIPTION_TYPES](state, subscriptionTypes) {
      state.subscriptionTypes = subscriptionTypes;
    },
  },

  actions: {
    async getSubscriptionTypes({commit}) {
      const subscriptionTypes = await subscriptionService.getSubscriptionTypes();
      commit(SET_SUBSCRIPTION_TYPES, subscriptionTypes);
    }
  }
}
