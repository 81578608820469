import api from '@/api/api'

class UserService {
  getCurrentUser() {
    return api.axios.get('user/get-current').then(response => response.data.user);
  }

  addUserMeta(key, value) {
    return api.axios.post('user/add-meta', {
      meta_key: key,
      meta_value: value
    }).then(response => response.data.user);
  }

  updateUserProfile(username, birthday, description) {
    return api.axios.patch('user/update-profile', {username, birthday, description}).then(response => {
      if (response.data.access_token) {
        api.setAccessToken(response.data.access_token);
      }

      if (response.data.refresh_token) {
        api.setRefreshToken(response.data.refresh_token);
      }

      return response.data.user;
    });
  }

  updateUserPreferences(preferences) {
    return api.axios.patch('user/update-preferences', preferences).then(response => response.data.user);
  }

  changeEmail(email) {
    return api.axios.patch('user/change-email', {email}).then(response => response.data.user);
  }

  setBored() {
    return api.axios.patch('user/set-bored').then(response => response.data.user);
  }
}

export default new UserService();
