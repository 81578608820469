import fantasyService from "@/api/services/fantasyService";
import {FANTASY_CATEGORY_LIKE} from "@/utils/constants";

const ADD_CATEGORIZED_FANTASIES = "ADD_CATEGORIZED_FANTASIES";
const ADD_CATEGORIZED_FANTASY = "ADD_CATEGORIZED_FANTASY";
const REMOVE_CATEGORIZED_FANTASY = "REMOVE_CATEGORIZED_FANTASY";
const CLEAR_CATEGORIZED_FANTASIES = "CLEAR_CATEGORIZED_FANTASIES";
const ADD_PRESELECTED_FANTASY = "ADD_PRESELECTED_FANTASY";
const SET_LIKED_FANTASIES = "SET_LIKED_FANTASIES";

export default {
  namespaced: true,

  state: {
    categorizedFantasies: {},
    likedFantasies: null,
    preselectedFantasies: [],
  },

  getters: {
    getCategorizedFantasies: state => state.categorizedFantasies,

    getFantasiesByCategory: state => {
      return (category) => {
        return state.categorizedFantasies[category] || []
      }
    },

    getLoadedCategories: state => {
      return Object.keys(state.categorizedFantasies);
    },

    getPreselectedFantasies: state => state.preselectedFantasies,

    getLikedFantasies: state => state.likedFantasies
  },

  mutations: {
    [ADD_CATEGORIZED_FANTASIES](state, {category, fantasies}) {
      state.categorizedFantasies = {...state.categorizedFantasies, [category]: fantasies};
    },

    [ADD_CATEGORIZED_FANTASY](state, {category, fantasy}) {
      const categoryFantasies = state.categorizedFantasies[category] || [];
      const index = categoryFantasies.findIndex(item => item.id === fantasy.id);
      if (index !== -1) {
        categoryFantasies[index] = fantasy;
      } else {
        categoryFantasies.push(fantasy);
      }

      state.categorizedFantasies = {
        ...state.categorizedFantasies,
        [category]: [...categoryFantasies]
      };
    },

    [REMOVE_CATEGORIZED_FANTASY](state, {category, fantasy}) {
      if (state.categorizedFantasies[category]) {
        state.categorizedFantasies = {
          ...state.categorizedFantasies,
          [category]: [...state.categorizedFantasies[category].filter(item => item.id !== fantasy.id)]
        };
      }
    },

    [CLEAR_CATEGORIZED_FANTASIES](state) {
      state.categorizedFantasies = {};
    },

    [ADD_PRESELECTED_FANTASY](state, fantasy) {
      state.preselectedFantasies = [
        ...state.preselectedFantasies.filter(item => item.id !== fantasy.id),
        fantasy
      ];
    },

    [SET_LIKED_FANTASIES](state, fantasies) {
      state.likedFantasies = fantasies;
    },
  },

  actions: {
    async getCategorizedFantasies({commit}, {category, kinkIds}) {
      const fantasies = await fantasyService.getFantasies(category, kinkIds);
      commit(ADD_CATEGORIZED_FANTASIES, {category, fantasies});
    },

    async toggleFantasyLike({commit, getters, dispatch}, fantasy) {
      const updateFantasyLike = function (like) {
        fantasy.liked = like;
        for (const [category, fantasies] of Object.entries(getters.getCategorizedFantasies)) {
          const index = fantasies.findIndex(item => item.id === fantasy.id);
          if (index !== -1) {
            commit(ADD_CATEGORIZED_FANTASY, {category, fantasy: fantasy});
          }
        }

        if (getters.getLoadedCategories.includes(FANTASY_CATEGORY_LIKE)) {
          commit(fantasy.liked ? ADD_CATEGORIZED_FANTASY : REMOVE_CATEGORIZED_FANTASY, {
            category: FANTASY_CATEGORY_LIKE,
            fantasy: fantasy
          });
        }
      };

      const like = !fantasy.liked;
      updateFantasyLike(like);
      await fantasyService.evaluateFantasy(fantasy.id, like).catch(() => {
        updateFantasyLike(!like);
      });

      dispatch('getLikedFantasies');
      dispatch('profile/clearUntouchedProfiles', {}, {root: true});
    },

    clearCategorizedFantasies({commit}) {
      commit(CLEAR_CATEGORIZED_FANTASIES);
    },

    addPreselectedFantasy({commit}, fantasy) {
      commit(ADD_PRESELECTED_FANTASY, fantasy);
    },

    async getLikedFantasies({commit}) {
      const likedFantasies = await fantasyService.getFantasies(FANTASY_CATEGORY_LIKE, []);
      commit(SET_LIKED_FANTASIES, likedFantasies);
    }
  },
}
