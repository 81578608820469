<template>
  <div class="karma-package" :class="{'double-karma': karmaPackage.double_karma}">
    <b-form-radio
        :id="`karma-package-${karmaPackage.id}`"
        name="karma_package"
        :value="karmaPackage"
        v-model="selectedKarmaPackage"
    >
      <div class="double-karma-label" v-if="karmaPackage.double_karma">
        <span class="text-bold me-1">First-Time Offer:</span>
        <span class="interactive-text"><strong>Get</strong> <i>double</i> <strong>Karma</strong></span>
      </div>
      <div v-else-if="karmaPackage.discount" class="discount-label text-bold">{{ karmaPackage.discount }}% discount</div>
      <div v-else-if="karmaPackage.popular" class="prime-label">Most popular</div>
      <div class="custom-control-label">
        <h4 class="karma">
          <i class="icon icon-diamond-filled primary me-1"></i>
          <template v-if="karmaPackage.double_karma">
            <s class="me-1">{{ karmaPackage.karma_amount }}</s>
            <span class="interactive-text me-1">{{ karmaPackage.karma_amount * 2 }}</span> Karma
          </template>
          <template v-else>
            {{ karmaPackage.karma_amount }} Karma
          </template>
        </h4>
        <div class="price">
          <span v-if="karmaPackage.old_price_label" class="old-price">{{ karmaPackage.old_price_label }}</span>
          {{ karmaPackage.current_price_label }}
        </div>
      </div>
    </b-form-radio>
  </div>
</template>

<script>
export default {
  name: 'PaywallKarmaPackage',

  emits: ['update:modelValue'],

  computed: {
    selectedKarmaPackage: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  props: {
    karmaPackage: {
      type: Object
    },

    modelValue: {
      type: Object,
      default: () => {}
    },
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
