<template>
  <app-modal
      id="subscription-paywall-modal"
      @show="onShow"
      header-class="compact"
      :hide-footer="false"
      content-class="full-screen-xs"
      dialog-class="modal-sm paywall"
      footer-class="fill-buttons light"
      no-close-on-backdrop
      no-close-on-esc
  >
    <div class="choose-subscription-block">
      <div class="paywall-header container-fluid vertical-gutter">
        <h3>Welcome to the <span class="interactive-text">family!</span></h3>
        <div>Your erotic adventure starts here…</div>
      </div>

      <app-overlay :show="isLoading">
        <div class="d-flex justify-content-center">
          <b-list-group class="mt-3">
            <b-list-group-item>
              <i class="icon icon-check-circle success"></i>
              <div class="text-bold mb-0">Access hot chats & intimate content</div>
              <span class="text-small">Live out your fantasies with likeminded people</span>
            </b-list-group-item>
            <b-list-group-item>
              <i class="icon icon-check-circle success"></i>
              <div class="text-bold mb-0">Build meaningful connections</div>
              <span class="text-small">Deepen your sensual relationships</span>
              <connection-avatars/>
            </b-list-group-item>
            <b-list-group-item>
              <i class="icon icon-check-circle success"></i>
              <div class="text-bold mb-0">Monthly Karma package</div>
              <span class="text-small">Karma is your key to sexy fantasy chats</span>
            </b-list-group-item>
          </b-list-group>
        </div>

        <template v-if="subscriptionTypes.length">
          <div class="delimiter">
            <span>Choose your plan</span>
          </div>
          <template v-for="type in subscriptionTypes" :key="type.id">
            <subscription-type :subscription-type="type" v-model="selectedSubscriptionType"/>
          </template>
        </template>
        <div class="my-3 text-center" v-else>There are no subscription types yet.</div>
      </app-overlay>

      <secure-payment-badges/>
    </div>

    <template #footer>
      <b-button variant="primary" :disabled="!selectedSubscriptionType" @click="next">
        <span>Next</span>
        <i class="icon icon-arrow-right"></i>
      </b-button>
    </template>
  </app-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import mixpanelUtils from "@/utils/mixpanel";
import AppModal from "@/components/modals/modal";
import AppOverlay from "@/components/overlay";
import SubscriptionType from "@/components/subscription-type";
import SecurePaymentBadges from "@/components/secure-payment-badges";
import ConnectionAvatars from "@/components/connection-avatars";

export default {
  name: 'ModalPaywallSubscription',

  components: {
    ConnectionAvatars,
    SecurePaymentBadges,
    AppModal,
    AppOverlay,
    SubscriptionType,
  },

  data() {
    return {
      isLoading: false,
      selectedSubscriptionType: null,
      currentTimestamp: Date.now() / 1000,
      timeInterval: null
    }
  },

  computed: {
    ...mapGetters('subscriptionType', {subscriptionTypes: 'getSubscriptionTypes'}),
    ...mapGetters('user', {user: 'getUser'}),
    ...mapGetters('experiment', ['getExperimentResult']),

    accessRemainingSeconds() {
      if (!this.user.access_restriction_timestamp) {
        return null;
      }

      return Math.max(0, this.user.access_restriction_timestamp - this.currentTimestamp);
    },
  },

  methods: {
    ...mapActions('subscriptionType', ['getSubscriptionTypes']),
    ...mapActions('payment', ['addOrder']),
    ...mapActions('modal', ['openModal', 'closeModal']),
    ...mapActions('user', ['getUser']),

    async onShow(e) {
      this.getUser();

      if (this.user.premium) {
        e.preventDefault();
        this.closeModal('subscription-paywall-modal');
        this.openModal({id: 'karma-paywall-modal'});
        return;
      }

      this.isLoading = true;
      await this.getSubscriptionTypes();
      this.isLoading = false;

      this.selectedSubscriptionType = this.subscriptionTypes[0] || null;

      mixpanelUtils.trackSawSubscriptionPaywall();
    },

    next() {
      const subscriptionType = this.selectedSubscriptionType;
      if (subscriptionType) {
        mixpanelUtils.trackChoseSubscriptionType(subscriptionType);

        this.closeModal('subscription-paywall-modal');
        this.openModal({id: subscriptionType.trial && subscriptionType.recurring
              ? 'subscription-trial-info-modal'
              : 'payment-modal'
        });
      }
    }
  },

  watch: {
    selectedSubscriptionType: {
      handler(newVal) {
        this.addOrder({subscriptionType: newVal});
      },
      deep: true,
    },

    accessRemainingSeconds(newVal) {
      if (newVal === 0) {
        this.$router.push('/my-profile');
      }
    }
  },

  mounted() {
    this.timeInterval = setInterval(() => this.currentTimestamp = Date.now() / 1000);
  },

  beforeUnmount() {
    clearInterval(this.timeInterval);
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
