import authService from "@/api/services/authService";

const SET_LOGGED_IN = 'SET_LOGGED_IN';
const SET_INFLUENCER_ID = 'SET_INFLUENCER_ID';
const SET_FANTASY_ID = 'SET_FANTASY_ID';

export default {
  namespaced: true,

  state: {
    isLoggedIn: !!localStorage.getItem('access_token'),
    influencerId: null,
    fantasyId: null
  },

  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },

    getInfluencerId(state) {
      return state.influencerId;
    },

    getFantasyId(state) {
      return state.fantasyId;
    }
  },

  mutations: {
    [SET_LOGGED_IN](state, value) {
      state.isLoggedIn = value;
    },

    [SET_INFLUENCER_ID](state, influencerId) {
      state.influencerId = influencerId;
    },

    [SET_FANTASY_ID](state, fantasyId) {
      state.fantasyId = fantasyId;
    }
  },

  actions: {
    async login({commit}, {username, password}) {
      await authService.login(username, password);
      commit(SET_LOGGED_IN, true);
    },

    async loginLink({commit}, url) {
      await authService.loginLink(url);
      commit(SET_LOGGED_IN, true);
    },

    async loginGoogle({commit, getters}, token) {
      await authService.loginGoogle(token, getters.getInfluencerId, getters.getFantasyId);
      commit(SET_LOGGED_IN, true);
    },

    async loginFacebook({commit, getters}, token) {
      await authService.loginFacebook(token, getters.getInfluencerId, getters.getFantasyId);
      commit(SET_LOGGED_IN, true);
    },

    async signup({commit, getters}, {email, password}) {
      await authService.signup(email, password, getters.getInfluencerId, getters.getFantasyId);
      commit(SET_LOGGED_IN, true);
    },

    setLoggedIn({commit}, value) {
      commit(SET_LOGGED_IN, value);
    },

    setInfluencerId({commit}, influencerId) {
      commit(SET_INFLUENCER_ID, influencerId);
    },

    setFantasyId({commit}, fantasyId) {
      commit(SET_FANTASY_ID, fantasyId);
    },

    logout({commit}) {
      authService.logout();
      commit(SET_LOGGED_IN, false);
    }
  }
}
