<template>
  <uploaded-image-preview class="uploaded-chat-image-preview" :image-file="imageFile">
    <template #actions>
      <b-button class="upload-image wide mt-3" @click.prevent="uploadImage">
        <i class="icon icon-upload"/>
        <span>Upload picture</span>
      </b-button>
    </template>
  </uploaded-image-preview>
</template>

<script>
import {IMAGE_VISIBILITY_HIDDEN} from "@/utils/constants";
import UploadedImagePreview from "@/components/uploaded-image-preview";

export default {
  name: 'UploadedChatImagePreview',
  components: {
    UploadedImagePreview
  },
  props: {
    imageFile: {
      type: File
    },
  },
  emits: ['on-upload-file'],
  data() {
    return {
      isPrivate: false
    }
  },
  methods: {
    uploadImage() {
      this.$emit('on-upload-file', {
        file: this.imageFile,
        visibility: IMAGE_VISIBILITY_HIDDEN
      });
    }
  }
}
</script>
