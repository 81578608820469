import chatService from "@/api/services/chatService";

const ADD_CHAT = "ADD_CHAT";
const UPDATE_CHAT = "UPDATE_CHAT";
const ADD_MESSAGES = "ADD_MESSAGES";

export default {
  namespaced: true,

  state: {
    chats: []
  },

  getters: {
    getChats(state) {
      return state.chats;
    },

    getChatByConversationId(state) {
      return (id) => {
        return state.chats.find(chat => chat.conversationId === id);
      };
    }
  },

  mutations: {
    [ADD_CHAT](state, chat) {
      state.chats.push(chat);
    },

    [ADD_MESSAGES](state, {conversationId, messages, scrollDirection}) {
      let chat = state.chats.find(chat => chat.conversationId === conversationId);
      if (!chat) return;

      for (let message of messages) {
        const idx = chat.messages.findIndex((item) => item.id === message.id);
        if (idx !== -1) {
          chat.messages[idx] = message;
          continue;
        }

        chat.messages.push(message);
        if (message.quote) {
          const quoteIdx =  chat.messages.findIndex((item) => item.id === message.quote.id);
          if (quoteIdx !== -1) {
            chat.messages[quoteIdx] = message.quote;
          }
        }
      }

      chat.messages.sort(function (a, b) {
        const diff = a.timestamp - b.timestamp;
        if (diff === 0) {
          return a.id - b.id;
        }
        return diff;
      });

      const messageIds = chat.messages.map(item => item.id);
      chat.minMessageId = Math.min(...messageIds);
      chat.maxMessageId = Math.max(...messageIds);
      chat.scrollDirection = scrollDirection;

      state.chats = [...state.chats];
    },

    [UPDATE_CHAT](state, chat) {
      state.chats = [...state.chats.filter(item => item.conversationId !== chat.conversationId), chat];
    },
  },

  actions: {
    async loadChat({commit, getters, dispatch}, conversationId) {
      let chat = getters.getChatByConversationId(conversationId);
      if (!chat) {
        chat = {
          conversationId: conversationId,
          messages: [],
          minMessageId: null,
          maxMessageId: null,
          replyToMessage: null,
          scrollDirection: 'bottom'
        };
        commit(ADD_CHAT, chat);
      }

      dispatch('loadLatestMessages', chat);
      dispatch('message/getUnseenMessages', {}, {root: true});
    },

    async loadLatestMessages({dispatch}, chat) {
      const messages = await chatService.loadMessages(chat.conversationId, chat.maxMessageId);
      dispatch('addMessages', {chat, messages});
    },

    async loadOlderMessages({dispatch}, chat) {
      const messages = await chatService.loadMessages(chat.conversationId, null, chat.minMessageId);
      dispatch('addMessages', {chat, messages, scrollDirection: 'top'});
    },

    async sendMessage({dispatch}, {chat, text, image, replyToMessage}) {
      const message = await chatService.sendMessage(
        chat.conversationId,
        text,
        image ? image.id : null,
        replyToMessage ? replyToMessage.id : null
      );
      dispatch('loadLatestMessages', chat);
      dispatch('user/updateUser', message.conversation.user, {root: true});

      return message;
    },

    async sendIcebreaker({dispatch}, {chat, icebreakerId}) {
      const message = await chatService.sendIcebreaker(
          chat.conversationId,
          icebreakerId
      );
      dispatch('loadLatestMessages', chat);
      dispatch('user/updateUser', message.conversation.user, {root: true});

      return message;
    },

    addMessages({commit, dispatch}, {chat, messages, scrollDirection}) {
      commit(ADD_MESSAGES, {
        conversationId: chat.conversationId,
        messages,
        scrollDirection: scrollDirection || 'bottom'
      });

      if (messages.length) {
        const lastMessage = messages[messages.length - 1];
        dispatch('conversation/addConversation', lastMessage.conversation, {root: true});
        dispatch('user/updateUser', lastMessage.conversation.user, {root: true});
      }
    },

    setReplyToMessage({commit}, {chat, message}) {
      chat.replyToMessage = message;
      commit(UPDATE_CHAT, chat);
    }
  }
}
