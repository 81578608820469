<template>
  <app-modal
      id="karma-paywall-modal"
      @shown="onShown"
      hide-header
      :hide-footer="false"
      content-class="full-screen-xs"
      dialog-class="modal-sm"
      footer-class="fill-buttons light"
    >
    <div class="choose-karma-block">
      <app-headline :absolute="true" :blackout="true" :backgroundImageUrl="require('@/assets/images/payment/header_bg.png')">
        <b-button variant="close" data-bs-dismiss="modal">
          <i class="icon icon-close"></i>
        </b-button>
        <h3>Recharge Karma</h3>
        <h4>Choose your payment option</h4>
      </app-headline>

      <app-overlay :show="isLoading">
        <div class="karma-list my-3" v-if="karmaPackages.length">
          <template v-for="karmaPackage in karmaPackages" :key="karmaPackage.id">
            <karma-package :karma-package="karmaPackage" v-model="selectedKarmaPackage"/>
          </template>
        </div>

        <div class="my-3 text-center" v-else>There are no karma packages yet.</div>
      </app-overlay>

      <secure-payment-badges/>
    </div>

    <template #footer>
      <b-button variant="primary" :disabled="!selectedKarmaPackage" @click="next">
        <span>Next</span>
        <i class="icon icon-arrow-right"></i>
      </b-button>
    </template>
  </app-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import mixpanelUtils from "@/utils/mixpanel";
import AppModal from "@/components/modals/modal";
import AppOverlay from "@/components/overlay";
import AppHeadline from "@/components/headline";
import KarmaPackage from "@/components/karma-package";
import SecurePaymentBadges from "@/components/secure-payment-badges";

export default {
  name: 'ModalKarmaPaywall',

  components: {
    AppHeadline,
    AppModal,
    AppOverlay,
    KarmaPackage,
    SecurePaymentBadges
  },

  data() {
    return {
      selectedKarmaPackage: null,
      isLoading: false
    }
  },

  computed: {
    ...mapGetters('karmaPackage', {karmaPackages: 'getKarmaPackages'})
  },

  methods: {
    ...mapActions('karmaPackage', ['getKarmaPackages']),
    ...mapActions('payment', ['addOrder']),

    async onShown() {
      if (!this.karmaPackages.length) {
        this.isLoading = true;
        await this.getKarmaPackages();
        this.isLoading = false;
      }

      this.selectedKarmaPackage = this.karmaPackages[0] || null;

      mixpanelUtils.trackSawKarmaPaywall();
    },

    next() {
      if (this.selectedKarmaPackage) {
        mixpanelUtils.trackChoseKarmaPackage(this.selectedKarmaPackage);

        this.$store.dispatch('modal/closeModal', 'karma-paywall-modal');
        this.$store.dispatch('modal/openModal', {id: 'payment-modal'});
      }
    }
  },

  watch: {
    selectedKarmaPackage: {
      handler(newVal) {
        this.$store.dispatch('payment/addOrder', {karmaPackage: newVal});
      },
      deep: true,
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
