<template>
  <header ref="header" :class="user.id ? 'authorized' : ''">
    <b-container fluid>
      <div class="content">
        <a v-if="showBackButton" class="back-button" href="#" @click.prevent="$router.back()"><i class="icon icon-arrow-left"/></a>

        <router-link to="/">
          <div class="logo"/>
        </router-link>

        <template v-if="user.id">
          <div class="header-items d-none d-md-flex" ref="menu">
            <div class="nav flex">
              <router-link
                  v-for="link in links"
                  :key="link.uri"
                  :to="link.uri"
                  :class="{
                    active: $route.path === link.uri,
                    pulsating: link.showAnimation && link.showAnimation(),
                    'notification-indicator': link.showNotificationIndicator && link.showNotificationIndicator()
                  }"
                  :id="link.id || ''"
                  @click="link.click ? link.click() : null"
              >
                <i class="icon" :class="link.icon"/>
                {{ link.label }}
              </router-link>

              <popover-explore-fantasies
                  container="header"
                  target="header-fantasies-link"
                  :show="showExploreFantasyHint"
                  placement="bottom"
              />
            </div>

            <div class="flex-grow-1"></div>
            <b-button
                to="/chat"
                variant="default"
                class="flat conversations-button"
                :class="{
                  active: $route.path === '/chat',
                  'notification-indicator': getUnseenMessages.filter(item => item.conversation).length
                }"
            >
              <i class="icon icon-message-circle"></i>
              <span>Conversations</span>
            </b-button>

            <div class="user-profile-thumb">
              <b-link to="/my-profile">
                <app-avatar :source="user.avatar.urls.square" size="small"/>
                <user-karma-badge/>
              </b-link>
            </div>
            <div class="username text-small text-semibold">
              <b-link to="/my-profile">{{ user.username }}</b-link>
            </div>
          </div>
          <user-karma-badge class="d-block d-md-none ms-auto"/>
        </template>

        <template v-else>
          <div class="flex-grow-1"></div>
          <div class="auth-actions">
            <b-link class="btn btn-default me-sm-2 me-md-3" v-b-modal="'login-modal'">
              <i class="icon icon-log-in"></i>
              <span class="d-none d-md-inline-block">Login</span>
            </b-link>
            <b-link class="btn btn-default" v-b-modal="'signup-modal'">
              <i class="icon icon-user"></i>
              <span class="d-none d-md-inline-block">Sign up</span>
            </b-link>
          </div>
        </template>
      </div>
    </b-container>
  </header>

  <element-highlighter v-if="showExploreFantasyHint" :elements="[$refs.header]" :clickable="false"/>
</template>

<script>
import {mapGetters} from "vuex";
import AppAvatar from "@/components/avatar";
import UserKarmaBadge from "@/components/user-karma-badge";
import ElementHighlighter from "@/components/element-highlighter";
import PopoverExploreFantasies from "@/components/popovers/popover-explore-fantasies";

export default {
  name: "AppHeader",
  components: {
    PopoverExploreFantasies,
    ElementHighlighter,
    UserKarmaBadge,
    AppAvatar
  },

  data() {
    return {
      links: [
        {label: "Home", uri: "/", icon: "icon-home", id: 'header-home-link'},
        {
          label: "Fantasies",
          uri: "/fantasies",
          icon: "icon-edit",
          id: 'header-fantasies-link',
          showAnimation: () => this.showExploreFantasyHint,
          click: () => this.showExploreFantasyHint = false
        },
        {
          label: "Karma",
          uri: "/karma",
          icon: "icon-diamond",
          id: 'header-karma-link',
          showNotificationIndicator: () => this.user.chat_activity_reward_progress === 100
        }
      ],
      showExploreFantasyHint: false
    };
  },

  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('user', {user: 'getUser'}),
    ...mapGetters('layout', ['showFantasyHints', 'showBackButton']),
    ...mapGetters('message', ['getUnseenMessages'])
  },
  watch: {
    showFantasyHints(newVal) {
      this.$nextTick(() => {
        this.showExploreFantasyHint = newVal
            && this.$route.name !== 'Fantasies'
            && this.$refs.menu.offsetParent !== null;
      });
    },
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
