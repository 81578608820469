<template>
  <div class="text-quote">{{ text }}</div>
</template>

<script>
export default {
  name: 'TextQuote',
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
