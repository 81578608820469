<template>
  <p class="policy text-center">
    By entering your data (name, e-mail, etc.) and clicking on the button "{{buttonName}}", you agree to our
    <b-link to="/static/data-policy">Data Policy</b-link> and the provisions on cookies and Internet advertising.
  </p>
</template>

<script>
export default {
  name: "DataProtectionHint",
  props: {
    buttonName: {
      type: String,
      default: "Ok"
    }
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
