<template>
  <div class="webcam-screenshot">
    <div class="webcam-container">
      <div v-if="!webcamStream" class="webcam-tutorial">
        <i class="icon icon-webcam"></i>
        <p class="text-bold">
          Please allow app to use your camera
        </p>
        App needs access to your camera to take photos. You can find the access notification in your browser. Please allow your browser get access to your webcam
      </div>
      <div v-else class="webcam">
        <video ref="video" width="800" height="600" :class="{'d-none': screenshotDone}" autoplay/>
        <canvas ref="canvas" width="800" height="600" :class="{'d-none': !screenshotDone}"/>
      </div>
    </div>
    <div class="controls">
      <b-button
          v-if="!screenshotDone"
          :disabled="!webcamStream"
          class="make-screenshot"
          @click="makeScreenshot"
      >
        <div/>
      </b-button>
      <b-button v-if="screenshotDone" @click="makeScreenshot">
        <i class="icon icon-webcam"/>
        <span>Retake</span>
      </b-button>
    </div>
    <div class="actions">
      <slot name="actions"/>
      <b-button :disabled="!screenshotDone" class="use-screenshot" @click="useScreenshot">Looks good!</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WebcamScreenshot',
  data() {
    return {
      webcamStream: null,
      screenshotDone: false
    }
  },
  emits: ['screenshot-done'],
  methods: {
    makeScreenshot() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(this.$refs.video, 0,0, canvas.width, canvas.height);
      this.screenshotDone = true;
    },
    useScreenshot() {
      const canvas = this.$refs.canvas;
      canvas.toBlob((blob) => {
        const file = new File(
          [blob],
          'webcam-screenshot' + Date.now() + '.jpg',
          {type: "image/jpeg"}
        );
        this.$emit('screenshot-done', file);
      });
    }
  },
  mounted() {
    navigator.mediaDevices.getUserMedia({
      video: {
        width: {min: 600, ideal: 1000, max: 1200},
        height: {min: 450, ideal: 750, max: 900},
      },
      audio: false
    }).then((stream) => {
      this.webcamStream = stream;
      this.$nextTick(() => {
        this.$refs.video.srcObject = stream;
      });
    });
  },
  beforeUnmount() {
    if (this.webcamStream) {
      this.webcamStream.getTracks().forEach(function(track) {
        track.stop();
      });
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
