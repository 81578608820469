<template>
  <app-modal
      id="payment-success-modal"
      :hide-footer="false"
      content-class="full-screen-xs"
      dialog-class="modal-sm"
  >
    <div class="circle-icon badge scatter color-success mx-auto mb-3">
      <i class="icon empty icon-check-circle"></i>
    </div>
    <h4 class="text-center">Well done!</h4>
    <p class="text-center">Thank you!</p>
    <p class="text-center" v-if="modal.karmaAmount">You've received {{ modal.karmaAmount }} karma</p>

    <template #footer>
      <b-button variant="success" class="wide" data-bs-dismiss="modal">
        <i class="icon icon-check-circle"></i>
        <span>OK</span>
      </b-button>
    </template>
  </app-modal>
</template>

<script>
import AppModal from "@/components/modals/modal";
import {mapGetters} from "vuex";

export default {
  name: 'ModalPaymentSuccess',

  components: {
    AppModal
  },

  computed: {
    ...mapGetters('modal', ['getModal']),

    modal() {
      return this.getModal('payment-success-modal');
    }
  }
}
</script>
