import store from "@/store";
import {
  IMAGE_VISIBILITY_PUBLIC,
  IMAGE_STATUS_APPROVED
} from "@/utils/constants";

function notifyAboutImageStatus(image) {
  if (image.status !== IMAGE_STATUS_APPROVED) {
    store.dispatch('modal/openModal', {
      id: 'image-moderation-notification-modal',
      image: image
    });
  }
}

export function uploadAvatar() {
  return store.dispatch('modal/openModal', {
    id: 'upload-image-modal',
    ageVerificationRequired: true,
    onUpload: async ({file}) => {
      const image = await store.dispatch('userImage/uploadAlbumImage', {
        file,
        visibility: IMAGE_VISIBILITY_PUBLIC,
        isAvatar: true
      });
      await store.dispatch('modal/closeModal', 'upload-image-modal');
      notifyAboutImageStatus(image);
    }
  })
}

export function uploadAlbumImage() {
  return store.dispatch('modal/openModal', {
    id: 'upload-image-modal',
    ageVerificationRequired: true,
    previewTag: 'uploaded-album-image-preview',
    onUpload: async (payload) => {
      const image = await store.dispatch('userImage/uploadAlbumImage', payload);
      await store.dispatch('modal/closeModal', 'upload-image-modal');
      notifyAboutImageStatus(image);
    }
  })
}

export function uploadChatImage() {
  return store.dispatch('modal/openModal', {
    id: 'upload-image-modal',
    previewTag: 'uploaded-chat-image-preview',
    ageVerificationRequired: false,
    onUpload: async (payload) => {
      await store.dispatch('userImage/uploadChatImage', payload);
      await store.dispatch('modal/closeModal', 'upload-image-modal');
      await store.dispatch('modal/closeModal', 'send-image-modal');
    }
  })
}
