<template>
  <app-modal
      id="image-moderation-notification-modal"
      footer-class="fill-buttons"
      dialog-class="modal-sm"
      body-class="text-center"
      header-class="compact"
      :hide-footer="false"
  >
    <div class="image">
      <img :src="modal.image.urls.medium" alt="Image"/>
      <div v-if="modal.image.status === this.IMAGE_STATUS_REJECTED" class="circle-icon error">
        <i class="icon icon-close"/>
      </div>
      <div v-else class="circle-icon primary">
        <i class="icon icon-eye"/>
      </div>
    </div>

    <p class="mt-4">
      {{ modal.image.status === this.IMAGE_STATUS_REJECTED ? 'Your image was rejected.' : 'Your image will be moderated soon.' }}
      Please,
      <age-verification>
        <template v-slot:default="{startVerification}">
          <b-link @click.prevent="onClick(startVerification)">verify your age</b-link>
        </template>
      </age-verification>
      to get access to the instant photos posting.
    </p>

    <template #footer>
      <b-button class="wide" data-bs-dismiss="modal">
        <i class="icon icon-check-circle"/>
        <span>OK</span>
      </b-button>
    </template>
  </app-modal>
</template>
<script>
import AppModal from "@/components/modals/modal";
import AgeVerification from "@/components/age-verification";
import {IMAGE_STATUS_REJECTED} from '@/utils/constants';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ModalImageModerationNotification',
  components: {
    AgeVerification,
    AppModal
  },
  computed: {
    ...mapGetters('modal', ['getModal']),
    modal() {
      return this.getModal('image-moderation-notification-modal');
    }
  },
  methods: {
    ...mapActions('modal', ['closeModal']),

    onClick(callback) {
      this.closeModal('image-moderation-notification-modal');
      callback();
    }
  },
  created() {
    this.IMAGE_STATUS_REJECTED = IMAGE_STATUS_REJECTED;
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
