<template>
  <div class="adult-restriction">
    <div class="blocked-content">
      <div class="icon icon-lock"></div>
      <template v-if="showRestrictionInfo">
        <h3>18+ Content</h3>
        <div class="text-bold">Available only for the subscribers</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdultRestriction',

  props: {
    showRestrictionInfo: {
      type: Boolean,
      default: true
    },
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
