<template>
  <app-modal
      id="profile-modal"
      header-class="compact"
      hide-footer
      footer-class="light"
      @show="onShow"
      @shown="isShown = true"
      @hidden="isShown = false"
  >
    <app-overlay :show="isLoading">
      <div class="profile" v-if="profile">
        <div class="basic-info">
          <app-avatar :online="profile.online" :source="profile.avatar.urls.square" class="responsive ratio-1-1"/>
          <h4 class="name">
            <span>{{profile.username}}</span>
            <i v-if="profile.verified" ref="verificationIco" class="icon icon-check-circle" id="verification-ico"/>
            <app-popover target="verification-ico" triggers="click" html placement="bottom">
              <div class="icon icon-close floating primary btn-close" @click="$refs.verificationIco.click()"/>
              <div>A verified profile badge is a confirmation that the account is held by the real person</div>
              <age-verification>
                <template v-slot:default="{startVerification}">
                  <b-button v-if="!user.identity_verified" @click="startVerification" variant="" class="mt-1">
                    Verify Your Profile
                  </b-button>
                </template>
              </age-verification>
            </app-popover>
          </h4>
        </div>

        <app-tags-list
            v-if="isShown"
            expandable
            selected-tags-first
            class="profile-tags"
            :tags="profile.kinks"
            v-model="user.kinks"
        />

        <div class="container-fluid vertical-gutter rounded-bg mt-3">
          <div class="profile-description">{{ profile.description }}</div>
          <profile-gallery :images="profile.images" class="mt-3"/>
        </div>
      </div>

      <div v-else-if="typeof profile !== 'undefined'" class="profile-not-found">
        <h4 class="text-center">Profile not found</h4>
      </div>
    </app-overlay>
  </app-modal>
</template>

<script>
import {mapGetters} from "vuex";

import AppModal from "@/components/modals/modal";
import AppAvatar from "@/components/avatar";
import AppOverlay from "@/components/overlay";
import ProfileGallery from "@/components/profile-gallery";
import AppTagsList from "@/components/tags-list";
import AppPopover from "@/components/popovers/popover";
import AgeVerification from "@/components/age-verification";

export default {
  name: 'ModalProfile',
  components: {
    AgeVerification,
    AppPopover,
    AppTagsList,
    ProfileGallery,
    AppOverlay,
    AppAvatar,
    AppModal
  },
  data() {
    return {
      isLoading: false,
      isShown: false
    }
  },
  computed: {
    ...mapGetters('profile', ['getProfileById', 'getProfiles']),
    ...mapGetters('modal', ['getModal']),
    ...mapGetters('user', {user: 'getUser'}),

    modal() {
      return this.getModal('profile-modal');
    },

    profile() {
      return this.modal ? this.getProfileById(this.modal.profileId) : undefined;
    }
  },
  methods: {
    async loadProfile() {
      if (!this.profile) {
        await this.$store.dispatch('profile/getProfile', this.modal.profileId);
      }
    },

    async onShow() {
      this.isLoading = true;
      await this.loadProfile();
      this.isLoading = false;
    }
  },

  watch: {
    user: {
      handler(newValue, oldValue) {
        if (newValue.premium && !oldValue.premium && this.getProfiles.length) {
          this.$store.dispatch('profile/updateProfiles');
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>

