<template>
  <div class="input-icon-group" :class="position">
    <slot/>
    <i class="icon" :class="'icon-' + icon"></i>
  </div>
</template>

<script>
export default {
  name: "InputIconGroup",
  props: {
    position: {
      type: String,
      default: 'right',
      validator(value) {
        return ['left', 'right'].includes(value)
      }
    },
    icon: {
      type: String,
      required: true
    },
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
