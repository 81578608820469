<template>
  <teleport to="body">
    <vue-easy-lightbox
        :visible="visible"
        :style="{zIndex}"
        v-bind="$attrs"
        class="lightbox"
        move-disabled
    >
      <template #toolbar>
        <slot name="toolbar"/>
      </template>
      <template v-slot:close-btn="{close}">
        <div class="btn__close" @click="close">
          <i class="icon icon-close"/>
          close
        </div>
      </template>
    </vue-easy-lightbox>
  </teleport>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import {mapGetters} from "vuex";

export default {
  name: 'AppLightbox',
  components: {
    VueEasyLightbox
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      openedModals: [],
      zIndex: false
    }
  },

  computed: {
    ...mapGetters('modal', ['getOpenedModals'])
  },

  watch: {
    getOpenedModals: {
      handler(newValue, oldValue) {
        if (!this.visible) {
          return;
        }

        this.$nextTick(() => {
          if (newValue.length && newValue.length >= oldValue.length) {
            const openedModal = newValue[newValue.length - 1];
            this.openedModals.push(openedModal);
            if (!this.zIndex) {
              this.zIndex = document.getElementById(openedModal.id).style.zIndex - 1;
            }
          } else if (newValue.length < oldValue.length) {
            if (!this.openedModals.length) {
              return;
            }

            const closedModals = oldValue.filter(item => !newValue.includes(item));
            this.openedModals = this.openedModals.filter(item => !closedModals.includes(item));
            if (!this.openedModals.length) {
              this.zIndex = false;
            }
          }
        });
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
