<template>
  <div class="karma-scores user-karma" :class="{animate: isAnimated}">
    <b-link to="/karma">
      <i class="icon icon-diamond-filled"></i>
      <span>{{ karma }}</span>
    </b-link>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'UserKarmaBadge',

  data: function() {
    const user = this.$store.getters['user/getUser'];
    return {
      karmaAnimTimer: null,
      animationDuration: 1000,
      isAnimated: false,
      karma: user.karma,
    }
  },

  computed: {
    ...mapGetters('user', {user: 'getUser'})
  },

  watch: {
    user: {
      handler(newValue, oldValue) {
        if (newValue.karma !== oldValue.karma) {
          const karmaDiff = newValue.karma - oldValue.karma;
          clearInterval(this.karmaAnimTimer);
          this.karma = oldValue.karma;
          this.isAnimated = false;
          if (karmaDiff > 0) {
            this.isAnimated = true;
            this.karma = karmaDiff;
            this.karmaAnimTimer = setInterval(() => {
              this.isAnimated = false;
              this.karma = newValue.karma;
              clearInterval(this.karmaAnimTimer);
            }, this.animationDuration);
          } else {
            const durationPerPoint = this.animationDuration / Math.abs(karmaDiff);
            this.karmaAnimTimer = setInterval(() => {
              if (this.karma === newValue.karma) {
                clearInterval(this.karmaAnimTimer);
                return;
              }
              this.karma--;
            }, durationPerPoint);
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
