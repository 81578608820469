import api from '@/api/api'

class ConversationService {
  getConversations() {
    return api.axios.get('conversation/get-all')
      .then((response) => response.data.conversations);
  }

  getConversation(id) {
    return api.axios.get(`conversation/get/${id}`)
      .then((response) => response.data.conversation);
  }
}

export default new ConversationService();
