import staticPageService from "@/api/services/staticPageService";

const ADD_PAGE = "ADD_PAGE";

export default {
  namespaced: true,

  state: {
    pages: []
  },

  getters: {
    getPageBySlug(state) {
      return (slug) => {
        return state.pages.find(page => page.slug === slug);
      };
    }
  },

  mutations: {
    [ADD_PAGE](state, page) {
      state.pages = [...state.pages.filter(item => item.id !== page.id), page];
    },
  },

  actions: {
    async getPage({commit}, slug) {
      const page = await staticPageService.getPage(slug);
      commit(ADD_PAGE, page);
    }
  }
}
