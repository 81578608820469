import store from "@/store";

let callbackTimeout;
const paymentModalIds = [
  'payment-modal',
  'payment-success-modal',
  'subscription-paywall-modal',
  'subscription-trial-info-modal',
  'karma-paywall-modal'
];

store.watch(
  function (state) {
    return state.modal.openedModals;
  },
  function (openedModals) {
    clearTimeout(callbackTimeout);

    callbackTimeout = setTimeout(() => {
      let paymentInProgress = false;
      for (let modal of openedModals) {
        if (paymentModalIds.includes(modal.id)) {
          paymentInProgress = true;
          break;
        }
      }

      store.dispatch('payment/setPaymentInProgress', paymentInProgress);
    }, 1);
  },
  {
    deep: true
  }
);
