<template>
  <app-popover v-bind="$attrs"
      triggers="manual"
      html
  >
    <div>Select fantasies you like</div>
  </app-popover>
</template>

<script>
import AppPopover from "@/components/popovers/popover";
export default {
  name: 'PopoverExploreFantasies',
  components: {
    AppPopover
  }
}
</script>
