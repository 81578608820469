<template>
  <div class="user-gallery-carousel">
    <div class="gallery-title">
      <slot name="title">{{ galleryTitle }}</slot>
    </div>
    <div class="gallery-size">
      <span>{{ images.length }}</span> photos
    </div>
    <app-carousel class="gallery-images">
      <slot/>
      <div
          v-for="image in images"
          :key="image.id"
          v-lazy="image.urls.square"
          class="gallery-image responsive ratio-1-1"
          @click="$emit('image-click', image)"
      >
      </div>
    </app-carousel>
  </div>
</template>

<script>
import AppCarousel from "@/components/carousel";

export default {
  name: 'UserGalleryCarousel',
  components: {
    AppCarousel
  },
  emits: ['image-click'],
  props: {
    galleryTitle: {
      type: String,
      default: ''
    },
    images: {
      type: Array,
      default: () => []
    },
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
