import api from '@/api/api';

class PasswordRecoveryService {
    forgotPassword(email) {
        return api.axios.post('auth/forgot-password', {email}).then(response => response.data);
    }

    changePassword(password, repeatPassword, secret) {
        return api.axios.post('auth/recover-password', {password, secret, 'repeat_password': repeatPassword})
            .then(response => {
                return response.data.user;
            });
    }
}

export default new PasswordRecoveryService();
