import {createStore} from 'vuex';
import userModule from './modules/user';
import authModule from './modules/auth'
import layoutModule from "./modules/layout";
import modalModule from "./modules/modal";
import conversationModule from "./modules/conversation";
import chatModule from "./modules/chat";
import messageModule from "./modules/message";
import profileModule from "./modules/profile";
import influencerModule from "@/store/modules/influencer";
import userImageModule from "./modules/userImage";
import kinkModule from "./modules/kink";
import chatActivityRewardModule from "./modules/chatActivityReward";
import fantasyModule from "@/store/modules/fantasy";
import staticPageModule from "@/store/modules/staticPage";
import karmaModule from "@/store/modules/karma";
import subscriptionTypeModule from "@/store/modules/subscriptionType";
import karmaPackageModule from "@/store/modules/karmaPackage";
import paymentModule from "@/store/modules/payment";
import creditCardModule from "@/store/modules/creditCard";
import chatEventModule from "@/store/modules/chatEvent";
import experimentModule from "@/store/modules/experiment";
import icebreakerModule from "@/store/modules/icebreaker";

const store = createStore({
  modules: {
    user: userModule,
    auth: authModule,
    layout: layoutModule,
    modal: modalModule,
    conversation: conversationModule,
    chat: chatModule,
    message: messageModule,
    profile: profileModule,
    influencer: influencerModule,
    userImage: userImageModule,
    kink: kinkModule,
    chatActivityReward: chatActivityRewardModule,
    fantasy: fantasyModule,
    staticPage: staticPageModule,
    karma: karmaModule,
    payment: paymentModule,
    subscriptionType: subscriptionTypeModule,
    karmaPackage: karmaPackageModule,
    creditCard: creditCardModule,
    chatEvent: chatEventModule,
    experiment: experimentModule,
    icebreaker: icebreakerModule
  }
});

export default store;
