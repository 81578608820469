import api from '@/api/api';

class ExperimentService {
  getExperimentResults() {
    return api.axios.get('experiment/get-results').then((response) => {
      return response.data.experiment_results;
    });
  }
}

export default new ExperimentService();
