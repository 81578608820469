import userImageService from "@/api/services/userImageService";
import {
  IMAGE_STATUS_APPROVED,
  IMAGE_VISIBILITY_PRIVATE,
  IMAGE_VISIBILITY_PUBLIC
} from "@/utils/constants";

const SET_ALBUM_IMAGES = "SET_ALBUM_IMAGES";
const SET_CHAT_IMAGES = "SET_CHAT_IMAGES";
const UPDATE_ALBUM_IMAGE = "UPDATE_ALBUM_IMAGE";
const ADD_ALBUM_IMAGE = "ADD_ALBUM_IMAGE";
const DELETE_ALBUM_IMAGE = "DELETE_ALBUM_IMAGE";
const SET_ATTACHED_CHAT_IMAGE = "SET_ATTACHED_CHAT_IMAGE";

export default {
  namespaced: true,

  state: {
    albumImages: [],
    chatImages: [],
    attachedChatImage: null
  },

  getters: {
    getPublicImages(state) {
      return state.albumImages.filter(image => image.visibility === IMAGE_VISIBILITY_PUBLIC);
    },

    getPrivateImages(state) {
      return state.albumImages.filter(image => image.visibility === IMAGE_VISIBILITY_PRIVATE);
    },

    getChatImages(state) {
      return state.chatImages;
    },

    getAttachedChatImage(state) {
      return state.attachedChatImage;
    }
  },

  mutations: {
    [SET_ALBUM_IMAGES](state, images) {
      state.albumImages = images;
    },
    [SET_CHAT_IMAGES](state, images) {
      state.chatImages = images;
    },
    [UPDATE_ALBUM_IMAGE](state, image) {
      const index = state.albumImages.findIndex(item => item.id === image.id);
      state.albumImages[index] = image;
    },
    [DELETE_ALBUM_IMAGE](state, imageId) {
      state.albumImages = [...state.albumImages.filter(item => item.id !== imageId)];
    },
    [ADD_ALBUM_IMAGE](state, image) {
      state.albumImages = [...state.albumImages.filter(item => item.id !== image.id), image];
    },
    [SET_ATTACHED_CHAT_IMAGE](state, image) {
      state.attachedChatImage = image;
    }
  },

  actions: {
    async getAlbumImages({commit}) {
      const images = await userImageService.getAlbumImages();
      commit(SET_ALBUM_IMAGES, images);
    },

    async getChatImages({commit}) {
      const images = await userImageService.getChatImages();
      commit(SET_CHAT_IMAGES, images);
    },

    async markAlbumImageAsAvatar({commit, dispatch}, imageId) {
      const image = await userImageService.setAvatar(imageId);
      commit(UPDATE_ALBUM_IMAGE, image);

      await dispatch('user/getUser', {}, {root: true});
    },

    async updateAlbumImageVisibility({commit}, {imageId, visibility}) {
      const image = await userImageService.updateImageVisibility(imageId, visibility);
      commit(UPDATE_ALBUM_IMAGE, image);
    },

    async deleteAlbumImage({commit}, imageId) {
      await userImageService.deleteImage(imageId);
      commit(DELETE_ALBUM_IMAGE, imageId);
    },

    async uploadAlbumImage({commit, dispatch}, {file, visibility, isAvatar}) {
      const image = await userImageService.uploadImage(file, visibility, isAvatar);
      if (image.status === IMAGE_STATUS_APPROVED) {
        commit(ADD_ALBUM_IMAGE, image);
        await dispatch('user/getUser', {}, {root: true});
      }

      dispatch('message/getUnseenMessages', {}, {root: true});

      return image;
    },

    async uploadChatImage({commit}, {file, visibility}) {
      const image = await userImageService.uploadImage(file, visibility);
      commit(SET_ATTACHED_CHAT_IMAGE, image);

      return image;
    },

    setAttachedChatImage({commit}, image) {
      commit(SET_ATTACHED_CHAT_IMAGE, image);
    }
  }
}
