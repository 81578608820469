import api from '@/api/api';

class FantasyService {
    getFantasies(category, kinkIds) {
        return api.axios.get('fantasy/get-fantasies', {
            params: {
                category: category,
                kink_ids: kinkIds
            }
        }).then((response) => {
            return response.data.fantasies;
        });
    }

    evaluateFantasy(fantasyId, like) {
        return api.axios.post('fantasy/like/' + fantasyId, {
            like: like
        }).then((response) => {
            return response.data.fantasy;
        });
    }

    createFantasy({text, kinks}) {
        return api.axios.post('fantasy/create', {text, kink_ids: kinks.map(kink => kink.id)});
    }

    async searchMatchedProfile(priorityFantasyIds, forcedProfileId = null, forcedFantasyId = null) {
        return api.axios.get('fantasy/search-matched-profile', {
            params: {
                priority_fantasy_ids: priorityFantasyIds,
                forced_profile_id: forcedProfileId,
                forced_fantasy_id: forcedFantasyId
            }
        }).then((response) => {
            return response.data;
        });
    }

    startFantasy(fantasyId, profileId) {
        return api.axios.post('fantasy/start-fantasy', {
            fantasy_id: fantasyId,
            profile_id: profileId
        }).then((response) => {
            return response.data.conversation;
        });
    }

    getMatchedFantasy(profileId) {
        return api.axios.get('fantasy/get-matched-fantasy/' + profileId).then((response) => {
            return response.data.fantasy;
        });
    }

    getConversationFantasies(conversationId) {
        return api.axios.get('fantasy/get-conversation-fantasies/' + conversationId)
          .then((response) => {
            return response.data.fantasies;
          });
    }
}

export default new FantasyService();
