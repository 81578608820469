import api from '@/api/api'

class MessageService {
  getUnseenMessages() {
    return api.axios.get('message/get-unseen').then((response) => response.data.messages);
  }

  markMessagesAsSeen(messageIds) {
    return api.axios.patch('message/mark-as-seen', {message_ids: messageIds});
  }
}

export default new MessageService();
