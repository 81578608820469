import api from '@/api/api';

class AgeVerificationService {
  startVerification() {
    return api.axios.post('age-verification/start-verification').then(response => {
      return {
        client_token: response.data.client_token,
        status: response.data.status
      }
    });
  }

  finishVerification() {
    return api.axios.post('age-verification/finish-verification').then(response => response.data.status);
  }
}

export default new AgeVerificationService();
