import api from "@/api/api";

class KarmaChargeService {
  getKarmaPackages() {
    return api.axios.get('karma-charge/get-karma-packages').then((response) => {
      return response.data.karma_packages
    });
  }

  buyKarma(karmaPackageId, creditCardId) {
    return api.axios.post('karma-charge/buy-karma', {
      'karma_package_id': karmaPackageId,
      'credit_card_id': creditCardId
    }).then((response) => {
      return response.data;
    });
  }
}

export default new KarmaChargeService();
