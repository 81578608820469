<template>
  <b-form @submit="onSubmit" class="login-form overflow-auto animated">
    <app-overlay :show="isLoading">
      <b-form-group class="username mb-0">
        <input-icon-group position="left" class="animated-form-field" icon="user">
          <b-form-input
              type="text"
              v-model="username"
              :state="getFieldState('username')"
              placeholder="Input your username/email"
              autocomplete="username"
          />
          <b-form-invalid-feedback>{{ validationErrors['username'] }}</b-form-invalid-feedback>
          <label>Email or username</label>
        </input-icon-group>
      </b-form-group>

      <b-form-group class="password">
        <input-icon-group position="left" class="animated-form-field" icon="lock">
          <b-form-input
              type="password"
              v-model="password"
              :state="getFieldState('password')"
              placeholder="Input your password"
              autocomplete="current-password"
          />
          <b-form-invalid-feedback>{{ error || validationErrors['password'] }}</b-form-invalid-feedback>
          <label>Password</label>
        </input-icon-group>
      </b-form-group>

      <b-form-group class="text-center">
        <b-link @click.prevent="openForgotPasswordModal" class="forgot-password">
          <ins>Forgot Password?</ins>
        </b-link>
      </b-form-group>

      <div class="text-center">
        <b-button type="submit" variant="primary" class="wide login">
          <i class="icon icon-log-in"></i>
          <span>Log in</span>
        </b-button>
      </div>

      <slot/>
    </app-overlay>
  </b-form>

  <modal-forgot-password/>
</template>

<script>
import InputIconGroup from "@/components/input-icon-group";
import AppOverlay from "@/components/overlay";
import ModalForgotPassword from "@/components/modals/modal-forgot-password";

export default {
  name: "LoginForm",
  components: {
    ModalForgotPassword,
    InputIconGroup,
    AppOverlay
  },
  data() {
    return {
      isSubmitted: false,
      isLoading: false,
      username: '',
      password: '',
      validationErrors: {},
      error: false
    };
  },

  methods: {
    getFieldState(field) {
      return !this.isSubmitted ? null : (!this.validationErrors[field] && !this.error);
    },

    openForgotPasswordModal() {
      this.$store.dispatch('modal/openModal', {id: "forgot-password-modal"});
    },

    async onSubmit() {
      this.error = false;
      this.isSubmitted = true;
      this.validationErrors = [];

      if (!this.username) {
        this.validationErrors.username = 'Field is required';
      }

      if (!this.password) {
        this.validationErrors.password = 'Field is required';
      }

      if (Object.keys(this.validationErrors).length) {
        return;
      }

      try {
        this.isLoading = true;
        await this.$store.dispatch('auth/login', {
          username: this.username,
          password: this.password
        });
        await this.$router.go(0);
        this.isLoading = false;
      } catch (e) {
        if (e.response) {
          this.error = e.response.status === 400 ? e.response.data.errors[0] : e.response.data.message;
        } else {
          this.error = 'Connection error, please try again later';
        }
      }
      this.isLoading = false;
    },
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>

