<template v-if="!isLoggedIn">
  <b-form @submit="onSubmit" class="signup-form">
    <app-overlay :show="isLoading">
      <b-form-group class="email">
        <input-icon-group position="left" class="animated-form-field" icon="user">
          <b-form-input
              type="text"
              v-model="email"
              :state="getFieldState('email')"
              placeholder="Input your email address"
              autocomplete="username"
          />
          <b-form-invalid-feedback>{{ validationErrors['email'] }}</b-form-invalid-feedback>
          <label>Email</label>
        </input-icon-group>
      </b-form-group>

      <b-form-group class="password">
        <input-icon-group position="left" class="animated-form-field" icon="lock">
          <b-form-input
              type="password"
              v-model="password"
              :state="getFieldState('password')"
              placeholder="Input password"
              autocomplete="current-password"
          />
          <b-form-invalid-feedback>{{ error || validationErrors['password'] }}</b-form-invalid-feedback>
          <label>Password</label>
        </input-icon-group>
      </b-form-group>

      <div class="text-center mt-4">
        <b-button type="submit" variant="primary" class="mb-4 wide login">
          <i class="icon icon-user"></i>
          <span>Create Account</span>
        </b-button>
      </div>
    </app-overlay>
  </b-form>
</template>

<script>
import {mapGetters} from "vuex";
import InputIconGroup from "@/components/input-icon-group";
import AppOverlay from "@/components/overlay";

export default {
  name: "SignupForm",
  components: {
    InputIconGroup,
    AppOverlay
  },
  data() {
    return {
      isSubmitted: false,
      isLoading: false,
      email: '',
      password: '',
      validationErrors: {},
      error: false
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn'])
  },
  methods: {
    getFieldState(field) {
      return !this.isSubmitted ? null : (!this.validationErrors[field] && !this.error);
    },

    async onSubmit() {
      this.error = false;
      this.isSubmitted = true;
      this.validationErrors = {};

      if (!this.email) {
        this.validationErrors.email = 'Field is required';
      }

      if (!this.password) {
        this.validationErrors.password = 'Field is required';
      }

      if (Object.keys(this.validationErrors).length) {
        return;
      }

      try {
        this.isLoading = true;
        await this.$store.dispatch('auth/signup', {
          email: this.email,
          password: this.password
        });
        await this.$router.go(0);
      } catch (e) {
        if (e.response) {
          if (e.response.status === 400) {
            for (let key in e.response.data.errors) {
              this.validationErrors[key] = e.response.data.errors[key];
            }
          } else {
            this.error = e.response.data.message;
          }
        } else {
          this.error = 'Connection error, please try again later';
        }
      }
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
