import api from '@/api/api'

class chatActivityRewardsService {
    getProfilesForReward() {
        return api.axios.get(`reward/get-profiles-ready-for-reward`).then(response => {
            return response.data.profiles;
        });
    }

    sendRewardToProfile(profileId) {
        return api.axios.post(`reward/send-activity-reward/` + profileId).then(response => response.data.reward);
    }
}

export default new chatActivityRewardsService();
