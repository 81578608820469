<template>
  <uploaded-image-preview class="uploaded-album-image-preview" :image-file="imageFile">
    <b-form-checkbox v-model="isAvatar">
      Make main profile picture
    </b-form-checkbox>

    <div class="image-status">
      <b-form-checkbox v-model="isPrivate">
        Mark as private
      </b-form-checkbox>
      <b-link @click.prevent="openPrivateImageHelpModal()" class="ms-2">
        <i class="icon icon-info primary"/>
      </b-link>
    </div>

    <template #actions>
      <b-button variant="primary" class="upload-image wide mt-3 mb-3" @click.prevent="uploadImage">
        <i class="icon icon-upload"/>
        <span>Upload picture</span>
      </b-button>
    </template>
  </uploaded-image-preview>
</template>

<script>
import {IMAGE_VISIBILITY_PRIVATE, IMAGE_VISIBILITY_PUBLIC} from "@/utils/constants";
import UploadedImagePreview from "@/components/uploaded-image-preview";

export default {
  name: 'UploadedAlbumImagePreview',
  components: {
    UploadedImagePreview
  },
  props: {
    imageFile: {
      type: File
    },
  },
  data() {
    return {
      isAvatar: false,
      isPrivate: false
    }
  },
  emits: ['on-upload-file'],
  methods: {
    uploadImage() {
      this.$emit('on-upload-file', {
        file: this.imageFile,
        visibility: this.isPrivate ? IMAGE_VISIBILITY_PRIVATE : IMAGE_VISIBILITY_PUBLIC,
        isAvatar: this.isAvatar
      });
    },
    openPrivateImageHelpModal() {
      this.$store.dispatch('modal/openModal', {id: 'private-image-help-modal'});
    }
  },
  watch: {
    isAvatar(newVal) {
      if (newVal) {
        this.isPrivate = false;
      }
    },
    isPrivate(newVal) {
      if (newVal) {
        this.isAvatar = false;
      }
    }
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>

