import api from '@/api/api'

class KinkService {
    setKinks(kinkIds) {
        return api.axios.post('kink/set-kinks', {
            kink_ids: kinkIds
        }).then((response) => response.data.user);
    }

    getKinks() {
        return api.axios.get('kink/get-available').then((response) => response.data.kinks);
    }
}

export default new KinkService();
