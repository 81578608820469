<template>
  <div
      class="profile-image position-relative overflow-hidden"
      v-lazy="image.urls[size]"
      v-bind="$attrs"
      @click.capture="onClick($event)"
  >
    <adult-restriction v-if="image.restricted" :show-restriction-info="showRestrictionInfo"/>
  </div>
</template>

<script>
import AdultRestriction from "@/components/adult-restriction";

export default {
  name: 'ProfileImage',

  props: {
    size: {
      type: String,
      default: 'square'
    },
    image: {
      type: Object,
    },
    showRestrictionInfo: {
      type: Boolean,
      default: true
    },
  },

  components: {
    AdultRestriction
  },

  methods: {
    onClick(e) {
      if (this.image.restricted) {
        this.$store.dispatch('modal/openModal', {id: 'subscription-paywall-modal'});
        e.stopPropagation();
      }
    }
  }
}
</script>
