const ADD_ORDER = "ADD_ORDER";
const SET_ROUTE_BEFORE_PAYMENT = "SET_ROUTE_BEFORE_PAYMENT";
const SET_PAYMENT_IN_PROGRESS = "SET_PAYMENT_IN_PROGRESS";

export default {
  namespaced: true,

  state: {
    order: JSON.parse(sessionStorage.getItem('payment_order')) ?? {},
    routeBeforePayment: sessionStorage.getItem('route_before_payment') ?? 'Home',
    paymentInProgress: false
  },

  getters: {
    getOrder(state) {
      return state.order;
    },

    getRouteBeforePayment(state) {
      return state.routeBeforePayment;
    },

    getPaymentInProgress(state) {
      return state.paymentInProgress;
    },
  },

  mutations: {
    [ADD_ORDER](state, order) {
      state.order = order
    },

    [SET_ROUTE_BEFORE_PAYMENT](state, route) {
      state.routeBeforePayment = route;
    },

    [SET_PAYMENT_IN_PROGRESS](state, value) {
      state.paymentInProgress = value;
    },
  },

  actions: {
    addOrder({commit}, order) {
      sessionStorage.setItem('payment_order', JSON.stringify(order));
      commit(ADD_ORDER, order);
    },

    setRouteBeforePayment({commit}, route) {
      sessionStorage.setItem('route_before_payment', route);
      commit(SET_ROUTE_BEFORE_PAYMENT, route);
    },

    setPaymentInProgress({commit}, value) {
      commit(SET_PAYMENT_IN_PROGRESS, value);
    },
  }
}
