<template>
  <app-modal
      id="upload-image-modal"
      :dialog-class="step === STEP_UPLOAD_METHOD ? 'modal-sm' : ''"
      title-tag="h4"
      title-class="text-center"
      @hidden="onHidden"
  >
    <template #title>
      {{ step === STEP_WEBCAM ? 'Take a picture' : 'Upload picture' }}
    </template>

    <app-overlay :show="isLoading">
      <template v-if="step === STEP_PREVIEW">
        <div class="preview-step">
          <component :is="modal.previewTag" @on-upload-file="uploadFile" :image-file="imageFile"/>
          <age-verification-warning v-if="modal.ageVerificationRequired && !user.age_verified"/>
        </div>
      </template>

      <template v-else-if="step === STEP_WEBCAM">
        <div class="webcam-step">
          <webcam-screenshot @screenshot-done="imageSelected">
            <template #actions>
              <b-button variant="secondary" @click="step = STEP_UPLOAD_METHOD">Back</b-button>
            </template>
          </webcam-screenshot>
        </div>
      </template>

      <template v-else>
        <div class="upload-methods-step">
          <div class="methods">
            <div class="method webcam" @click="step = STEP_WEBCAM">
              <div class="upload-label">
                <i class="icon icon-camera"/>
                <div class="text-bold">Webcam picture</div>
                <div class="hint">Take a picture with your webcam</div>
              </div>
            </div>
            <div class="method hard-drive" @click="$refs.imageInput.click()">
              <div class="upload-label">
                <i class="icon icon-upload"/>
                <div class="text-bold">Upload picture</div>
                <div class="hint">Upload or drag your image here</div>
              </div>
              <input ref="imageInput" @change="imageSelected($event.target.files[0])" class="d-none" type="file" accept="image/*"/>
            </div>
          </div>

          <age-verification-warning v-if="modal.ageVerificationRequired && !user.age_verified"/>
        </div>
      </template>
    </app-overlay>
  </app-modal>
</template>

<script>
import resizeImage from "@/utils/imageResizer";
import {mapActions, mapGetters} from "vuex";
import AppModal from "@/components/modals/modal";
import UploadedAlbumImagePreview from "@/components/uploaded-album-image-preview";
import UploadedChatImagePreview from "@/components/uploaded-chat-image-preview";
import WebcamScreenshot from "@/components/webcam-screenshot";
import AgeVerificationWarning from "@/components/age-verification-warning";
import AppOverlay from "@/components/overlay";

export default {
  name: "ModalUploadImage",
  props: {
    previewTag: {
      type: String
    }
  },
  data() {
    return {
      step: this.STEP_UPLOAD_METHOD,
      imageFile: null,
      isLoading: false
    }
  },
  components: {
    AppOverlay,
    AgeVerificationWarning,
    WebcamScreenshot,
    AppModal,
    UploadedAlbumImagePreview,
    UploadedChatImagePreview
  },
  computed: {
    ...mapGetters('modal', ['getModal']),
    ...mapGetters('user', {user: 'getUser'}),

    modal() {
      return this.getModal('upload-image-modal');
    }
  },
  methods: {
    ...mapActions('modal', ['closeModal']),

    onHidden() {
      this.imageFile = null;
      this.step = this.STEP_UPLOAD_METHOD;
    },

    async uploadFile(payload) {
      this.isLoading = true;
      try {
        await this.modal.onUpload(payload);
      } catch (e) {
        if (e.response && e.response.status === 400) {
          this.openModal({
            id: 'notification-modal',
            message: e.response.data.errors
                ? Object.values(e.response.data.errors).join("\n")
                : e.response.data.message
          });
        }
      }
      this.isLoading = false;
    },

    async imageSelected(imageFile) {
      if (!imageFile) {
        return;
      }

      this.imageFile = await resizeImage(imageFile);
      if (this.modal) {
        if (this.modal.previewTag) {
          this.step = this.STEP_PREVIEW;
        } else {
          await this.uploadFile({file: this.imageFile});
        }
      }
    },
  },
  beforeCreate() {
    this.STEP_UPLOAD_METHOD = 'upload-method';
    this.STEP_WEBCAM = 'webcam';
    this.STEP_PREVIEW = 'preview';
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>
