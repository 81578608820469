import api from '@/api/api';

class FantasyRequestService {
  acceptFantasyRequest(fantasyRequestId) {
    return api.axios.post('fantasy-request/accept', {
      request_id: fantasyRequestId
    }).then((response) => {
      return response.data.conversation;
    });
  }

  rejectFantasyRequest(fantasyRequestId) {
    return api.axios.post('fantasy-request/reject', {
      request_id: fantasyRequestId
    });
  }
}

export default new FantasyRequestService();
