<template>
  <div class="explode-bubbles">
    <div v-for="i in bubbleCount" :key="i"/>
  </div>
</template>

<script>
export default {
  name: "ExplodeBubbles",
  props: {
    bubbleCount: {
      type: Number,
      default: 30
    },
    animation: {
      type: String,
      default: 'fade',
      validator: function (value) {
        return ['fade', 'move'].indexOf(value) !== -1;
      }
    },
    startElement: {
      type: Node,
      default: null
    }
  },
  methods: {
    fade() {
      const sizeMin = 10;
      const sizeMax = 90;
      const opacityMin = 0.3;
      const opacityMax = 0.8;

      const bubbles = this.$el.querySelectorAll('.explode-bubbles > div');
      for (const bubble of bubbles) {
        const size = Math.floor(Math.random() * (sizeMax - sizeMin)) + sizeMin;
        const opacity = Math.random() * (opacityMax - opacityMin) + opacityMin;
        bubble.style.top = (Math.random() * 100) + '%';
        bubble.style.left = (Math.random() * 100) + '%';
        bubble.style.width = size + 'px';
        bubble.style.height = size + 'px';
        bubble.style.opacity = opacity;
      }
    },

    move() {
      if (this.startElement) {
        const element = this.startElement;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const xBarCenter = element.offsetLeft + element.offsetWidth / 2;
        const yBarCenter = element.offsetTop + element.offsetHeight / 2;

        const xMin = 0 - xBarCenter;
        const yMin = 0 - yBarCenter;
        const xMax = windowWidth - xBarCenter;
        const yMax = windowHeight - yBarCenter;
        const sizeMin = 10;
        const sizeMax = 90;
        const brightnessMin = 90;
        const brightnessMax = 120;

        const bubbles = this.$el.querySelectorAll('.explode-bubbles > div');
        for (const bubble of bubbles) {
          bubble.style.top = yBarCenter + 'px';
          bubble.style.left = xBarCenter + 'px';
        }

        setTimeout(function () {
          for (const bubble of bubbles) {
            const x = Math.floor(Math.random() * (xMax - xMin)) + xMin;
            const y = Math.floor(Math.random() * (yMax - yMin)) + yMin;
            const size = Math.floor(Math.random() * (sizeMax - sizeMin)) + sizeMin;
            const brightness = Math.floor(Math.random() * (brightnessMax - brightnessMin)) + brightnessMin;
            bubble.setAttribute('translate-x', x);
            bubble.setAttribute('translate-y', y);
            bubble.style.width = size + 'px';
            bubble.style.height = size + 'px';
            bubble.style.transform = 'translate3d(' + x + 'px, ' + y + 'px, 0)';
            bubble.style.filter = 'brightness(' + brightness + '%)';
          }
        }, 100);

        setTimeout(function () {
          for (const bubble of bubbles) {
            const x = bubble.getAttribute('translate-x');
            const y = bubble.getAttribute('translate-y') + windowHeight;
            bubble.style.transform = 'translate3d(' + x + 'px, ' + y + 'px, 0)';
          }
        }, 1000);
      }
    }
  },

  mounted() {
    this[this.animation]();
  }
}
</script>

<style lang="scss">
@import "./index.scss";
</style>

