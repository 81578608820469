import api from '@/api/api'

class ProfileService {
  getUntouchedProfiles(excludeIds, ids) {
    return api.axios.post('profile/get-untouched', {
      exclude_ids: excludeIds,
      ids: ids
    }).then((response) => response.data.profiles);
  }

  getProfile(profileId) {
    return api.axios.get('profile/get-profile/' + profileId).then(response => response.data.profile);
  }

  getProfiles(profileIds) {
    return api.axios.get('profile/get-profiles',{
      params: {
        profile_ids: profileIds
      }
    }).then(response => response.data.profiles);
  }
}

export default new ProfileService();
